// sec/src/onesignalInit.js
"use client";

import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

export default function OneSignalInit() {
  const [isSubscribed, setIsSubscribed] = useState(null);

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: 'a4d00232-f1a9-4536-9308-751c6039639e',
        notifyButton: {
          enable: true, // Show the notification bell
        },
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: 'OneSignalSDKWorker.js',
        serviceWorkerUpdaterPath: 'OneSignalSDKUpdaterWorker.js',
      })
        .then(() => {
          // OneSignal is initialized~
          // Now check subscription status
          OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
            setIsSubscribed(isEnabled);
            if (isEnabled) {
              console.log('Push notifications are enabled!');
            } else {
              console.log('Push notifications are not enabled yet.');
            }
          });
        })
        .catch((e) => {
          console.error('OneSignal initialization failed:', e);
        });
    }
  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '50px',
        color: '#333',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>Welcome to the World of Secrets!</h1>
      <p style={{ fontSize: '18px', maxWidth: '600px', lineHeight: '1.5' }}>
        Dive into the realm where every notification uncovers a new story. Allow
        push notifications to stay updated on the latest secrets!
      </p>
      <button
        onClick={() => {
          alert('Thanks for joining the journey!');
        }}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Join the Adventure
      </button>

      {/* Display subscription status */}
      {isSubscribed === null && <p>Checking subscription status...</p>}
      {isSubscribed === true && <p>You are subscribed to notifications.</p>}
      {isSubscribed === false && <p>You are not subscribed to notifications.</p>}
    </div>
  );
}
