//sec/src/App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
// import Topbar from './scenes/global/Topbar';
// import Sidebar from './scenes/global/Sidebar';

// dashboard

import OneSignal from '../src/onesignalInit.js';
import ProtectedRoute from '../src/ProtectedRoute';


import Dashboard from "./scenes/dashboard";


import UserManagement from "./scenes/usermanagement";
import UserInformation from "./scenes/crude/UserInformation.jsx";


import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";
// import Profile from "./scenes/Profile/index";

import MemberProfile from "./scenes/newp/MemberProfile";
// newp








import Matches from "./scenes/matchesAi/index.jsx";
import Aiprofile from "./scenes/aiProfile/index.jsx";

import Adminusers from "./scenes/admincreateusers/index.jsx";



import Walletmanagement from "./scenes/walletmanagement/index.js";
import PurchaseCoins from "./scenes/PurchaseCoins/index.js";


import EventCard from "./scenes/EventCard.js";
import EventList from "./scenes/EventList.js";
import EventPassList from "./scenes/EventPassList.js";


import TransactionHistory from "./scenes/TransactionHistory/index.js";


// setting
import PlatformConfiguration from "./scenes/newp/setting/PlatformConfiguration";
import BackupAndRecovery from "./scenes/newp/setting/BackupAndRecovery";

// website
import HomePages from './pages/home';
import MembersPage from './pages/members';
import ProfilePage from './pages/profile';
import LoginPage from './pages/login';
import SignInPage from './pages/signin';
import PricingPlanPage from './pages/pricingplan';
import ForeZeroPage from './pages/forezero';
import CommunityPage from './pages/community';
import BlogPage from './pages/blog';
import BlogSinglePage from './pages/blogsingle';
import ContactPage from './pages/contact';
import EventPage from './pages/event';
import AboutusPage from './pages/aboutus';
import ServicesPage from './pages/services';
import Mainform from './pages/form';
import Partnerform from './pages/partnerform';
import LegalPage from './pages/LegalPage';



                                              
import Memberform from './pages/memberform';  
                                              



import { ColorModeContext, useMode } from "./theme";

function App() {
  const [theme, colorMode] = useMode();

  const adminRoutes = [
    { path: '/dashboard', component: Dashboard, roles: ['admin', 'member', 'agent', 'support', 'partner'] },
  
    // Admin and Support access only
    { path: '/user-management', component: UserManagement, roles: ['admin', 'support', 'member'] },
    { path: '/user-information', component: UserInformation, roles: ['admin', 'support', 'member'] },

 
    { path: '/contacts', component: Contacts, roles: ['admin', 'member', 'agent', 'partner', 'support'] },
       { path: '/invoices', component: Invoices, roles: ['admin', 'member', 'agent', 'partner', 'support'] },
    { path: '/form', component: Form, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/bar', component: Bar, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/pie', component: Pie, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/line', component: Line, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/faq', component: FAQ, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/calendar', component: Calendar, roles: ['admin', 'member', 'agent', 'partner'] },
    { path: '/geography', component: Geography, roles: ['admin', 'member', 'agent', 'partner'] },  


 



    { path: '/matches', component: Matches, roles: [ 'member', 'partner'] },
    { path: '/aiprofile', component: Aiprofile, roles: [ 'member', 'partner'] },

    { path: '/PurchaseCoins', component: PurchaseCoins, roles: [ 'member', 'admin'] },
{ path: '/walletmanagement', component: Walletmanagement, roles: [ 'member', 'admin'] },
{ path: '/TransactionHistory', component: TransactionHistory, roles: [ 'member', 'admin'] },

{ path: '/EventCard', component: EventCard, roles: [ 'member', 'admin'] },
{ path: '/EventList', component: EventList, roles: [ 'member', 'admin'] },
{ path: '/event-passes', component: EventPassList, roles: [ 'member', 'admin'] },

{ path: '/partner-form', component: Partnerform, roles: [ 'admin', 'member', 'agent', 'partner', 'support'] },
{ path: '/member-form', component: Memberform, roles: [ 'admin', 'member', 'agent', 'partner', 'support'] },

{ path: '/userscreation', component: Adminusers , roles: [ 'admin','member', 'agent', 'partner'] },

// Member profile access for all roles except admin
{ path: '/profile-page', component: MemberProfile, roles: ['admin', 'member', 'agent', 'partner', 'support'] },
    
   ] ;
  

  const websiteRoutes = [
    { path: '/', component: <HomePages /> },
    { path: '/members', component: <MembersPage /> },
    { path: '/profile', component: <ProfilePage /> },
    { path: '/login', component: <LoginPage /> },
    { path: '/signup', component: <SignInPage /> },
    { path: '/pricing', component: <PricingPlanPage /> },
    { path: '/community', component: <CommunityPage /> },
    { path: '/blog', component: <BlogPage /> },
    { path: '/blog-single', component: <BlogSinglePage /> },
    { path: '/contact', component: <ContactPage /> },
    { path: '/event', component: <EventPage /> },
    { path: '/aboutus', component: <AboutusPage /> },
    { path: '/services', component: <ServicesPage /> },
    { path: '/user-form', component: <Mainform /> },


    { path: '/platform-configuration', component: <PlatformConfiguration /> },
    { path: '/backup-and-recovery', component: <BackupAndRecovery /> },

    { path: '*', component: <ForeZeroPage /> },

    { path: '/PrivacyPolicy', component: <LegalPage /> },
    { path: '/OneSignal', component: <OneSignal /> },
    
  ];

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <Routes>

            {adminRoutes.map(({ path, component, roles }) => (
                <Route
                  key={path}
                  path={path}
                  element={<ProtectedRoute component={component} allowedRoles={roles} />}
                />
              ))}

              {websiteRoutes.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}

      {/* Fallback for unauthorized access */}
      <Route path="*" element={<Navigate to="/login" replace />} />


            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;



