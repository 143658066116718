import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

const PartnerFormManagement = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [tabIndex, setTabIndex] = useState(0);

  // Responsiveness
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Fetch all partner forms from the backend
  useEffect(() => {
    const fetchPartnerForms = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8001/partner_forms"
        );
        setRows(response.data);
      } catch (error) {
        console.error("Error fetching partner forms:", error);
      }
    };
    fetchPartnerForms();
  }, []);

  // Open view modal
  const handleView = (row) => {
    setSelectedRow(row);
    setIsViewModalOpen(true);
  };

  // Open edit modal
  const handleEdit = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  };

  // Save changes made to the partner form
  const handleSave = async () => {
    try {
      await axios.put(
        `http://localhost:8001/partner_forms/${selectedRow.id}`,
        selectedRow
      );
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === selectedRow.id ? selectedRow : row))
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  // Delete partner form
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this partner form?")) {
      try {
        await axios.delete(`http://localhost:8001/partner_forms/${id}`);
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      } catch (error) {
        console.error("Error deleting partner form:", error);
      }
    }
  };

  // Handle input changes
  const handleChange = (field, value) => {
    setSelectedRow((prev) => ({ ...prev, [field]: value }));
  };

  // Handle Tab Change in Edit Modal
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Define the columns for the DataGrid
  const columns = useMemo(() => {
    // Base columns
    const baseColumns = [
      {
        field: "id",
        headerName: "ID",
        flex: 0.5,
        minWidth: 50,
      },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 0.5,
        minWidth: 50,
      },
      {
        field: "company_name",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "phone_number",
        headerName: "Phone Number",
        flex: 1,
        minWidth: 150,
      },
      // Add more fields as needed
      {
        field: "view",
        headerName: "View",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevents triggering the onCellClick
              handleView(params.row);
            }}
            size="small"
          >
            View
          </Button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(params.row.id);
            }}
            size="small"
          >
            Delete
          </Button>
        ),
      },
    ];

    // Adjust columns for small screens
    if (isSmallScreen) {
      // Hide less important columns on small screens
      return baseColumns.map((column) => {
        if (["email", "phone_number"].includes(column.field)) {
          return { ...column, hide: true };
        }
        return column;
      });
    }

    return baseColumns;
  }, [isSmallScreen]);

  return (
    <Box
      height="70vh"
      sx={{
        overflowX: "auto",
        mt: 2,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        components={{ Toolbar: GridToolbar }}
        autoHeight={isSmallScreen}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        sx={{
          backgroundColor: "#1F2A40",
          color: "#fff",
          "& .MuiDataGrid-cell": {
            color: "#fff",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#333",
            color: "#fff",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#333",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
          },
          "& .MuiTablePagination-root": {
            color: "#fff",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
            margin: 0,
          },
          "& .MuiTablePagination-actions": {
            margin: 0,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: "#333",
            color: "#fff",
          },
        }}
      />

      {/* View Modal */}
      <Dialog
        open={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { backgroundColor: "#1F2A40", color: "#fff" },
        }}
      >
        <DialogTitle>Partner Form Details</DialogTitle>
        <DialogContent dividers>
          {selectedRow && (
            <Box>
              <Typography variant="h6" gutterBottom>
                {selectedRow.company_name}
              </Typography>
              <Box>
                {Object.keys(selectedRow).map((key) => (
                  <Typography key={key}>
                    <strong>{key.replace(/_/g, " ")}:</strong>{" "}
                    {selectedRow[key]}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsViewModalOpen(false)}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { backgroundColor: "#1F2A40", color: "#fff" },
        }}
      >
        <DialogTitle>Edit Partner Form</DialogTitle>
        <DialogContent dividers>
          {selectedRow && (
            <>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Company Info" />
                <Tab label="Business Details" />
                <Tab label="Additional Info" />
              </Tabs>
              <Box mt={2}>
                {/* Company Info Tab */}
                {tabIndex === 0 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Company Name */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Company Name"
                          fullWidth
                          value={selectedRow.company_name || ""}
                          onChange={(e) =>
                            handleChange("company_name", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Contact Person */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Contact Person"
                          fullWidth
                          value={selectedRow.contact_person || ""}
                          onChange={(e) =>
                            handleChange("contact_person", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Email"
                          fullWidth
                          value={selectedRow.email || ""}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Phone Number */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Phone Number"
                          fullWidth
                          value={selectedRow.phone_number || ""}
                          onChange={(e) =>
                            handleChange("phone_number", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Zip Code */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Zip Code"
                          fullWidth
                          value={selectedRow.zip_code || ""}
                          onChange={(e) =>
                            handleChange("zip_code", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Company Website */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Company Website"
                          fullWidth
                          value={selectedRow.company_website || ""}
                          onChange={(e) =>
                            handleChange("company_website", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Business Details Tab */}
                {tabIndex === 1 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Type of Business */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Type of Business"
                          fullWidth
                          value={selectedRow.type_of_business || ""}
                          onChange={(e) =>
                            handleChange("type_of_business", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Brand Description */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Brand Description"
                          fullWidth
                          multiline
                          rows={4}
                          value={selectedRow.brand_description || ""}
                          onChange={(e) =>
                            handleChange("brand_description", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Partnership Reason */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Partnership Reason"
                          fullWidth
                          multiline
                          rows={4}
                          value={selectedRow.partnership_reason || ""}
                          onChange={(e) =>
                            handleChange("partnership_reason", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Partnership Interests */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Partnership Interests"
                          fullWidth
                          value={selectedRow.partnership_interests || ""}
                          onChange={(e) =>
                            handleChange(
                              "partnership_interests",
                              e.target.value
                            )
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Additional Info Tab */}
                {tabIndex === 2 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Additional Comments */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Additional Comments"
                          fullWidth
                          multiline
                          rows={4}
                          value={selectedRow.additional_comments || ""}
                          onChange={(e) =>
                            handleChange(
                              "additional_comments",
                              e.target.value
                            )
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Agreement */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Agreement"
                          fullWidth
                          value={selectedRow.agreement || ""}
                          onChange={(e) =>
                            handleChange("agreement", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Document Upload */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Document Upload"
                          fullWidth
                          value={selectedRow.document_upload || ""}
                          onChange={(e) =>
                            handleChange("document_upload", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsEditModalOpen(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PartnerFormManagement;
