//sec\src\scenes\admincreateusers\index.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";

// Define roles at the top after imports
const roles = ['Member', 'Partner', 'Agent', 'Support', 'Admin'];

const SignInPage = () => {
    const [regName, setRegName] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regConPassword, setRegConPassword] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [isSidebar, setIsSidebar] = useState(true);
    const isSidebarVisible = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (regPassword !== regConPassword) {
            alert("Passwords do not match!");
            return;
        }

        if (!selectedRole) {
            alert("Please select a role.");
            return;
        }

        axios.post('http://localhost:8001/registeruser', {
            username: regName,
            email: regEmail,
            password: regPassword,
            role: selectedRole.toLowerCase(), // Convert role to lowercase to match backend ENUM
        })
        .then(response => {
            alert('Registration successful!');
        })
        .catch(error => {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);
            } else {
                alert('There was an error registering. Please try again later.');
                console.error('Registration error:', error);
            }
        });
    };

    const handleRoleSelect = (role) => {
        setSelectedRole(role);
    };

    return (
        <div>
            <div className="app">
                <Sidebar isSidebar={isSidebarVisible} />
                <main className="content">
                    <Topbar setIsSidebar={setIsSidebar} />

                    <div className="login-section padding-tb">
                        <div className="container">
                            <div className="account-wrapper">
                                <h3 className="title">Role Creation Form</h3>
                                <form className="account-form" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={regName}
                                            onChange={(e) => setRegName(e.target.value)}
                                            placeholder="Your Name *"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            id="item02"
                                            value={regEmail}
                                            onChange={(e) => setRegEmail(e.target.value)}
                                            placeholder="Your Email *"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            id="item03"
                                            value={regPassword}
                                            onChange={(e) => setRegPassword(e.target.value)}
                                            placeholder="Password *"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="conpassword"
                                            id="item04"
                                            value={regConPassword}
                                            onChange={(e) => setRegConPassword(e.target.value)}
                                            placeholder="Confirm Password *"
                                            required
                                        />
                                    </div>
                                    <h4>Select Role</h4>
                                    <div className="role-selection">
                                        {roles.map((role) => (
                                            <div
                                                key={role}
                                                className={`role-card ${selectedRole === role ? 'selected' : ''}`}
                                                onClick={() => handleRoleSelect(role)}
                                            >
                                                <span>{role}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="form-group">
                                        <button className="d-block lab-btn" type="submit"><span>Get Started Now</span></button>
                                    </div>
                                </form>
                                <div className="account-bottom">
                                    <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <style jsx>{`
                        .role-selection {
                            display: flex;
                            gap: 15px;
                            margin: 20px 0;
                        }
                        .role-card {
                            padding: 15px;
                            border: 2px solid #ddd;
                            border-radius: 8px;
                            cursor: pointer;
                            text-align: center;
                            transition: all 0.3s ease;
                            flex: 1;
                        }
                        .role-card.selected {
                            border-color: #007bff;
                            background-color: #007bff;
                            color: #fff;
                            transform: scale(1.05);
                        }
                        .role-card:hover {
                            background-color: #ffffff2c;
                        }
                    `}</style>
                </main>
            </div>
        </div>
    );
};

export default SignInPage;
