import React, { useState, useEffect, useMemo } from "react";
import { format, parseISO } from 'date-fns';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  useMediaQuery,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

const MemberFormManagement = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [tabIndex, setTabIndex] = useState(0);

  // Responsiveness
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Fetch all member forms from the backend
  useEffect(() => {
    const fetchMemberForms = async () => {
      try {
        const response = await axios.get("http://localhost:8001/member_forms");
        console.log("Fetched data:", response.data); // Add this line
        setRows(response.data);
      } catch (error) {
        console.error("Error fetching member forms:", error);
      }
    };
    fetchMemberForms();
  }, []);
  
  

  // Open view modal
  const handleView = (row) => {
    setSelectedRow(row);
    setIsViewModalOpen(true);
  };

  // Open edit modal
  const handleEdit = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  };

  // Save changes made to the member form
  const handleSave = async () => {
    try {
      // Clone the selectedRow to avoid mutating state directly
      const updatedRow = { ...selectedRow };
  
      // Format the dob field to 'YYYY-MM-DD'
      if (updatedRow.dob) {
        updatedRow.dob = new Date(updatedRow.dob).toISOString().split('T')[0];
      }
  
      await axios.put(
        `http://localhost:8001/member_forms/${updatedRow.id}`,
        updatedRow
      );
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  // Delete member form
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this member form?")) {
      try {
        await axios.delete(`http://localhost:8001/member_forms/${id}`);
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      } catch (error) {
        console.error("Error deleting member form:", error);
      }
    }
  };

  // Handle input changes
  const handleChange = (field, value) => {
    setSelectedRow((prev) => ({ ...prev, [field]: value }));
  };

  // Handle Tab Change in Edit Modal
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Define the columns for the DataGrid
  const columns = useMemo(() => {
    // Base columns
    const baseColumns = [
        {
          field: "id",
          headerName: "ID",
          flex: 0.5,
          minWidth: 50,
        },
        {
          field: "user_id",
          headerName: "User ID",
          flex: 0.5,
          minWidth: 50,
        },
        {
          field: "full_name",
          headerName: "Full Name",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "email",
          headerName: "Email",
          flex: 1,
          minWidth: 150,
        },
        {
          field: "phone_number",
          headerName: "Phone Number",
          flex: 1,
          minWidth: 150,
        },
        {
            field: "dob",
            headerName: "Date of Birth",
            flex: 1,
            minWidth: 120,
            valueFormatter: (date) => {
              console.log('valueFormatter date:', date); // Log date
              if (!date) return '';
              try {
                const parsedDate = parseISO(date);
                return format(parsedDate, 'yyyy-MM-dd');
              } catch (error) {
                console.error('Date formatting error:', error, 'Date value:', date);
                return '';
              }
            },
          },
          
      // Add more fields as needed
      {
        field: "view",
        headerName: "View",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevents triggering the onCellClick
              handleView(params.row);
            }}
            size="small"
          >
            View
          </Button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(params.row.id);
            }}
            size="small"
          >
            Delete
          </Button>
        ),
      },
    ];

    // Adjust columns for small screens
    if (isSmallScreen) {
      // Hide less important columns on small screens
      return baseColumns.map((column) => {
        if (["email", "phone_number", "dob"].includes(column.field)) {
          return { ...column, hide: true };
        }
        return column;
      });
    }

    return baseColumns;
  }, [isSmallScreen]);

  return (
    <Box
      height="70vh"
      sx={{
        overflowX: "auto",
        mt: 2,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        components={{ Toolbar: GridToolbar }}
        autoHeight={isSmallScreen}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        sx={{
          backgroundColor: "#1F2A40",
          color: "#fff",
          "& .MuiDataGrid-cell": {
            color: "#fff",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#333",
            color: "#fff",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#333",
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
          },
          "& .MuiTablePagination-root": {
            color: "#fff",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
            margin: 0,
          },
          "& .MuiTablePagination-actions": {
            margin: 0,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: "#333",
            color: "#fff",
          },
        }}
      />

      {/* View Modal */}
      <Dialog
        open={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { backgroundColor: "#1F2A40", color: "#fff" },
        }}
      >
        <DialogTitle>Member Form Details</DialogTitle>
        <DialogContent dividers>
          {selectedRow && (
            <Box>
              <Typography variant="h6" gutterBottom>
                {selectedRow.full_name}
              </Typography>
              <Box>
  {Object.keys(selectedRow).map((key) => {
    let value = selectedRow[key];

    // Check if the field is 'dob' or any other date field
    if (key === 'dob' && value) {
      value = format(new Date(value), 'yyyy-MM-dd');
    }

    return (
      <Typography key={key}>
        <strong>{key.replace(/_/g, " ")}:</strong> {value}
      </Typography>
    );
  })}
</Box>

            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsViewModalOpen(false)}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { backgroundColor: "#1F2A40", color: "#fff" },
        }}
      >
        <DialogTitle>Edit Member Form</DialogTitle>
        <DialogContent dividers>
          {selectedRow && (
            <>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Personal Info" />
                <Tab label="Physical Attributes" />
                <Tab label="Lifestyle" />
                <Tab label="Education & Career" />
                <Tab label="Additional Info" />
              </Tabs>
              <Box mt={2}>
                {/* Personal Info Tab */}
                {tabIndex === 0 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Full Name */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Full Name"
                          fullWidth
                          value={selectedRow.full_name || ""}
                          onChange={(e) =>
                            handleChange("full_name", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Preferred Name */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Preferred Name"
                          fullWidth
                          value={selectedRow.preferred_name || ""}
                          onChange={(e) =>
                            handleChange("preferred_name", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Email"
                          fullWidth
                          value={selectedRow.email || ""}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Phone Number */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Phone Number"
                          fullWidth
                          value={selectedRow.phone_number || ""}
                          onChange={(e) =>
                            handleChange("phone_number", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Date of Birth */}
                      <Grid item xs={6}>
                      <TextField
  margin="dense"
  label="Date of Birth"
  type="date"
  fullWidth
  value={selectedRow.dob ? format(new Date(selectedRow.dob), 'yyyy-MM-dd') : ''}
  onChange={(e) => handleChange("dob", e.target.value)}
  InputLabelProps={{
    shrink: true,
    style: { color: "#fff" },
  }}
  InputProps={{ style: { color: "#fff" } }}
/>
                      </Grid>
                      {/* Gender Identity */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Gender Identity
                          </InputLabel>
                          <Select
                            value={selectedRow.gender_identity || ""}
                            onChange={(e) =>
                              handleChange("gender_identity", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Gender Identity Other */}
                      {selectedRow.gender_identity === "Other" && (
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            label="Other Gender Identity"
                            fullWidth
                            value={selectedRow.gender_identity_other || ""}
                            onChange={(e) =>
                              handleChange(
                                "gender_identity_other",
                                e.target.value
                              )
                            }
                            InputLabelProps={{ style: { color: "#fff" } }}
                            InputProps={{ style: { color: "#fff" } }}
                          />
                        </Grid>
                      )}
                      {/* Nationality */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Nationality"
                          fullWidth
                          value={selectedRow.nationality || ""}
                          onChange={(e) =>
                            handleChange("nationality", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Home Town */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Home Town"
                          fullWidth
                          value={selectedRow.home_town || ""}
                          onChange={(e) =>
                            handleChange("home_town", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Zip Code */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Zip Code"
                          fullWidth
                          value={selectedRow.zip_code || ""}
                          onChange={(e) =>
                            handleChange("zip_code", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Home Address */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Home Address"
                          fullWidth
                          value={selectedRow.home_address || ""}
                          onChange={(e) =>
                            handleChange("home_address", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Physical Attributes Tab */}
                {tabIndex === 1 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Height */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Height"
                          fullWidth
                          value={selectedRow.height || ""}
                          onChange={(e) =>
                            handleChange("height", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Weight */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Weight"
                          fullWidth
                          value={selectedRow.weight || ""}
                          onChange={(e) =>
                            handleChange("weight", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Body Type */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Body Type
                          </InputLabel>
                          <Select
                            value={selectedRow.body_type || ""}
                            onChange={(e) =>
                              handleChange("body_type", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Slim">Slim</MenuItem>
                            <MenuItem value="Athletic">Athletic</MenuItem>
                            <MenuItem value="Average">Average</MenuItem>
                            <MenuItem value="Curvy">Curvy</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Eye Color */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Eye Color
                          </InputLabel>
                          <Select
                            value={selectedRow.eye_color || ""}
                            onChange={(e) =>
                              handleChange("eye_color", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Brown">Brown</MenuItem>
                            <MenuItem value="Blue">Blue</MenuItem>
                            <MenuItem value="Green">Green</MenuItem>
                            <MenuItem value="Hazel">Hazel</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Eye Color Other */}
                      {selectedRow.eye_color === "Other" && (
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            label="Other Eye Color"
                            fullWidth
                            value={selectedRow.eye_color_other || ""}
                            onChange={(e) =>
                              handleChange("eye_color_other", e.target.value)
                            }
                            InputLabelProps={{ style: { color: "#fff" } }}
                            InputProps={{ style: { color: "#fff" } }}
                          />
                        </Grid>
                      )}
                      {/* Hair Color */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Hair Color
                          </InputLabel>
                          <Select
                            value={selectedRow.hair_color || ""}
                            onChange={(e) =>
                              handleChange("hair_color", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Black">Black</MenuItem>
                            <MenuItem value="Brown">Brown</MenuItem>
                            <MenuItem value="Blonde">Blonde</MenuItem>
                            <MenuItem value="Red">Red</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Hair Color Other */}
                      {selectedRow.hair_color === "Other" && (
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            label="Other Hair Color"
                            fullWidth
                            value={selectedRow.hair_color_other || ""}
                            onChange={(e) =>
                              handleChange("hair_color_other", e.target.value)
                            }
                            InputLabelProps={{ style: { color: "#fff" } }}
                            InputProps={{ style: { color: "#fff" } }}
                          />
                        </Grid>
                      )}
                      {/* Physical Fitness Level */}
                      <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Physical Fitness Level
                          </InputLabel>
                          <Select
                            value={selectedRow.physical_fitness_level || ""}
                            onChange={(e) =>
                              handleChange(
                                "physical_fitness_level",
                                e.target.value
                              )
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Low">Low</MenuItem>
                            <MenuItem value="Average">Average</MenuItem>
                            <MenuItem value="High">High</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Lifestyle Tab */}
                {tabIndex === 2 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Marital Status */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Marital Status
                          </InputLabel>
                          <Select
                            value={selectedRow.marital_status || ""}
                            onChange={(e) =>
                              handleChange("marital_status", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Single">Single</MenuItem>
                            <MenuItem value="Married">Married</MenuItem>
                            <MenuItem value="Divorced">Divorced</MenuItem>
                            <MenuItem value="Widowed">Widowed</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Marital Status Other */}
                      {selectedRow.marital_status === "Other" && (
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            label="Other Marital Status"
                            fullWidth
                            value={selectedRow.marital_status_other || ""}
                            onChange={(e) =>
                              handleChange(
                                "marital_status_other",
                                e.target.value
                              )
                            }
                            InputLabelProps={{ style: { color: "#fff" } }}
                            InputProps={{ style: { color: "#fff" } }}
                          />
                        </Grid>
                      )}
                      {/* Have Children */}
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={selectedRow.have_children === "Yes"}
                              onChange={(e) =>
                                handleChange(
                                  "have_children",
                                  e.target.checked ? "Yes" : "No"
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Have Children"
                        />
                      </Grid>
                      {/* Partner Children Preference */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Partner Children Preference
                          </InputLabel>
                          <Select
                            value={selectedRow.partner_children_preference || ""}
                            onChange={(e) =>
                              handleChange(
                                "partner_children_preference",
                                e.target.value
                              )
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Doesn't Matter">
                              Doesn't Matter
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Family Relationship Description */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Family Relationship Description"
                          fullWidth
                          multiline
                          rows={4}
                          value={
                            selectedRow.family_relationship_description || ""
                          }
                          onChange={(e) =>
                            handleChange(
                              "family_relationship_description",
                              e.target.value
                            )
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Do You Smoke */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Do You Smoke?
                          </InputLabel>
                          <Select
                            value={selectedRow.do_you_smoke || ""}
                            onChange={(e) =>
                              handleChange("do_you_smoke", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Occasionally">Occasionally</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Do You Drink Alcohol */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Do You Drink Alcohol?
                          </InputLabel>
                          <Select
                            value={selectedRow.do_you_drink_alcohol || ""}
                            onChange={(e) =>
                              handleChange(
                                "do_you_drink_alcohol",
                                e.target.value
                              )
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Occasionally">Occasionally</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Dietary Restrictions */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Dietary Restrictions"
                          fullWidth
                          value={selectedRow.dietary_restrictions || ""}
                          onChange={(e) =>
                            handleChange(
                              "dietary_restrictions",
                              e.target.value
                            )
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Hobbies & Interests */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Hobbies & Interests"
                          fullWidth
                          value={selectedRow.hobbies_interests || ""}
                          onChange={(e) =>
                            handleChange("hobbies_interests", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Education & Career Tab */}
                {tabIndex === 3 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Education Level */}
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel style={{ color: "#fff" }}>
                            Education Level
                          </InputLabel>
                          <Select
                            value={selectedRow.education_level || ""}
                            onChange={(e) =>
                              handleChange("education_level", e.target.value)
                            }
                            style={{ color: "#fff" }}
                          >
                            <MenuItem value="High School">High School</MenuItem>
                            <MenuItem value="Bachelor's Degree">
                              Bachelor's Degree
                            </MenuItem>
                            <MenuItem value="Master's Degree">
                              Master's Degree
                            </MenuItem>
                            <MenuItem value="Doctorate">Doctorate</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Education Level Other */}
                      {selectedRow.education_level === "Other" && (
                        <Grid item xs={12}>
                          <TextField
                            margin="dense"
                            label="Other Education Level"
                            fullWidth
                            value={selectedRow.education_level_other || ""}
                            onChange={(e) =>
                              handleChange(
                                "education_level_other",
                                e.target.value
                              )
                            }
                            InputLabelProps={{ style: { color: "#fff" } }}
                            InputProps={{ style: { color: "#fff" } }}
                          />
                        </Grid>
                      )}
                      {/* Occupation */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Occupation"
                          fullWidth
                          value={selectedRow.occupation || ""}
                          onChange={(e) =>
                            handleChange("occupation", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Industry */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Industry"
                          fullWidth
                          value={selectedRow.industry || ""}
                          onChange={(e) =>
                            handleChange("industry", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Income Bracket */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Income Bracket"
                          fullWidth
                          value={selectedRow.income_bracket || ""}
                          onChange={(e) =>
                            handleChange("income_bracket", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Career Ambitions */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Career Ambitions"
                          fullWidth
                          multiline
                          rows={4}
                          value={selectedRow.career_ambitions || ""}
                          onChange={(e) =>
                            handleChange("career_ambitions", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Additional Info Tab */}
                {tabIndex === 4 && (
                  <Box component="form">
                    <Grid container spacing={2}>
                      {/* Languages Spoken */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Languages Spoken"
                          fullWidth
                          value={selectedRow.languages_spoken || ""}
                          onChange={(e) =>
                            handleChange("languages_spoken", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Pets */}
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          label="Pets"
                          fullWidth
                          value={selectedRow.pets || ""}
                          onChange={(e) => handleChange("pets", e.target.value)}
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Volunteering */}
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={selectedRow.volunteering === "Yes"}
                              onChange={(e) =>
                                handleChange(
                                  "volunteering",
                                  e.target.checked ? "Yes" : "No"
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Volunteering"
                        />
                      </Grid>
                      {/* Social Media Profiles */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Social Media Profiles"
                          fullWidth
                          value={selectedRow.social_media_profiles || ""}
                          onChange={(e) =>
                            handleChange(
                              "social_media_profiles",
                              e.target.value
                            )
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                      {/* Document Upload */}
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Document Upload"
                          fullWidth
                          value={selectedRow.document_upload || ""}
                          onChange={(e) =>
                            handleChange("document_upload", e.target.value)
                          }
                          InputLabelProps={{ style: { color: "#fff" } }}
                          InputProps={{ style: { color: "#fff" } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsEditModalOpen(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MemberFormManagement;
