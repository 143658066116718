import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";

const UserManagement = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isSidebar, setIsSidebar] = useState(true);

  // New state variables for cell content dialog
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [cellContent, setCellContent] = useState("");
  const [cellField, setCellField] = useState("");

  // Responsiveness
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Custom theme
  const theme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#1F2A40",
        paper: "#1F2A40",
      },
      primary: {
        main: "#1F2A40",
      },
      text: {
        primary: "#ffffff",
        secondary: "#b0b0b0",
      },
    },
  });

  // Pagination state
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // Fetch all users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("http://localhost:8001/users");
        setRows(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  // Open view modal
  const handleView = (row) => {
    setSelectedRow(row);
    setIsViewModalOpen(true);
  };

  // Open edit modal
  const handleEdit = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  };

  // Save changes made to the user
  const handleSave = async () => {
    try {
      await axios.put(
        `http://localhost:8001/users/${selectedRow.user_id}`,
        selectedRow
      );
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.user_id === selectedRow.user_id ? selectedRow : row
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  // Delete user
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`http://localhost:8001/users/${id}`);
        setRows((prevRows) => prevRows.filter((row) => row.user_id !== id));
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  // Handle input changes
  const handleChange = (field, value) => {
    setSelectedRow((prev) => ({ ...prev, [field]: value }));
  };

  // Define the columns for the DataGrid
  const columns = useMemo(() => {
    // Base columns
    const baseColumns = [
      {
        field: "user_id",
        headerName: "ID",
        flex: 0.5,
        minWidth: 50,
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "role",
        headerName: "Role",
        flex: 0.7,
        minWidth: 80,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.7,
        minWidth: 100,
        type: "singleSelect",
        valueOptions: ["active", "inactive", "suspended"],
      },
      {
        field: "coinBalance",
        headerName: "Coin Balance",
        flex: 0.7,
        minWidth: 100,
        type: "number",
      },
      {
        field: "is_form_completed",
        headerName: "Form Completed",
        flex: 0.7,
        minWidth: 120,
        type: "boolean",
        renderCell: (params) =>
          params.value ? (
            <Typography color="success.main">Yes</Typography>
          ) : (
            <Typography color="error.main">No</Typography>
          ),
      },
      {
        field: "view",
        headerName: "View",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevents triggering the onCellClick
              handleView(params.row);
            }}
            size="small"
          >
            View
          </Button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(params.row.user_id);
            }}
            size="small"
          >
            Delete
          </Button>
        ),
      },
    ];

    // Adjust columns for small screens
    if (isSmallScreen) {
      // Hide less important columns on small screens
      return baseColumns.map((column) => {
        if (
          ["email", "coinBalance", "is_form_completed"].includes(column.field)
        ) {
          return { ...column, hide: true };
        }
        return column;
      });
    }

    return baseColumns;
  }, [isSmallScreen]);

  return (

    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />


    <ThemeProvider theme={theme}>
      <Box m={isSmallScreen ? "10px" : "20px"}>
        <Typography variant="h4" gutterBottom>
          User Management
        </Typography>
        <Box
          height="70vh"
          sx={{
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.user_id}
            components={{ Toolbar: GridToolbar }}
            autoHeight={isSmallScreen}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            sx={{
              backgroundColor: "#1F2A40",
              color: "#fff",
              "& .MuiDataGrid-cell": {
                color: "#fff",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#333",
                color: "#fff",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#333",
                color: "#fff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
              },
              "& .MuiTablePagination-root": {
                color: "#fff",
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                margin: 0,
              },
              "& .MuiTablePagination-actions": {
                margin: 0,
              },
              "& .MuiDataGrid-toolbarContainer": {
                backgroundColor: "#333",
                color: "#fff",
              },
            }}
            onCellClick={(params) => {
              // Ignore clicks on action buttons
              if (
                ["view", "edit", "delete"].includes(params.field) ||
                params.colDef.type === "actions"
              ) {
                return;
              }
              // Open the modal with cell content
              setCellField(params.colDef.headerName);
              setCellContent(params.value);
              setIsCellModalOpen(true);
            }}
          />
        </Box>

        {/* View Modal */}
        <Dialog
          open={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { backgroundColor: "#1F2A40", color: "#fff" },
          }}
        >
          <DialogTitle>User Details</DialogTitle>
          <DialogContent dividers>
            {selectedRow && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  {selectedRow.username}
                </Typography>
                <Box>
                  <Typography>
                    <strong>ID:</strong> {selectedRow.user_id}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {selectedRow.email}
                  </Typography>
                  <Typography>
                    <strong>Role:</strong> {selectedRow.role}
                  </Typography>
                  <Typography>
                    <strong>Status:</strong> {selectedRow.status}
                  </Typography>
                  <Typography>
                    <strong>Coin Balance:</strong> {selectedRow.coinBalance}
                  </Typography>
                  <Typography>
                    <strong>Form Completed:</strong>{" "}
                    {selectedRow.is_form_completed ? "Yes" : "No"}
                  </Typography>
                  <Typography>
                    <strong>Created At:</strong>{" "}
                    {new Date(selectedRow.createdAt).toLocaleString()}
                  </Typography>
                  <Typography>
                    <strong>Updated At:</strong>{" "}
                    {new Date(selectedRow.updatedAt).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsViewModalOpen(false)}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Modal */}
        <Dialog
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { backgroundColor: "#1F2A40", color: "#fff" },
          }}
        >
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent dividers>
            {selectedRow && (
              <Box component="form">
                {/* Username */}
                <TextField
                  margin="dense"
                  label="Username"
                  fullWidth
                  value={selectedRow.username || ""}
                  onChange={(e) => handleChange("username", e.target.value)}
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{ style: { color: "#fff" } }}
                />
                {/* Email */}
                <TextField
                  margin="dense"
                  label="Email"
                  fullWidth
                  value={selectedRow.email || ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  InputLabelProps={{ style: { color: "#fff" } }}
                  InputProps={{ style: { color: "#fff" } }}
                />
                {/* Role */}
                <FormControl fullWidth margin="dense">
                  <InputLabel style={{ color: "#fff" }}>Role</InputLabel>
                  <Select
                    value={selectedRow.role || ""}
                    onChange={(e) => handleChange("role", e.target.value)}
                    style={{ color: "#fff" }}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="partner">Partner</MenuItem>
                    <MenuItem value="member">Member</MenuItem>
                    <MenuItem value="agent">Agent</MenuItem>
                    <MenuItem value="support">Support</MenuItem>
                  </Select>
                </FormControl>
                {/* Status */}
                <FormControl fullWidth margin="dense">
                  <InputLabel style={{ color: "#fff" }}>Status</InputLabel>
                  <Select
                    value={selectedRow.status || ""}
                    onChange={(e) => handleChange("status", e.target.value)}
                    style={{ color: "#fff" }}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                    <MenuItem value="suspended">Suspended</MenuItem>
                  </Select>
                </FormControl>
                {/* Form Completed */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!selectedRow.is_form_completed}
                      onChange={(e) =>
                        handleChange(
                          "is_form_completed",
                          e.target.checked ? 1 : 0
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Form Completed"
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsEditModalOpen(false)}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Cell Content Modal */}
        <Dialog
          open={isCellModalOpen}
          onClose={() => setIsCellModalOpen(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { backgroundColor: "#1F2A40", color: "#fff" },
          }}
        >
          <DialogTitle>{cellField}</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1">{cellContent}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsCellModalOpen(false)}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>


    </main>
    </div>

  );
};

export default UserManagement;
