import React from 'react';

import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import FooterSection from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';


const LegalPage = () => {
  return (

    <div>
    <Header2/>
    <Header />
    <PageHeader title={'Privacy Policy'} />



    <div style={styles.container}>
      <h1 style={styles.pageTitle}>Legal Page - Secret Proposal Capital Club (SPCC)</h1>

      {/* Privacy Policy Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Privacy Policy</h2>
        <p style={styles.paragraph}>
          At Secret Proposal Capital Club, your privacy is our top priority. We are dedicated to protecting your 
          personal information and ensuring that your data remains secure. Our Privacy Policy details the methods 
          we use to collect, store, and safeguard your information, adhering to the highest standards of 
          confidentiality. We utilize advanced encryption technologies to protect your personal data from 
          unauthorized access. Your information will never be shared with third parties without your explicit 
          consent, except where required by law.
        </p>
        <ul style={styles.list}>
          <li><strong>Data Collection:</strong> We collect personal information that you provide during sign-up, membership, 
              and event participation, including your name, contact details, preferences, and payment 
              information.</li>
          <li><strong>Data Usage:</strong> Your information is used to enhance your experience with our services, tailor 
              matchmaking results, and facilitate event participation. It is also used to communicate important 
              updates and offers.</li>
          <li><strong>Data Security:</strong> We employ industry-leading security measures, including encryption, to protect 
              your data from breaches or unauthorized access.</li>
          <li><strong>Third-Party Sharing:</strong> Your data will only be shared with trusted partners and affiliates who assist 
              in delivering our services, and only with your consent. These parties are bound by strict 
              confidentiality agreements.</li>
          <li><strong>Your Rights:</strong> You have the right to access, correct, or delete your personal data at any time. You 
              can also withdraw your consent for data processing.</li>
        </ul>
      </section>

      {/* Terms and Conditions Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Terms and Conditions</h2>
        <p style={styles.paragraph}>
          These Terms and Conditions govern your use of the Secret Proposal Capital Club’s services and your 
          participation in our events. By becoming a member, you agree to comply with these terms, which are 
          designed to ensure a safe, respectful, and exclusive environment for all members. Our terms are 
          comprehensive, covering all aspects of membership, event participation, and the use of our digital 
          platforms.
        </p>
        <ul style={styles.list}>
          <li><strong>Membership Agreement:</strong> Outlines the responsibilities and expectations of members, including 
              adherence to club rules, participation protocols, and ethical conduct.</li>
          <li><strong>Event Participation:</strong> Specifies the guidelines for attending events, including dress codes, 
              behavior expectations, and confidentiality agreements. It also details the process for purchasing 
              and using guest passes.</li>
          <li><strong>Payment Terms:</strong> Details the fees for membership packages, events, and other services, including 
              payment schedules, methods, and refund policies.</li>
          <li><strong>Termination:</strong> The club reserves the right to terminate memberships for violations of terms, 
              unethical behavior, or failure to adhere to the club’s standards.</li>
          <li><strong>Legal Compliance:</strong> All activities within the club, including events and communications, must 
              comply with local and international laws. Members are required to respect the legal frameworks 
              governing our operations.</li>
        </ul>
      </section>

      {/* Disclaimer Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Disclaimer</h2>
        <p style={styles.paragraph}>
          Secret Proposal Capital Club provides a platform for exclusive matchmaking and social events. While we 
          strive to facilitate meaningful connections and deliver high-quality services, we do not guarantee specific 
          outcomes or results. Membership and participation in our events are offered at the discretion of the 
          club, and all interactions and relationships formed are the responsibility of the individuals involved.
        </p>
        <ul style={styles.list}>
          <li><strong>No Guarantees:</strong> Membership does not guarantee finding a match or successful relationships. 
              The club provides opportunities, but outcomes depend on individual interactions.</li>
          <li><strong>Event Risks:</strong> Participation in events is voluntary, and members assume any risks associated with 
              attending. The club is not liable for any personal or financial losses incurred.</li>
          <li><strong>Third-Party Services:</strong> The club may recommend or provide access to third-party services (e.g., 
              travel, accommodations). We are not responsible for the quality or outcomes of these services.</li>
          <li><strong>Liability Limitations:</strong> The club’s liability is limited to the value of the membership fees paid. We 
              are not liable for indirect or consequential damages.</li>
        </ul>
      </section>

      {/* Cookie Policy Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Cookie Policy</h2>
        <p style={styles.paragraph}>
          Our website uses cookies to enhance your browsing experience and improve our services. By using our 
          website, you consent to the use of cookies as described in our Cookie Policy. This policy explains what 
          cookies are, how we use them, and how you can control your cookie preferences.
        </p>
        <ul style={styles.list}>
          <li><strong>Types of Cookies:</strong> We use essential cookies for website functionality, performance cookies for 
              analytics, and marketing cookies to personalize content and ads.</li>
          <li><strong>Cookie Management:</strong> You can manage your cookie preferences through your browser settings or 
              by using our cookie consent tool.</li>
          <li><strong>Data Collection:</strong> Cookies collect non-personal data, such as IP addresses and browsing behavior, 
              to help us improve website performance and user experience.</li>
          <li><strong>Third-Party Cookies:</strong> We may use third-party services, such as Google Analytics, which set their 
              own cookies to analyze website traffic and user interactions.</li>
        </ul>
      </section>

      {/* Governing Law and Jurisdiction Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Governing Law and Jurisdiction</h2>
        <p style={styles.paragraph}>
          The operations of Secret Proposal Capital Club are governed by the laws of [Country]. Any disputes arising 
          from your membership or participation in our events will be resolved under the jurisdiction of the 
          courts in [City, Country]. We are committed to resolving any legal issues in a fair and transparent manner, 
          adhering to the highest standards of legal and ethical conduct.
        </p>
        <ul style={styles.list}>
          <li><strong>Applicable Law:</strong> The club operates under the legal frameworks of [Country], ensuring 
              compliance with national and international regulations.</li>
          <li><strong>Dispute Resolution:</strong> Any disputes related to membership, events, or services will be addressed 
              through mediation or legal proceedings in [City, Country].</li>
          <li><strong>Member Responsibilities:</strong> Members are expected to comply with the legal requirements and 
              standards of conduct set by the club. Non-compliance may result in termination of membership 
              and legal action.</li>
        </ul>
      </section>

      {/* Contact Information Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Contact Information</h2>
        <p style={styles.paragraph}>
          For any legal inquiries or concerns, please reach out to our legal team directly.
        </p>
        <ul style={styles.list}>
          <li><strong>Email:</strong> legal@secretproposalclub.com</li>
          <li><strong>Phone:</strong> +[Country Code] [Phone Number]</li>
          <li><strong>Mailing Address:</strong> [Secret Proposal Capital Club Legal Department, Address, City, Country]</li>
        </ul>
      </section>
    </div>

    <FooterSection />
    </div>
  );
};

// Inline styles for the page
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    // backgroundColor: '#1e1e1e',
    color: '#ffffff',
    padding: '50px 20px',
  },
  pageTitle: {
    fontSize: '2.5rem',
    color: '#FFDD57',
    textAlign: 'center',
    marginBottom: '30px',
  },
  section: {
    marginBottom: '50px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',

    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    webkitBackdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
  },
  sectionTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#f5db83',
  },
  paragraph: {
    fontSize: '1.2rem',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  list: {
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '1rem',
    lineHeight: '1.8',
    marginBottom: '20px',
  },
};

export default LegalPage;
