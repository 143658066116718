// sec/src/ProtectedRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';

const getUserData = () => {
  const role = localStorage.getItem('userRole');
  const token = localStorage.getItem('token');
  const isFormCompletedValue = localStorage.getItem('is_form_completed');

  // Handle different possible values for is_form_completed
  const isFormCompleted =
    isFormCompletedValue === 'true' ||
    isFormCompletedValue === '1' ||
    isFormCompletedValue === true ||
    isFormCompletedValue === 1;

  return { role, token, isFormCompleted };
};

const ProtectedRoute = ({ component: Component, allowedRoles }) => {
  const { role, token, isFormCompleted } = getUserData();

  if (token && allowedRoles.includes(role)) {
    // Allow access to the member form even if isFormCompleted is false
    if (
      role === 'member' &&
      !isFormCompleted &&
      window.location.pathname !== '/member-form'
    ) {
      // Redirect to member-form if not completed
      return <Navigate to="/member-form" replace />;
    }

    // Allow access to the partner form even if isFormCompleted is false
    if (
      role === 'partner' &&
      !isFormCompleted &&
      window.location.pathname !== '/partner-form'
    ) {
      // Redirect to partner-form if not completed
      return <Navigate to="/partner-form" replace />;
    }

    // If the form is completed or the user is accessing their form, allow access
    return <Component />;
  } else {
    console.warn(
      `Access Denied: ${!token ? 'No token' : ''}${!role ? ', No role' : ''}${
        !allowedRoles.includes(role) ? ', Unauthorized role' : ''
      }`
    );
    console.log('ProtectedRoute:', {
      role,
      token,
      isFormCompleted,
      pathname: window.location.pathname,
    });
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
