//sec\src\pages\partnerform.jsx

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const formSteps = [
  {
    id: 'personal_info',
    label: 'Personal Information',
    type: 'group',
    fields: [
      { id: 'company_name', label: 'Company Name', type: 'text', required: true },
      { id: 'contact_person', label: 'Contact Person', type: 'text', required: true },
      { id: 'email', label: 'Email Address', type: 'email', required: true },
      { id: 'phone_number', label: 'Phone Number', type: 'tel', required: true },
      { id: 'zip_code', label: '5-Digit Zip Code', type: 'number', required: true },
      { id: 'company_website', label: 'Company Website', type: 'text' },
      { id: 'document_upload', label: 'Upload Document', type: 'file' },
      {
        id: 'type_of_business',
        label: 'Type of Business',
        type: 'select',
        options: [
          'Luxury Goods',
          'Travel and Hospitality',
          'Financial Services',
          'Fashion and Accessories',
          'Wellness and Beauty',
          'Other',
        ],
        required: true,
      },
      { id: 'type_of_business_other', label: 'Please Specify', type: 'text', dependsOn: 'type_of_business', dependsOnValue: 'Other' },
      { id: 'brand_description', label: 'Description of Your Brand', type: 'textarea' },
      { id: 'partnership_reason', label: 'Why You Want to Partner with Secret Proposal Capital Club', type: 'textarea' },
      {
        id: 'partnership_interests',
        label: 'Partnership Interests',
        type: 'checkbox',
        options: ['Event Sponsorships', 'Product Placements', 'Collaborative Campaigns', 'Affiliate Programs'],
      },
      { id: 'additional_comments', label: 'Additional Comments or Questions', type: 'textarea' },
      {
        id: 'agreement',
        label:
          'I have read and agree to the Terms & Conditions and Partnership Agreement outlined by Secret Proposal Capital Club.',
        type: 'checkbox',
        required: true,
      },
    ],
  },
];

const PartnerForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const totalSteps = formSteps.length;

  const handleInputChange = (event, field) => {
    const { id, type } = field;
    let value;

    if (type === 'file') {
      value = event.target.files[0];
    } else if (type === 'checkbox' && !field.options) {
      // Single checkbox
      value = event.target.checked;
    } else if (type === 'checkbox' && field.options) {
      // Checkbox group
      const checkedOptions = formData[id] || [];
      if (event.target.checked) {
        checkedOptions.push(event.target.value);
      } else {
        const index = checkedOptions.indexOf(event.target.value);
        if (index > -1) {
          checkedOptions.splice(index, 1);
        }
      }
      value = checkedOptions;
    } else {
      value = event.target.value;
    }

    setFormData({ ...formData, [id]: value });

    // Clear validation error on input change
    if (errors[id]) {
      setErrors({ ...errors, [id]: null });
    }
  };

  const handleNext = () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      if (currentStep < totalSteps - 1) {
        setCurrentStep(currentStep + 1);
        window.scrollTo(0, 0);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);

      const dataToSubmit = new FormData();
      const userId = localStorage.getItem('user_id'); // Get user_id from local storage

      if (!userId) {
        alert('User ID is missing! Please log in.');
        setIsSubmitting(false);
        return;
      }

      dataToSubmit.append('user_id', userId); // Add user_id to form data

      // Add other form data
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (value instanceof File) {
          dataToSubmit.append(key, value);
        } else if (Array.isArray(value)) {
          value.forEach((item) => dataToSubmit.append(key, item));
        } else {
          dataToSubmit.append(key, value);
        }
      });

      try {
        const response = await axios.post('http://localhost:8001/partner-form', dataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        alert(response.data.message);

        // Update is_form_completed in local storage
        localStorage.setItem('is_form_completed', 'true');

        // Redirect to dashboard
        navigate('/dashboard');
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateStep = () => {
    const stepErrors = {};
    const currentFormStep = formSteps[currentStep];
    const fieldsToValidate = currentFormStep.fields || [];

    fieldsToValidate.forEach((field) => {
      const value = formData[field.id];
      if (field.required && (!value || value.length === 0)) {
        stepErrors[field.id] = 'This field is required';
      } else if (field.type === 'email' && value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          stepErrors[field.id] = 'Please enter a valid email address';
        }
      }
      // Additional validations can be added here
    });

    return stepErrors;
  };

  const currentFormStep = formSteps[currentStep];
  const progressPercentage = ((currentStep + 1) / totalSteps) * 100;

  // Styles (same as in MemberForm)
  const containerStyle = {
    width: '100%',
    margin: '0 auto',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: '#1F2A40',
    color: '#e0e0e0',
    minHeight: '100vh',
  };

  const titleStyle = {
    padding: '30px 20px 10px',
    textAlign: 'center',
    backgroundColor: '#141b2d',
    borderBottom: '1px solid rgb(12 17 37)',
  };

  const formContainerStyle = {
    width: '90%',
    maxWidth: '800px',
    margin: '20px auto',
    backgroundColor: '#141b2d',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.5s ease-in-out',
  };

  const progressBarContainerStyle = {
    marginBottom: '30px',
  };

  const progressBarBackgroundStyle = {
    height: '8px',
    backgroundColor: '#0b1521',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const progressBarStyle = {
    width: `${progressPercentage}%`,
    height: '100%',
    backgroundColor: '#2a3a59',
    transition: 'width 0.3s',
  };

  const progressTextStyle = {
    textAlign: 'right',
    marginTop: '10px',
    fontSize: '14px',
    color: '#a1a1a1',
  };

  const formFieldStyle = {
    marginBottom: '20px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#e0e0e0',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #273249',
    backgroundColor: '#273249',
    fontSize: '16px',
    color: '#e0e0e0',
    outline: 'none',
    boxSizing: 'border-box',
  };

  const inputErrorStyle = {
    borderColor: '#cf6679',
  };

  const errorTextStyle = {
    color: '#cf6679',
    fontSize: '14px',
    marginTop: '5px',
  };

  const inputFocusStyle = {
    borderColor: '#c1c1c1',
  };

  const radioOptionStyle = {
    marginBottom: '10px',
  };

  const navigationButtonsContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  };

  const buttonStyle = {
    padding: '12px 24px',
    backgroundColor: 'rgb(39, 50, 73)',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#e0e0e0',
    fontSize: '16px',
    fontWeight: '600',
    outline: 'none',
  };

  const previousButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#273249',
    color: '#e0e0e0',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#ffffff',
  };

  const subHeadingStyle = {
    fontSize: '20px',
    marginBottom: '15px',
    color: '#e0e0e0',
  };

  const radioLabelStyle = {
    fontSize: '16px',
    color: '#e0e0e0',
  };

  const checkboxStyle = {
    marginRight: '8px',
  };

  const textAreaStyle = {
    ...inputStyle,
    height: '100px',
    resize: 'vertical',
  };

  const buttonHoverStyle = {
    backgroundColor: 'rgb(59, 76, 113)',
  };

  // Animation styles
  const [animationClass, setAnimationClass] = useState('fade-in');

  const animateStepChange = (direction) => {
    setAnimationClass(direction === 'next' ? 'slide-in-right' : 'slide-in-left');
    setTimeout(() => {
      setAnimationClass('');
    }, 500);
  };

  const handleAnimatedNext = () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      animateStepChange('next');
      handleNext();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleAnimatedPrevious = () => {
    animateStepChange('previous');
    handlePrevious();
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        <h2 style={{ margin: '0', fontSize: '28px', color: '#ffffff' }}>Partner Assessment</h2>
      </div>
      <div style={formContainerStyle} className={animationClass} role="form" aria-labelledby="form-title">
        <div style={progressBarContainerStyle}>
          <div style={progressBarBackgroundStyle}>
            <div style={progressBarStyle}></div>
          </div>
          <p style={progressTextStyle}>
            Step {currentStep + 1} of {totalSteps}
          </p>
        </div>
        <div>
          <h3 style={headingStyle} id="form-title">
            {currentFormStep.label}
          </h3>
          {currentFormStep.fields.map((field) => {
            // Handle dependent fields
            if (field.dependsOn) {
              const dependsOnValue = formData[field.dependsOn];
              if (dependsOnValue !== field.dependsOnValue) {
                return null;
              }
            }
            return (
              <div key={field.id} style={formFieldStyle}>
                <label style={labelStyle} htmlFor={field.id}>
                  {field.label}
                  {field.required && ' *'}
                </label>
                {field.type === 'text' ? (
                  <>
                    <input
                      type="text"
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#273249')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'email' || field.type === 'tel' || field.type === 'number' ? (
                  <>
                    <input
                      type={field.type}
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#273249')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'textarea' ? (
                  <>
                    <textarea
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      style={{
                        ...textAreaStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    ></textarea>
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'file' ? (
                  <>
                    <input
                      type="file"
                      id={field.id}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        padding: '6px',
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'select' ? (
                  <>
                    <select
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    >
                      <option value="">Select an option</option>
                      {field.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'checkbox' && field.options ? (
                  field.options.map((option) => (
                    <div key={option} style={radioOptionStyle}>
                      <label style={radioLabelStyle}>
                        <input
                          type="checkbox"
                          name={field.id}
                          value={option}
                          checked={(formData[field.id] || []).includes(option)}
                          onChange={(e) => handleInputChange(e, field)}
                          style={checkboxStyle}
                          aria-invalid={errors[field.id] ? 'true' : 'false'}
                        />
                        {option}
                      </label>
                    </div>
                  ))
                ) : field.type === 'checkbox' ? (
                  <label style={radioLabelStyle}>
                    <input
                      type="checkbox"
                      id={field.id}
                      checked={formData[field.id] || false}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={checkboxStyle}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {field.label}
                  </label>
                ) : null}
                {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
              </div>
            );
          })}
        </div>
        <div style={navigationButtonsContainerStyle}>
          {currentStep > 0 && (
            <button
              onClick={handleAnimatedPrevious}
              style={previousButtonStyle}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#5a5a5a')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#273249')}
            >
              Previous
            </button>
          )}
          {currentStep < totalSteps - 1 && (
            <button
              onClick={handleAnimatedNext}
              style={buttonStyle}
              onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgb(39, 50, 73)')}
            >
              Next
            </button>
          )}
          {currentStep === totalSteps - 1 && (
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{
                ...buttonStyle,
                ...(isSubmitting ? { opacity: 0.6, cursor: 'not-allowed' } : {}),
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgb(39, 50, 73)')}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
