import React from 'react';
import { formatNumber } from '../../utils/formatNumber';

const TransactionHistory = ({ transactions = [] }) => {
    console.log('Transactions passed to component:', transactions);  // Ensure this logs the transactions

    if (transactions.length === 0) {
        return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: '#ccc' }}>No transactions found.</p>;
    }

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        fontFamily: 'Arial, sans-serif',
        color: '#e0e0e0',
    };

    const transactionsContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '1rem',
        width: '100%',
        maxWidth: '900px',
    };

    const transactionStyle = {
        border: '1px solid #333',
        borderRadius: '8px',
        padding: '1.5rem',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.2s ease',
        backgroundColor: '#2a2a2a',
    };

    const transactionTextStyle = {
        fontSize: '1rem',
        color: '#ccc',
        margin: '0.3rem 0',
    };

    return (
        <div style={containerStyle}>
            <div style={transactionsContainerStyle}>
                {transactions.map((transaction, index) => (
                    <div
                        key={index}
                        style={transactionStyle}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    >
                        <p style={transactionTextStyle}><strong>Transaction ID:</strong> {transaction.id}</p>
                        <p style={transactionTextStyle}><strong>Coins:</strong> {formatNumber(transaction.coins)}</p>
                        <p style={transactionTextStyle}><strong>Amount:</strong> £{transaction.amount}</p>
                        <p style={transactionTextStyle}><strong>Status:</strong> {transaction.status}</p>
                        <p style={transactionTextStyle}><strong>Date:</strong> {new Date(transaction.date).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransactionHistory;
