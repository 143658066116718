// src/components/EventPassList.js
import React, { useState, useEffect } from 'react';
import { getEventPasses } from './eventapi/api'; // Adjust the import path if necessary


const EventPassList = () => {
  const [eventPasses, setEventPasses] = useState([]);

  useEffect(() => {
    const fetchEventPasses = async () => {
      try {
        const data = await getEventPasses();
        console.log('Event passes received:', data);
        setEventPasses(data);
      } catch (error) {
        console.error('Error fetching event passes:', error);
      }
    };

    fetchEventPasses();
  }, []);

  const pageStyles = {
    padding: '20px',
    color: '#f5f5f5',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '1000px', // Increased max-width to accommodate content on mobile
    margin: '0 auto',
    textAlign: 'center',
  };

  const headerStyles = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  const noPassesMessage = {
    fontSize: '18px',
    color: '#aaa',
  };

  const errorCardStyles = {
    color: '#ff4d4d',
    margin: '15px 0',
    padding: '15px',
    border: '2px solid #333',
    borderRadius: '10px',
    maxWidth: '100%',
    transition: 'all 0.3s ease-in-out',
  };

  return (
    <div style={pageStyles}>
      <h2 style={headerStyles}>Your Event Passes</h2>
      {eventPasses.length > 0 ? (
        eventPasses.map((passItem) => {
          const event = passItem.event;

          if (!event) {
            console.error('Event data is missing for pass:', passItem);
            return (
              <div key={passItem.pass_id} style={errorCardStyles}>
                <h3>Error: Event Data Unavailable</h3>
                <p>We are unable to display the event details at this time.</p>
                <p>
                  Please try again later or contact support if the issue
                  persists.
                </p>
              </div>
            );
          }

          return (
            <div key={passItem.pass_id} className="ticketstyles">
              <div className="leftSection">
                <h3>{event.title || 'No Title'}</h3>
                <p>{event.description || 'No Description'}</p>
                <div className="priceSection">
                  <span>Price</span>
                  <p>{event.cost ? `${event.cost} Coins` : 'No Cost'}</p>
                </div>
              </div>
              <div className="rightSection">
                <div className="infoContainer">
                  <div className="infoColumn">
                    <p>
                      <strong>User ID:</strong> {passItem.user_id || 'N/A'}
                    </p>
                    <p>
                      <strong>Full Name:</strong> {passItem.full_name || 'N/A'}
                    </p>
                    <p>
                      <strong>Email:</strong> {passItem.email || 'N/A'}
                    </p>
                    <p>
                      <strong>City:</strong> {event.city || 'No City'}
                    </p>
                    <p>
                      <strong>Venue:</strong> {event.venue_name || 'No Venue'}
                    </p>
                  </div>
                  <div className="infoColumn">
                    <p>
                      <strong>Location:</strong> {event.location || 'No Location'}
                    </p>
                    <p>
                      <strong>Start Date:</strong>{' '}
                      {event.start_date
                        ? new Date(event.start_date).toLocaleString()
                        : 'No Start Date'}
                    </p>
                    <p>
                      <strong>End Date:</strong>{' '}
                      {event.end_date
                        ? new Date(event.end_date).toLocaleString()
                        : 'No End Date'}
                    </p>
                    <p>
                      <strong>Purchased on:</strong>{' '}
                      {new Date(passItem.createdAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p style={noPassesMessage}>You have not purchased any event passes.</p>
      )}
    </div>
  );
};

export default EventPassList;
