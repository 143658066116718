import React, { useEffect, useState } from "react";
import MatchCard from "../matchcardAi";
import { getMatches, startMatchmaking } from "../matchmakingApiCall";
import Loader from "../matchLoader/Loader";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";

const Matches = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMatchmaking, setIsMatchmaking] = useState(false); // Track matchmaking status

  const [isSidebar, setIsSidebar] = useState(true);

  // Fetch matches for the user
  const fetchMatches = async () => {
    setLoading(true);
    const data = await getMatches(); // Fetch the matches for the user
    setMatches(data);
    setLoading(false);
  };

  // Handle AI matchmaking
  const handleStartMatchmaking = async () => {
    setIsMatchmaking(true);
    try {
      const response = await startMatchmaking(); // Start matchmaking
      if (response.success) {
        fetchMatches(); // Refresh matches after matchmaking
        alert("Matchmaking started successfully"); // Show success message
      } else {
        alert(response.message || "Failed to start matchmaking");
      }
    } catch (error) {
      console.error("Error during matchmaking:", error);
      alert("Error during matchmaking");
    } finally {
      setIsMatchmaking(false);
    }
  };

  useEffect(() => {
    fetchMatches(); // Fetch matches on component mount
  }, []);

  // Define styles using const
  const styles = {
    container: {
      minHeight: "100vh",
      color: "#e2e8f0", // Light text color
      padding: "16px",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "24px",
    },
    buttonContainer: {
      textAlign: "center",
      marginBottom: "24px",
    },
    button: {
      padding: "8px 16px",
      borderRadius: "4px",
      color: "#fff",
      backgroundColor: isMatchmaking ? "#4a5568" : "#2d3748",
      border: "none",
      cursor: isMatchmaking ? "not-allowed" : "pointer",
      fontSize: "1rem",
    },
    noMatches: {
      textAlign: "center",
      color: "#a0aec0",
    },
  };

  return (


    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />


    <div style={styles.container}>
      <h1 style={styles.title}>Your Matches</h1>

      {/* Button to start AI matchmaking */}
      <div style={styles.buttonContainer}>
        <button
          onClick={handleStartMatchmaking}
          style={styles.button}
          disabled={isMatchmaking}
        >
          {isMatchmaking ? "Starting Matchmaking..." : "Start AI Matchmaking"}
        </button>
      </div>

      {/* Show loading indicator or display matches */}
      {loading ? (
        <Loader />
      ) : matches.length > 0 ? (
        matches.map((match) => (
          <MatchCard key={match.matchUserId} match={match} />
        ))
      ) : (
        <div style={styles.noMatches}>No matches found</div>
      )}
    </div>


    </main>
    </div>

  );
};

export default Matches;
