import React, { Component } from 'react';
import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import FooterSection from '../component/layout/footer';
import BannerSection from '../component/section/banner'; 
import MemberSection from '../component/section/member';
import AboutSection from '../component/section/about';
import StorySection from '../component/section/storysection';
import WorkSection from '../component/section/work';
import MemberTwoSection from '../component/section/memberTwo';
import GroupSection from '../component/section/group';
import AppsSection from '../component/section/apps';
import ClientSection from '../component/section/clints';
import Slider from '../component/section/slider';
import Kala from '../component/section/kala';
import Events from '../component/section/events';
import Service from '../component/section/service';
import Faq from '../component/section/faq';
import Commingsoon from '../component/section/commingsoon';
import WelcomeSection from '../component/section/WelcomeSection';


// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/css/style.css';
import '../assets/css/animate.css';
// import '././assets/css/icofont.min.css';


class HomePages extends Component {
    render() {
        return (
            <>
            <div>
                <Header2 />
                <Header />
                <BannerSection />
                <Slider/>  
                 <Kala/>
                <WelcomeSection/>
                <AboutSection />
                {/* <Commingsoon/> */}
                {/* <MemberSection /> */}
                <Service/>
                <WorkSection />
                <Events/>
                {/* <StorySection /> */}
                {/* <MemberTwoSection /> */}
                {/* <GroupSection /> */}
                <AppsSection />
                {/* <Faq/> */}
                <ClientSection />
                <FooterSection />
            </div>
            
            </>
        );
    }
}

export default HomePages;