// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import api from '../API/api';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = 1; // Replace with actual logged-in user ID
        const response = await api.get(`/users/${userId}`);
        setUserData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading profile: {error}</div>;

  return (
    <div className="profile-page">
      <h1 className="text-3xl font-bold">Profile Page</h1>
      {userData && (
        <div className="profile-details">
          <h2 className="text-2xl">{userData.username}</h2>
          <p>Email: {userData.email}</p>
          <p>Role: {userData.role}</p>
          <p>Status: {userData.status}</p>

          <h3 className="text-xl mt-4">Preferences</h3>
          <p>Relationship Goal: {userData.relationship_goal}</p>
          <p>Career Ambitions: {userData.career_ambitions}</p>
          <p>Hobbies: {userData.hobbies}</p>

          {/* Add Edit Profile Button */}
          <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Edit Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default Profile;
