// src/components/EventCard.js
import React from 'react';

const EventCard = ({ event, onBuyPass }) => {
  if (!event) {
    console.log('Event is undefined or null:', event);
    return null;
  }

  const handleBuyPass = () => {
    console.log('Buy Pass button clicked for event ID:', event.event_id || event.id);
    onBuyPass(event.event_id || event.id);
  };

  return (
    <div style={eventCardStyles} className="event-card">
      <h3 style={titleStyle}>{event.title || 'No Title'}</h3>
      <p style={descriptionStyle}>{event.description || 'No Description'}</p>
      <div style={infoContainer}>
        <p style={infoStyle}><strong>Start Date:</strong> {event.start_date ? new Date(event.start_date).toLocaleDateString() : 'N/A'}</p>
        <p style={infoStyle}><strong>End Date:</strong> {event.end_date ? new Date(event.end_date).toLocaleDateString() : 'N/A'}</p>
        <p style={infoStyle}><strong>Cost:</strong> {event.cost ? `${event.cost} Coins` : 'Free'}</p>
        <p style={infoStyle}><strong>Capacity:</strong> {event.capacity || 'No Capacity'}</p>
        <p style={infoStyle}><strong>Venue:</strong> {event.venue_name || 'N/A'}</p>
        <p style={infoStyle}><strong>Location:</strong> {event.location || 'N/A'}</p>
        <p style={infoStyle}><strong>City:</strong> {event.city || 'N/A'}</p>
      </div>
      <button
        style={buttonStyle}
        onMouseEnter={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
        onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
        onClick={handleBuyPass}
      >
        Buy Pass
      </button>
    </div>
  );
};

// CSS-in-JS styles with inset shadow and responsive adjustments
const eventCardStyles = {
  backgroundColor: 'rgb(20 27 45',
  padding: '16px',
  borderRadius: '12px',
  boxShadow: 'inset 0 4px 10px rgba(0, 0, 0, 0.5)',
  color: '#FFFFFF',
  maxWidth: '90%', // Responsive width
  width: '280px',
  margin: '20px auto',
  textAlign: 'center',
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  overflow: 'hidden',
  transform: 'scale(1)',
  animation: 'fadeIn 0.5s ease-in-out',
};

const titleStyle = {
  fontSize: '1.3rem',
  color: '#fff',
  marginBottom: '8px',
  textTransform: 'uppercase',
  fontWeight: '600',
};

const descriptionStyle = {
  fontSize: '0.9rem',
  color: '#A0A0A0 ',
  marginBottom: '16px',
  height: '40px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const infoContainer = {
  display: 'grid',
  gap: '4px',
  marginBottom: '16px',
};

const infoStyle = {
  fontSize: '0.85rem',
  color: '#D1D5DB',
  borderBottom: '1px solid #3A3B3E',
  paddingBottom: '4px',
};

const buttonStyle = {
  backgroundColor: '#26334f',
  color: '#121212',
  border: 'none',
  borderRadius: '20px',
  padding: '8px 16px',
  fontSize: '0.9rem',
  fontWeight: '600',
  cursor: 'pointer',
  transition: 'transform 0.2s ease, background-color 0.3s ease',
  marginTop: '10px',
};

const buttonHoverStyle = {
  backgroundColor: 'rgb(49 68 109)',
};

// CSS for fade-in animation and media queries (to be added in CSS file)
const styles = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.event-card:hover {
  transform: scale(1.05);
}

.event-card button:hover {
  background-color: #9A6CD1;
}

/* Media Query for Responsive Adjustments */
@media (max-width: 768px) {
  .event-card {
    width: 95%;
    padding: 12px;
  }
  .event-card h3 {
    font-size: 1.2rem;
  }
  .event-card p {
    font-size: 0.85rem;
  }
  .event-card button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}
`;

// Inject CSS styles for animations, hover effects, and media queries
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default EventCard;
