// src/scenes/matchcardAi/index.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa"; // For close icon
import { FiUser } from "react-icons/fi"; // For user icon

const cardContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "20px",
};

const cardStyle = {
  backgroundColor: "#1A202C",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.6)",
  borderRadius: "16px",
  padding: "24px",
  margin: "16px",
  transition: "transform 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease",
  transform: "scale(1)",
  overflow: "hidden",
  maxWidth: "350px",
  width: "100%",
  color: "#E2E8F0",
  opacity: 0,
};

const hoverStyle = {
  transform: "scale(1.05)",
  boxShadow: "0 12px 24px rgba(0, 0, 0, 0.8)",
};

const titleStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#E2E8F0",
  marginBottom: "16px",
  transition: "color 0.3s ease",
  display: "flex",
  alignItems: "center",
};

const titleHoverStyle = {
  color: "#f5db83",
};

const iconStyle = {
  marginRight: "10px",
};

const scoreStyle = {
  fontSize: "18px",
  color: "#A0AEC0",
};

const scoreHighlightStyle = {
  fontWeight: "bold",
  color: "#f5db83",
};

const buttonStyle = {
  marginTop: "24px",
  width: "100%",
  padding: "14px",
  backgroundColor: "#2d3748",
  color: "white",
  fontWeight: "bold",
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.4)",
  transition: "transform 0.2s ease, background-color 0.2s ease",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
};

const buttonHoverStyle = {
  backgroundColor: "rgb(52 67 93)",
  transform: "scale(1.02)",
};

const modalStyles = {
  content: {
    backgroundColor: "#2D3748",
    color: "#E2E8F0",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    borderRadius: "12px",
    maxWidth: "800px",
    width: "90%",
    border: "none",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.8)",
    maxHeight: "80vh",
    overflowY: "auto",
    position: "relative",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const closeButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "20px",
  backgroundColor: "transparent",
  border: "none",
  color: "#E2E8F0",
  fontSize: "24px",
  cursor: "pointer",
};

const subheadingStyle = {
  fontSize: "20px",
  fontWeight: "bold",
  color: "#fff",
  marginTop: "30px",
  borderBottom: "1px solid #4A5568",
  paddingBottom: "10px",
};

const fieldGridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "15px",
};

const fieldStyle = {
  marginBottom: "10px",
};

const labelStyle = {
  fontWeight: "bold",
  color: "#A0AEC0",
};

const valueStyle = {
  color: "#E2E8F0",
};

const headerStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};
const Name = {
  color: "#E2E8F0",
};



const avatarStyle = {
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundColor: "#4A5568",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "30px",
  color: "#E2E8F0",
  marginRight: "20px",
};

const MatchCard = ({ match }) => {
  const [hover, setHover] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [animateIn, setAnimateIn] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  useEffect(() => {
    // Start the entrance animation
    setAnimateIn(true);
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `http://localhost:8001/api/memberform/${match.matchUserId}`
      );
      setProfileData(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <div style={cardContainerStyle}>
      <div
        style={{
          ...cardStyle,
          ...(hover ? hoverStyle : {}),
          opacity: animateIn ? 1 : 0,
          transform: animateIn
            ? hover
              ? "scale(1.05)"
              : "scale(1)"
            : "scale(0.9)",
          transition: "all 0.5s ease",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          style={hover ? { ...titleStyle, ...titleHoverStyle } : titleStyle}
        >
          <FiUser style={iconStyle} />
          Match ID: {match.matchUserId}
        

        </div>
       
        <div style={scoreStyle}>
          Similarity Score:{" "}
          <span style={scoreHighlightStyle}>
            {match.similarity.toFixed(2)}
          </span>
        </div>
        <button
          style={
            buttonHover ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle
          }
          onClick={fetchProfileData}
          onMouseEnter={() => setButtonHover(true)}
          onMouseLeave={() => setButtonHover(false)}
        >
          View Profile
        </button>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
          contentLabel="Profile Data"
          style={modalStyles}
          ariaHideApp={false}
        >
          {profileData ? (
            <div>
              <button
                style={closeButtonStyle}
                onClick={() => setModalOpen(false)}
              >
                <FaTimes />
              </button>
              <div style={headerStyle}>
                <div style={avatarStyle}>
                  {profileData.full_name
                    ? profileData.full_name.charAt(0)
                    : "U"}
                </div>
                <h2 style={Name}>{profileData.full_name}'s Profile</h2>
              </div>

              {/* Personal Information */}
              <h3 style={subheadingStyle}>Personal Information</h3>
              <div style={fieldGridStyle}>
                {/* Repeat for each field */}
                <div style={fieldStyle}>
                  <span style={labelStyle}>User ID:</span>
                  <br />
                  <span style={valueStyle}>{profileData.user_id}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Preferred Name:</span>
                  <br />
                  <span style={valueStyle}>{profileData.preferred_name}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Email:</span>
                  <br />
                  <span style={valueStyle}>{profileData.email}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Phone Number:</span>
                  <br />
                  <span style={valueStyle}>{profileData.phone_number}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Date of Birth:</span>
                  <br />
                  <span style={valueStyle}>{profileData.dob}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Gender Identity:</span>
                  <br />
                  <span style={valueStyle}>{profileData.gender_identity}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Gender Identity (Other):</span>
                  <br />
                  <span style={valueStyle}>{profileData.gender_identity_other}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Nationality:</span>
                  <br />
                  <span style={valueStyle}>{profileData.nationality}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Home Town:</span>
                  <br />
                  <span style={valueStyle}>{profileData.home_town}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Zip Code:</span>
                  <br />
                  <span style={valueStyle}>{profileData.zip_code}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Home Address:</span>
                  <br />
                  <span style={valueStyle}>{profileData.home_address}</span>
                </div>
              </div>

              {/* Physical Attributes */}
              <h3 style={subheadingStyle}>Physical Attributes</h3>
              <div style={fieldGridStyle}>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Height:</span>
                  <br />
                  <span style={valueStyle}>{profileData.height}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Weight:</span>
                  <br />
                  <span style={valueStyle}>{profileData.weight}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Body Type:</span>
                  <br />
                  <span style={valueStyle}>{profileData.body_type}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Eye Color:</span>
                  <br />
                  <span style={valueStyle}>{profileData.eye_color}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Eye Color (Other):</span>
                  <br />
                  <span style={valueStyle}>{profileData.eye_color_other}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Hair Color:</span>
                  <br />
                  <span style={valueStyle}>{profileData.hair_color}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Hair Color (Other):</span>
                  <br />
                  <span style={valueStyle}>{profileData.hair_color_other}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Physical Fitness Level:</span>
                  <br />
                  <span style={valueStyle}>{profileData.physical_fitness_level}</span>
                </div>
              </div>

              {/* Family and Relationships */}
              <h3 style={subheadingStyle}>Family and Relationships</h3>
              <div style={fieldGridStyle}>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Marital Status:</span>
                  <br />
                  <span style={valueStyle}>{profileData.marital_status}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Marital Status (Other):</span>
                  <br />
                  <span style={valueStyle}>{profileData.marital_status_other}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Have Children:</span>
                  <br />
                  <span style={valueStyle}>{profileData.have_children}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Partner Children Preference:</span>
                  <br />
                  <span style={valueStyle}>{profileData.partner_children_preference}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Family Relationship Description:</span>
                  <br />
                  <span style={valueStyle}>{profileData.family_relationship_description}</span>
                </div>
              </div>

              {/* Education and Career */}
              <h3 style={subheadingStyle}>Education and Career</h3>
              <div style={fieldGridStyle}>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Education Level:</span>
                  <br />
                  <span style={valueStyle}>{profileData.education_level}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Education Level (Other):</span>
                  <br />
                  <span style={valueStyle}>{profileData.education_level_other}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Occupation:</span>
                  <br />
                  <span style={valueStyle}>{profileData.occupation}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Industry:</span>
                  <br />
                  <span style={valueStyle}>{profileData.industry}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Income Bracket:</span>
                  <br />
                  <span style={valueStyle}>{profileData.income_bracket}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Career Ambitions:</span>
                  <br />
                  <span style={valueStyle}>{profileData.career_ambitions}</span>
                </div>
              </div>

              {/* Lifestyle */}
              <h3 style={subheadingStyle}>Lifestyle</h3>
              <div style={fieldGridStyle}>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Do You Smoke:</span>
                  <br />
                  <span style={valueStyle}>{profileData.do_you_smoke}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Do You Drink Alcohol:</span>
                  <br />
                  <span style={valueStyle}>{profileData.do_you_drink_alcohol}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Dietary Restrictions:</span>
                  <br />
                  <span style={valueStyle}>{profileData.dietary_restrictions}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Hobbies/Interests:</span>
                  <br />
                  <span style={valueStyle}>{profileData.hobbies_interests}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Languages Spoken:</span>
                  <br />
                  <span style={valueStyle}>{profileData.languages_spoken}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Pets:</span>
                  <br />
                  <span style={valueStyle}>{profileData.pets}</span>
                </div>
                <div style={fieldStyle}>
                  <span style={labelStyle}>Volunteering:</span>
                  <br />
                  <span style={valueStyle}>{profileData.volunteering}</span>
                </div>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default MatchCard;
