// ProfilePage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaSave } from 'react-icons/fa';

import Topbar from '../global/Topbar';
import Sidebar from '../global/Sidebar';

// Custom EditableRow component for inline editing
const EditableRow = ({
  label,
  value,
  name,
  isEditing,
  onChange,
  type = 'text',
  options = [],
  required,
}) => {
  return (
    <div style={styles.formGroup}>
      <label style={styles.formLabel}>
        {label}
        {required && <span style={styles.required}> *</span>}
      </label>
      {isEditing ? (
        type === 'select' ? (
          <select
            name={name}
            value={value || ''}
            onChange={onChange}
            style={styles.formInput}
            required={required}
          >
            <option value="">Select</option>
            {options.map((option) =>
              typeof option === 'string' ? (
                <option key={option} value={option}>
                  {option}
                </option>
              ) : (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              )
            )}
          </select>
        ) : type === 'textarea' ? (
          <textarea
            name={name}
            value={value || ''}
            onChange={onChange}
            style={{ ...styles.formInput, height: '100px', resize: 'vertical' }}
            required={required}
          />
        ) : type === 'checkbox' ? (
          options.map((option) => (
            <label key={option} style={styles.checkboxLabel}>
              <input
                type="checkbox"
                name={name}
                value={option}
                checked={value && value.includes(option)}
                onChange={onChange}
                style={styles.checkboxInput}
              />
              {option}
            </label>
          ))
        ) : type === 'radio' ? (
          options.map((option) => (
            <label key={option} style={styles.radioLabel}>
              <input
                type="radio"
                name={name}
                value={option}
                checked={value === option}
                onChange={onChange}
                style={styles.radioInput}
              />
              {option}
            </label>
          ))
        ) : (
          <input
            type={type}
            name={name}
            value={value || ''}
            onChange={onChange}
            style={styles.formInput}
            required={required}
          />
        )
      ) : (
        <div style={styles.formValue}>
          {value && value.length > 0 ? (
            Array.isArray(value) ? (
              value.join(', ')
            ) : (
              value
            )
          ) : (
            <span style={styles.placeholder}>Not provided</span>
          )}
        </div>
      )}
    </div>
  );
};

const ProfilePage = () => {
  const [profileData, setProfileData] = useState({});
  const [userRole, setUserRole] = useState('');
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const [isSidebar, setIsSidebar] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    const storedUserRole = localStorage.getItem('userRole');

    if (!userId) {
      navigate('/login');
      return;
    }

    setUserRole(storedUserRole);

    let apiUrl;

    if (storedUserRole === 'partner') {
      apiUrl = `http://localhost:8001/partner-form/${userId}`;
    } else if (storedUserRole === 'member') {
      apiUrl = `http://localhost:8001/member-form/${userId}`;
    } else {
      // For 'admin', 'agent', 'support'
      apiUrl = `http://localhost:8001/user/${userId}`;
    }

    axios
      .get(apiUrl)
      .then((response) => setProfileData(response.data))
      .catch((err) => {
        setError('Error fetching profile data.');
        console.error(err);
      });
  }, [navigate]);

  const handleEditClick = () => setIsEditing(true);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setProfileData((prevData) => {
      if (type === 'checkbox') {
        const currentValue = prevData[name] || [];
        if (checked) {
          return { ...prevData, [name]: [...currentValue, value] };
        } else {
          return { ...prevData, [name]: currentValue.filter((val) => val !== value) };
        }
      } else {
        return { ...prevData, [name]: value };
      }
    });
  };

  const handleSaveClick = () => {
    const userId = localStorage.getItem('user_id');

    let apiUrl;

    if (userRole === 'partner') {
      apiUrl = `http://localhost:8001/update-partner-form/${userId}`;
    } else if (userRole === 'member') {
      apiUrl = `http://localhost:8001/update-member-form/${userId}`;
    } else {
      // For 'admin', 'agent', 'support'
      apiUrl = `http://localhost:8001/update-user/${userId}`;
    }

    axios
      .put(apiUrl, profileData)
      .then(() => setIsEditing(false))
      .catch((err) => {
        setError('Error updating profile data.');
        console.error(err);
      });
  };

  if (error) return <div>{error}</div>;
  if (!profileData) return <div>Loading...</div>;

  const getEditableFields = () => {
    if (userRole === 'partner') {
      return partnerFields.map((section) => (
        <div key={section.id} style={styles.section}>
          <h3 style={styles.sectionTitle}>{section.label}</h3>
          {section.fields.map((field) => (
            <EditableRow
              key={field.id}
              label={field.label}
              value={profileData[field.id]}
              name={field.id}
              type={field.type}
              options={field.options || []}
              isEditing={isEditing}
              onChange={handleInputChange}
              required={field.required}
            />
          ))}
        </div>
      ));
    } else if (userRole === 'member') {
      return memberFields.map((section) => (
        <div key={section.id} style={styles.section}>
          <h3 style={styles.sectionTitle}>{section.label}</h3>
          {section.fields.map((field) => (
            <EditableRow
              key={field.id}
              label={field.label}
              value={profileData[field.id]}
              name={field.id}
              type={field.type}
              options={field.options || []}
              isEditing={isEditing}
              onChange={handleInputChange}
              required={field.required}
            />
          ))}
        </div>
      ));
    } else {
      // For 'admin', 'agent', 'support'
      return (
        <>
          <EditableRow
            label="Username"
            value={profileData.username}
            name="username"
            isEditing={isEditing}
            onChange={handleInputChange}
            required
          />
          <EditableRow
            label="Email"
            value={profileData.email}
            name="email"
            isEditing={isEditing}
            onChange={handleInputChange}
            required
          />
        </>
      );
    }
  };

  const getProfileHeader = () => {
    return (
      <>
        <img
          src={profileData.avatar_url || 'https://via.placeholder.com/100'}
          alt="Profile Avatar"
          style={styles.avatar}
        />
        <h2 style={styles.profileName}>{profileData.username || 'Username'}</h2>
        <p style={styles.profileDetail}>{profileData.role || 'Role'}</p>
      </>
    );
  };

  return (
    <div className="app" style={styles.app}>
      <Sidebar isSidebar={isSidebar} />
      <main className="content" style={styles.content}>
        <Topbar setIsSidebar={setIsSidebar} />

        <div style={styles.profileContainer}>
          <div style={styles.profileLayout}>
            <aside style={styles.profileHeader}>
              <div style={styles.avatarSection}>{getProfileHeader()}</div>
            </aside>

            <section style={styles.formSection}>
              <div style={styles.detailsSection}>
                <form style={styles.form}>{getEditableFields()}</form>
                {isEditing ? (
                  <button style={styles.saveBtn} onClick={handleSaveClick}>
                    <FaSave style={styles.icon} /> Save Changes
                  </button>
                ) : (
                  <button style={styles.editBtn} onClick={handleEditClick}>
                    <FaEdit style={styles.icon} /> Edit Profile
                  </button>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

// Member Fields
const memberFields = [
  // Personal Information
  {
    id: 'personal_information',
    label: 'Personal Information',
    fields: [
      { id: 'full_name', label: 'Full Legal Name', type: 'text', required: true },
      { id: 'preferred_name', label: 'Preferred Name', type: 'text', required: true },
      { id: 'email', label: 'Email Address', type: 'email', required: true },
      { id: 'phone_number', label: 'Phone Number', type: 'tel', required: true },
      { id: 'dob', label: 'Date of Birth', type: 'date', required: true },
      {
        id: 'gender_identity',
        label: 'Gender Identity',
        type: 'select',
        required: true,
        options: ['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other'],
      },
      { id: 'nationality', label: 'Nationality', type: 'text' },
      { id: 'home_town', label: 'Home Town', type: 'text' },
      { id: 'zip_code', label: 'Zip Code', type: 'text' },
    ],
  },
  // Home Address
  {
    id: 'home_address',
    label: 'Home Address',
    fields: [{ id: 'home_address', label: 'Home Address', type: 'text' }],
  },
  // Physical Details
  {
    id: 'physical_details',
    label: 'Physical Details',
    fields: [
      {
        id: 'height',
        label: 'Height',
        type: 'select',
        options: ['Below 5\'0"', '5\'0" - 5\'5"', '5\'6" - 6\'0"', 'Above 6\'0"'],
      },
      { id: 'weight', label: 'Weight', type: 'text' },
      {
        id: 'body_type',
        label: 'Body Type',
        type: 'select',
        options: ['Slim', 'Athletic', 'Average', 'Curvy', 'Plus size'],
      },
      {
        id: 'eye_color',
        label: 'Eye Color',
        type: 'select',
        options: ['Brown', 'Blue', 'Green', 'Hazel', 'Other'],
      },
      {
        id: 'hair_color',
        label: 'Hair Color',
        type: 'select',
        options: ['Black', 'Brown', 'Blonde', 'Red', 'Other'],
      },
      {
        id: 'physical_fitness_level',
        label: 'Physical Fitness Level',
        type: 'select',
        options: ['Not active', 'Moderately active', 'Highly active', 'Very fit'],
      },
    ],
  },
  // Family and Relationship Preferences
  {
    id: 'family_relationship',
    label: 'Family and Relationship Preferences',
    fields: [
      {
        id: 'marital_status',
        label: 'Marital Status',
        type: 'select',
        options: ['Single', 'Divorced', 'Widowed', 'Other'],
      },
      {
        id: 'have_children',
        label: 'Do you have children?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      {
        id: 'partner_children_preference',
        label: 'Are you open to a partner with children?',
        type: 'select',
        options: ['Yes', 'No', 'Maybe'],
      },
      {
        id: 'family_relationship_description',
        label: 'Family Relationship Description',
        type: 'textarea',
      },
    ],
  },
  // Education & Career
  {
    id: 'education_career',
    label: 'Education & Career',
    fields: [
      {
        id: 'education_level',
        label: 'Education Level',
        type: 'select',
        options: [
          'High school',
          'Associate degree',
          'Bachelor’s degree',
          'Master’s degree',
          'Doctorate',
          'Other',
        ],
      },
      { id: 'occupation', label: 'Occupation', type: 'text' },
      {
        id: 'industry',
        label: 'Industry',
        type: 'select',
        options: ['Technology', 'Healthcare', 'Finance', 'Education', 'Arts', 'Other'],
      },
      {
        id: 'income_bracket',
        label: 'Income Bracket',
        type: 'select',
        options: [
          'Below $30,000',
          '$30,000 - $60,000',
          '$60,000 - $100,000',
          '$100,000 - $150,000',
          'Above $150,000',
        ],
      },
      { id: 'career_ambitions', label: 'Career Ambitions', type: 'textarea' },
    ],
  },
  // Lifestyle & Preferences
  {
    id: 'lifestyle_preferences',
    label: 'Lifestyle & Preferences',
    fields: [
      {
        id: 'do_you_smoke',
        label: 'Do you smoke?',
        type: 'radio',
        options: ['Yes', 'No', 'Occasionally'],
      },
      {
        id: 'do_you_drink_alcohol',
        label: 'Do you drink alcohol?',
        type: 'radio',
        options: ['Yes', 'No', 'Socially'],
      },
      {
        id: 'dietary_restrictions',
        label: 'Dietary Restrictions or Preferences',
        type: 'textarea',
      },
      { id: 'hobbies_interests', label: 'Hobbies and Interests', type: 'textarea' },
      { id: 'languages_spoken', label: 'Languages Spoken', type: 'textarea' },
      {
        id: 'pets',
        label: 'Pets',
        type: 'radio',
        options: ['No pets', 'Yes'],
      },
    ],
  },
  // Social & Online Presence
  {
    id: 'social_online_presence',
    label: 'Social & Online Presence',
    fields: [
      {
        id: 'volunteering',
        label: 'Do you engage in volunteering?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      { id: 'social_media_profiles', label: 'Social Media Profiles', type: 'textarea' },
    ],
  },
];

// Partner Fields
const partnerFields = [
  {
    id: 'personal_info',
    label: 'Personal Information',
    fields: [
      { id: 'company_name', label: 'Company Name', type: 'text', required: true },
      { id: 'contact_person', label: 'Contact Person', type: 'text', required: true },
      { id: 'email', label: 'Email Address', type: 'email', required: true },
      { id: 'phone_number', label: 'Phone Number', type: 'tel', required: true },
      { id: 'zip_code', label: '5 Digit Zip code', type: 'number', required: true },
      { id: 'company_website', label: 'Company Website', type: 'text' },
      {
        id: 'type_of_business',
        label: 'Type of Business',
        type: 'select',
        options: [
          'Luxury Goods',
          'Travel and Hospitality',
          'Financial Services',
          'Fashion and Accessories',
          'Wellness and Beauty',
          'Other (Please Specify)',
        ],
        required: true,
      },
      { id: 'brand_description', label: 'Description of Your Brand', type: 'textarea' },
      {
        id: 'partnership_reason',
        label: 'Why You Want to Partner with Secret Proposal Capital Club',
        type: 'textarea',
      },
      {
        id: 'partnership_interests',
        label: 'Partnership Interests',
        type: 'checkbox',
        options: [
          'Event Sponsorships',
          'Product Placements',
          'Collaborative Campaigns',
          'Affiliate Programs',
        ],
      },
      {
        id: 'additional_comments',
        label: 'Additional Comments or Questions',
        type: 'textarea',
      },
      {
        id: 'agreement',
        label:
          'I have read and agree to the Terms & Conditions and Partnership Agreement outlined by Secret Proposal Capital Club.',
        type: 'checkbox',
        required: true,
      },
    ],
  },
];

const styles = {
  app: {
    minHeight: '100vh',
  },
  content: {
    padding: '20px',
  },
  profileContainer: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  profileLayout: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    alignItems: 'flex-start',
  },
  profileHeader: {
    width: '100%',
    maxWidth: '300px',
    borderRadius: '10px',
    padding: '30px',
    textAlign: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#1F2A40',
  },
  avatarSection: {
    textAlign: 'center',
  },
  avatar: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    marginBottom: '20px',
    objectFit: 'cover',
    border: '3px solid #2E4A62',
  },
  profileName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: '10px',
  },
  profileDetail: {
    fontSize: '16px',
    color: '#D1D1D1',
    marginBottom: '5px',
  },
  formSection: {
    flex: '1 1 600px',
    borderRadius: '10px',
    padding: '30px',
    backgroundColor: '#1F2A40',
  },
  detailsSection: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    marginBottom: '40px',
  },
  sectionTitle: {
    fontSize: '20px',
    marginBottom: '20px',
    color: '#FFFFFF',
    borderBottom: '2px solid #2E4A62',
    paddingBottom: '10px',
  },
  formGroup: {
    marginBottom: '20px',
  },
  formLabel: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#FFFFFF',
  },
  required: {
    color: '#FF6B6B',
    marginLeft: '5px',
  },
  formInput: {
    width: '100%',
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #2E4A62',
    backgroundColor: '#1F2A40',
    color: '#FFFFFF',
    fontSize: '16px',
  },
  formValue: {
    padding: '12px',
    backgroundColor: 'rgb(255 255 255 / 15%)',
    borderRadius: '5px',
    color: '#FFFFFF',
    fontSize: '16px',
  },
  placeholder: {
    color: '#777777',
    fontStyle: 'italic',
  },
  radioLabel: {
    display: 'inline-block',
    marginRight: '15px',
    color: '#FFFFFF',
  },
  radioInput: {
    marginRight: '8px',
  },
  checkboxLabel: {
    display: 'block',
    marginBottom: '8px',
    color: '#FFFFFF',
  },
  checkboxInput: {
    marginRight: '8px',
  },
  editBtn: {
    backgroundColor: '#2E4A62',
    color: 'white',
    border: 'none',
    padding: '12px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  saveBtn: {
    backgroundColor: '#28A745',
    color: 'white',
    border: 'none',
    padding: '12px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  icon: {
    marginRight: '8px',
  },
};

export default ProfilePage;
