import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AboutUs() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Adjust styles based on window width
  const responsiveStyles = {
    container: {
      ...styles.container,
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      padding: windowWidth <= 480 ? '30px 20px' : '70px',
    },
    leftSection: {
      ...styles.leftSection,
      paddingRight: windowWidth <= 768 ? '0' : '40px',
      marginBottom: windowWidth <= 768 ? '30px' : '0',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    aboutTitle: {
      ...styles.aboutTitle,
      fontSize:
        windowWidth <= 480
          ? '2rem'
          : windowWidth <= 768
          ? '2.5rem'
          : '3rem',
    },
    aboutDescription: {
      ...styles.aboutDescription,
      fontSize: windowWidth <= 480 ? '1rem' : '1.2rem',
    },
    heading: {
      ...styles.heading,
      fontSize:
        windowWidth <= 480
          ? '1.5rem'
          : windowWidth <= 768
          ? '1.8rem'
          : '2rem',
    },
    text: {
      ...styles.text,
      fontSize: windowWidth <= 480 ? '0.9rem' : '1rem',
    },
  };

  return (
    <div style={responsiveStyles.container}>
      <div style={responsiveStyles.leftSection}>
        <h1 style={responsiveStyles.aboutTitle}>About Us</h1>
        <p style={responsiveStyles.aboutDescription}>
          At Secret Proposal Capital Club, we believe in the power of genuine
          connections. Our mission is to offer a secure, discreet, and highly
          personalized matchmaking experience for high-net-worth individuals.
        </p>
      </div>
      <div style={styles.rightSection}>
        <div style={styles.serviceBox}>
          <h3 style={responsiveStyles.heading}>Our Vision</h3>
          <p style={responsiveStyles.text}>
            Our vision is to redefine the art of matchmaking by providing a
            platform for elite individuals to find their perfect match through
            curated events and personalized services.
          </p>
          <LinkComponent to="/">
            <span>Read More</span>
          </LinkComponent>
        </div>
        <div style={styles.serviceBox}>
          <h3 style={responsiveStyles.heading}>Our Values</h3>
          <p style={responsiveStyles.text}>
            <strong>Privacy:</strong> Your confidentiality is our top priority.
            <br />
            <strong>Exclusivity:</strong> We host events for verified,
            high-net-worth individuals.
            <br />
            <strong>Quality:</strong> We partner with reputable brands to ensure
            the highest standards.
          </p>
          <LinkComponent to="/">
            <span>Read More</span>
          </LinkComponent>
        </div>
        <div style={styles.serviceBox}>
          <h3 style={responsiveStyles.heading}>Our Team</h3>
          <p style={responsiveStyles.text}>
            Meet the dedicated team behind Secret Proposal Capital Club,
            committed to making your matchmaking journey seamless and enjoyable.
          </p>
          <LinkComponent to="/">
            <span>Read More</span>
          </LinkComponent>
        </div>
      </div>
    </div>
  );
}

// Custom Link component to handle hover effects
function LinkComponent({ to, children }) {
  const [isHovered, setIsHovered] = useState(false);

  const linkStyle = {
    ...styles.link,
    color: isHovered ? '#FFF' : '#C8C8C8',
  };

  return (
    <Link
      to={to}
      style={linkStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </Link>
  );
}

const styles = {
  container: {
    display: 'flex',
    padding: '70px',
    background: 'linear-gradient(180deg, #0f1e2f 0%, #0f1e2f 100%)',
    color: '#ffffff',
    fontFamily: "'Roboto', sans-serif",
  },
  leftSection: {
    flex: 1,
    paddingRight: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  aboutTitle: {
    fontWeight: '700',
    marginBottom: '20px',
    color: '#f5db83',
  },
  aboutDescription: {
    lineHeight: '1.6',
    fontWeight: '300',
  },
  serviceBox: {
    borderBottom: '1px solid #444',
    padding: '20px 0',
  },
  heading: {
    fontWeight: '600',
    marginBottom: '10px',
    color: '#f5db83',
  },
  text: {
    lineHeight: '1.6',
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
    fontWeight: '500',
    transition: 'color 0.3s',
  },
};

export default AboutUs;
