//sec/src/scenes/dashboard/index.jsx
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material"; 


import axios from 'axios';
import { Link } from 'react-router-dom';

import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";

import AddReactionIcon from "@mui/icons-material/AddReaction";
import EventIcon from "@mui/icons-material/Event";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";

import { useState,useEffect } from "react";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSidebarVisible = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebar, setIsSidebar] = useState(true);

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [amountSum, setAmountSum] = useState(0);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await axios.get('http://localhost:8001/api/totalmembers');
        setCount(response.data.count);

        const response2 = await axios.get('http://localhost:8001/api/totalusers');
        setCount2(response2.data.count); // Use `count` consistently here

        const response3 = await axios.get('http://localhost:8001/api/totalevents');
        setEventCount(response3.data.count); // Set the event count

        const response4 = await axios.get('http://localhost:8001/api/latestTransactions');
        setTransactions(response4.data); // Store the latest transactions

        const response5 = await axios.get('http://localhost:8001/api/amountsum');
        setAmountSum(response5.data.totalAmount);
        
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    };
    fetchCount();
  }, []);


  return (
    <div className="app">
      <Sidebar isSidebar={isSidebarVisible} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          {/* HEADER */}
          <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isSmallScreen ? "flex-start" : "center"}
          >
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
            <Button
              sx={{
                mt: isSmallScreen ? "10px" : "0",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download Reports
            </Button>
          </Box>

          {/* GRID & CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns={isSmallScreen ? "1fr" : "repeat(12, 1fr)"}
            gridAutoRows="140px"
            gap="20px"
          >
            {/* ROW 1 */}
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={count}
                subtitle="Total Members"
                progress="0.75"
                increase="+14%"
                icon={
                  <AddReactionIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={count2}
                subtitle="Total Users"
                progress="0.50"
                increase="+21%"
                icon={
                  <PointOfSaleIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={eventCount}
                subtitle="Upcoming Events"
                progress="0.30"
                increase="+5%"
                icon={
                  <EventIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 3"}
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="1,325,134"
                subtitle="Pending Verifications"
                progress="0.80"
                increase="+43%"
                icon={
                  <HourglassTopIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>

            {/* ROW 2 */}
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 8"}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt="25px"
                p={isSmallScreen ? "0 15px" : "0 30px"}
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mb={isSmallScreen ? "15px" : "0"}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Revenue Generated
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={colors.greenAccent[500]}
                  >
                    ${amountSum.toFixed(2)}
                  </Typography>
                </Box>
                {/* <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box> */}
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} />
              </Box>
            </Box>



            <Box
      gridColumn={isSmallScreen ? 'span 12' : 'span 4'}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
      overflow="auto"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`4px solid ${colors.primary[500]}`}
        p="15px"
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Recent Transactions
        </Typography>
      </Box>
      {transactions.map((transaction) => (
        <Box
          key={transaction.id}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          p="15px"
        >
          <Box>
            <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
              {transaction.id}
            </Typography>
            <Typography color={colors.grey[100]}>
              User ID: {transaction.user_id}
            </Typography>
          </Box>
          <Box color={colors.grey[100]}>
            {new Date(transaction.date).toLocaleDateString()} {/* Format the date */}
          </Box>
          <Box
            backgroundColor={colors.greenAccent[500]}
            p="5px 10px"
            borderRadius="4px"
          >
            ${transaction.amount}
          </Box>
        </Box>
      ))}
    </Box>



            {/* ROW 3 */}

            {/* <Box
              gridColumn={isSmallScreen ? "span 12" : "span 4"}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              p="30px"
            >
              <Typography variant="h5" fontWeight="600">
                Campaign
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <ProgressCircle size="125" />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                >
                  $48,352 revenue generated
                </Typography>
                <Typography>
                  Includes extra misc expenditures and costs
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 4"}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px" }}
              >
                Sales Quantity
              </Typography>
              <Box height="250px" mt="-20px">
                <BarChart isDashboard={true} />
              </Box>
            </Box>
            <Box
              gridColumn={isSmallScreen ? "span 12" : "span 4"}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              padding="30px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "15px" }}
              >
                Geography Based Traffic
              </Typography>
              <Box height="200px">
                <GeographyChart isDashboard={true} />
              </Box>
            </Box> */}


            
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Dashboard;
