import React, { useState } from 'react';
import axios from 'axios';
import Loader from '../Loader';
import { formatNumber } from '../../utils/formatNumber';

const coinPackages = [
    { coins: 1000, amount: 10 },
    { coins: 2500, amount: 25 },
    { coins: 5000, amount: 50 },
    { coins: 10000, amount: 100 },
    { coins: 25000, amount: 250 },
    { coins: 50000, amount: 500 },
];

const PurchaseCoins = ({ onCoinPurchase }) => {
    const [loading, setLoading] = useState(false);

    const getAuthToken = () => {
        return localStorage.getItem('authToken');
    };

    const handleCheckout = async (coins) => {
        setLoading(true);
        try {
            const token = getAuthToken();
            const response = await axios.post(
                'http://localhost:8001/api/payment/purchase',
                {
                    userId: localStorage.getItem('userId'),
                    coins,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Redirect to Stripe Checkout
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error during checkout:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loader />;

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        fontFamily: 'Arial, sans-serif',
        color: '#e0e0e0',
    };

    const packagesContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '1.5rem',
        width: '100%',
        maxWidth: '800px',
    };

    const packageStyle = {
        border: '1px solid #333',
        borderRadius: '8px',
        padding: '1.5rem',
        textAlign: 'center',
        boxShadow: '0 4px 12px rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        backgroundColor: '#1e1e1e',
    };

    const packageText = {
        fontSize: '1.25rem',
        fontWeight: '500',
        color: '#e0e0e0',
        margin: '0.5rem 0',
    };

    const buttonStyle = {
        marginTop: '1rem',
        padding: '0.8rem 1.5rem',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(255, 255, 255, 0.1)',
        transition: 'background-color 0.3s ease, transform 0.2s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3',
    };

    return (
        <div style={containerStyle}>
            <div style={packagesContainerStyle}>
                {coinPackages.map((pkg) => (
                    <div
                        key={pkg.coins}
                        style={packageStyle}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(255, 255, 255, 0.2)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 12px rgba(255, 255, 255, 0.1)';
                        }}
                    >
                        <p style={packageText}>
                            {formatNumber(pkg.coins)} Coins - £{pkg.amount}
                        </p>
                        <button
                            style={buttonStyle}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                            onClick={() => handleCheckout(pkg.coins)}
                        >
                            Purchase
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PurchaseCoins;
