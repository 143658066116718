import React, { Component } from "react";



class ClientSection extends Component {
//   state = {};
  render() {
    return (
      <section className="clints-section padding-tb">
        <div className="container">
          <div className="section-header">
            <h4 className="theme-color"> What Our Members Say</h4>
            <h2>Client’s Feed back Latest Reviews From My Clients</h2>
          </div>
          <div className="section-wrapper">
            <div className="clients">
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                     incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Rajib Raj</h6>
                      <span>UI Designer</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                 <div className="clientimg1"></div>

                </div>
              </div>
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                     incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Ummi Nishat</h6>
                      <span>Founder</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                <div className="clientimg2"></div>

                </div>
              </div>
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                     incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Somrat Islam</h6>
                      <span>UI Designer</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                <div className="clientimg3"></div>

                </div>
              </div>
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                     incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Zinat Zata</h6>
                      <span>Student</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                <div className="clientimg4"></div>

                </div>
              </div>
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                    incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Sajahan Sagor</h6>
                      <span>UI Designer</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                <div className="clientimg5"></div>

                </div>
              </div>
              <div className="client-list">
                <div className="client-content">
                  <p>
                    {" "}
                    "The Secret Proposal Capital Club exceeded my expectations. I met 
                     incredible people and found my perfect match"{" "}
                  </p>
                  <div className="client-info">
                    <div className="name-desi">
                      <h6>Angel Mili</h6>
                      <span>UI Designer</span>
                    </div>
                    <div className="rating">
                      <ul>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                        <li>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="client-thumb">

                 <div className="clientimg6"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ClientSection;
