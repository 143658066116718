//sec/src/scenes/eventapi/api.js
import axios from 'axios';

// Set dynamic baseURL based on environment
const apiBaseURL = process.env.NODE_ENV === 'production' ? 'https://your-production-url.com/api' : 'http://localhost:8001/api';

// Create an Axios instance
const api = axios.create({
  baseURL: apiBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Get the token from localStorage
const getToken = () => localStorage.getItem('token');

// Attach token to headers dynamically for each request
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn('No token found, request might fail.');
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);

// Function to create an event
export const createEvent = async (eventData) => {
  try {
    console.log('Creating event:', eventData);
    const response = await api.post('/events', eventData);
    console.log('Event created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating event:', error.response?.data || error.message);
    throw error;  // Rethrow to let the caller handle the error
  }
};

// Function to fetch all events
export const getEvents = async () => {
  try {
    console.log('Fetching events...');
    const response = await api.get('/events');
    console.log('Events fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error.response?.data || error.message);
    throw error;
  }
};

// Function to buy an event pass
export const buyEventPass = async (eventId) => {
  try {
    console.log('Purchasing event pass for event ID:', eventId);
    const response = await api.post('/buy-pass', { event_id: eventId });
    console.log('Pass purchased successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error buying event pass:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventPasses = async () => {
  try {
    console.log('Fetching event passes...');
    const response = await api.get('/event-passes', {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    });
    console.log('Event passes fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching event passes:', error.response?.data || error.message);
    throw error;
  }
};

export default api;
