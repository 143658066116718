// AboutUs.js
import React from 'react';

const AboutUs = () => {
  return (
    <div style={styles.container}>
      {/* Welcome Section */}
      <section style={styles.section}>
        <h1 style={styles.title}>Welcome to Secret Proposal Capital Club</h1>
        <p style={styles.text}>
          At the Secret Proposal Capital Club, we offer an unparalleled matchmaking experience tailored for high-net-worth individuals. Our club is built on discretion, confidentiality, and integrity.
        </p>
      </section>

      {/* Mission Section (Right-Aligned) */}
      <section style={{ ...styles.section, ...styles.rightAligned }}>
        <div style={styles.textBlock}>
          <h2 style={styles.subtitle}>Our Mission</h2>
          <p style={styles.textt }>
            Our mission is to create a global platform where elite individuals meet, connect, and form lasting relationships. We blend the magic of mystery with the precision of AI profiling to provide a unique matchmaking service.
          </p>
        </div>
        <div style={styles.imageBlock}>
          {/* Place specific image inside the circle */}
          <div style={styles.imagePlaceholder}>
            <img
              src="https://example.com/mission.jpg"
              alt="Our Mission"
              style={styles.image}
            />
          </div>
        </div>
      </section>

      {/* What Makes Us Unique (Left-Aligned) */}
      <section style={{ ...styles.section, ...styles.leftAligned }}>
        <div style={styles.imageBlock}>
          <div style={styles.imagePlaceholder}>
            <img
              src="https://example.com/unique.jpg"
              alt="What Makes Us Unique"
              style={styles.image}
            />
          </div>
        </div>
        <div style={styles.textBlock}>
          <h2 style={styles.subtitle}>What Makes Us Unique</h2>
          <ul style={styles.list}>
            <li><strong>Exclusivity</strong>: We cater exclusively to high-caliber individuals.</li>
            <li><strong>Confidentiality</strong>: Your privacy is our priority.</li>
            <li><strong>Personalized Experience</strong>: AI matches individuals who complement your lifestyle.</li>
            <li><strong>Luxurious Events</strong>: Held in world-class venues like Dubai and New York.</li>
          </ul>
        </div>
      </section>

      {/* Core Values (Right-Aligned) */}
      <section style={{ ...styles.section, ...styles.rightAligned }}>
        <div style={styles.textBlock}>
          <h2 style={styles.subtitle}>Our Core Values</h2>
          <ul style={styles.list}>
            <li><strong>Integrity</strong>: We commit to the highest ethical standards.</li>
            <li><strong>Transparency</strong>: Open and honest communication with members.</li>
            <li><strong>Sincerity</strong>: Long-term relationships built on respect and understanding.</li>
          </ul>
        </div>
        <div style={styles.imageBlock}>
          <div style={styles.imagePlaceholder}>
            <img
              src="https://example.com/values.jpg"
              alt="Our Core Values"
              style={styles.image}
            />
          </div>
        </div>
      </section>

      {/* Membership Levels */}
      <section style={styles.section}>
        <h2 style={styles.subtitle}>Membership Levels</h2>
        <div style={styles.membershipContainer}>
          <div style={styles.membershipBox}>
            <h3>Presidential Membership</h3>
            <p>18 months of exclusive access to all events and luxurious perks.</p>
          </div>
          <div style={styles.membershipBox}>
            <h3>Platinum Membership</h3>
            <p>12 months of event access with tailored benefits to suit your needs.</p>
          </div>
          <div style={styles.membershipBox}>
            <h3>Discovery Membership</h3>
            <p>6 months of access to select events, ideal for exploring our services.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section style={styles.section}>
        <h2 style={styles.subtitle}>Join Us</h2>
        <p style={styles.text}>
          Join the Secret Proposal Capital Club to find a partner who shares your values. Start your journey in a secure, supportive environment today.
        </p>
        <button className='lab-btn' style={styles.button}>Contact Us</button>
      </section>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    padding: '20px',
    fontFamily: `'Poppins', sans-serif`,
    // backgroundColor: '#121212',
    color: '#e0e0e0',
    lineHeight: '1.7',
  },
  section: {
    padding: '40px 0',
    borderBottom: '1px solid #333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'column',
    // textAlign: 'center',
},

  rightAligned: {
    flexDirection: 'row-reverse',
  },
  leftAligned: {
    flexDirection: 'row',
  },
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FF5733',
    letterSpacing: '1px',
    // background: 'linear-gradient(90deg, #FF5733, #C70039, #900C3F)',
    background:'#f5db83',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  },
  subtitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#f5db83',
    marginBottom: '20px',
  },
  textBlock: {
    flex: '1 1 45%',
    padding: '20px',
  },
  imageBlock: {
    flex: '1 1 45%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePlaceholder: {
    width: '300px',
    height: '300px',
    backgroundColor: '#f5db83',
    borderRadius: '50%',
    boxShadow: '0 4px 10px rgba(255, 87, 51, 0.5)',
    overflow: 'hidden', // This makes sure the image stays inside the circle
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensures the image is centered and covers the entire circle
  },
  text: {
    fontSize: '1.2rem',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#e0e0e0',
    textAlign: 'center',
  },

  textt: {
    fontSize: '1.2rem',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#e0e0e0',
  },


  list: {
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '1.2rem',
    margin: '0',
    color: '#b3b3b3',
    lineHeight: '1.7',
  },
  membershipContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  membershipBox: {

    padding: '20px',
    margin: '20px',
    flexBasis: '30%',
    textAlign: 'center',
    borderRadius: '10px',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    webkitBackdropFilter: 'blur(10px)',
    border: '4px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',



  },
  button: {
    // backgroundColor: '#FF5733',
    // color: '#fff',
    // padding: '15px 30px',
    // fontSize: '1.2rem',
    // border: 'none',
    // borderRadius: '30px',
    // cursor: 'pointer',
    marginTop: '20px',
    width:'150px',
    // display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // transition: 'background-color 0.3s ease',
  },
};

export default AboutUs;
