//sec/src/pages/form.jsx
import React, { useReducer } from 'react';
import axios from 'axios';

const initialState = {
    currentStep: 0,
    progress: 0,
    formData: {},
    errors: {},
    isSubmitting: false,
};

function formReducer(state, action) {
    switch (action.type) {
        case 'SET_FORM_DATA':
            return {
                ...state,
                formData: { ...state.formData, [action.payload.field]: action.payload.value },
            };
        case 'SET_ERROR':
            return {
                ...state,
                errors: { ...state.errors, [action.payload.field]: action.payload.error },
            };
        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1,
                progress: ((state.currentStep + 1) / (formSteps.length - 1)) * 100,
                errors: {},
            };
        case 'PREVIOUS_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1,
                progress: ((state.currentStep - 1) / (formSteps.length - 1)) * 100,
                errors: {},
            };
        case 'SET_SUBMITTING':
            return {
                ...state,
                isSubmitting: action.payload,
            };
        default:
            return state;
    }
}

const formSteps = [
    // Personality Assessment
    { 
        id: 'weekend', 
        question: "How do you prefer to spend your weekends?", 
        type: 'select', 
        options: [
            { value: "A: Socializing with friends and family", label: "Socializing with friends and family" },
            { value: "B: Engaging in outdoor activities", label: "Engaging in outdoor activities" },
            { value: "C: Relaxing at home with a book or movie", label: "Relaxing at home with a book or movie" },
            { value: "D: Traveling to new destinations", label: "Traveling to new destinations" }
        ]
    },
    { 
        id: 'relationship_value', 
        question: "Which of the following values is most important to you in a relationship?", 
        type: 'select', 
        options: [
            { value: "A: Trust", label: "Trust" },
            { value: "B: Honesty", label: "Honesty" },
            { value: "C: Loyalty", label: "Loyalty" },
            { value: "D: Respect", label: "Respect" }
        ]
    },
    { 
        id: 'relationship_goal', 
        question: "What is your primary goal in joining SPCC?", 
        type: 'select', 
        options: [
            { value: "A: Finding a lifelong partner", label: "Finding a lifelong partner" },
            { value: "B: Expanding my social network", label: "Expanding my social network" },
            { value: "C: Attending exclusive events", label: "Attending exclusive events" },
            { value: "D: Exploring new opportunities", label: "Exploring new opportunities" }
        ]
    },
    { 
        id: 'social_interaction', 
        question: "How do you usually interact in social settings?", 
        type: 'select', 
        options: [
            { value: "A: I am the life of the party, always engaging with others", label: "I am the life of the party, always engaging with others" },
            { value: "B: I enjoy socializing but prefer smaller, intimate gatherings", label: "I enjoy socializing but prefer smaller, intimate gatherings" },
            { value: "C: I am more reserved and prefer to observe rather than participate", label: "I am more reserved and prefer to observe rather than participate" },
            { value: "D: I prefer one-on-one conversations", label: "I prefer one-on-one conversations" }
        ]
    },
    { 
        id: 'cultural_activities', 
        question: "Which cultural activities do you enjoy most?", 
        type: 'select', 
        options: [
            { value: "A: Attending art galleries and museums", label: "Attending art galleries and museums" },
            { value: "B: Enjoying live theater and concerts", label: "Enjoying live theater and concerts" },
            { value: "C: Participating in culinary experiences", label: "Participating in culinary experiences" },
            { value: "D: Engaging in historical tours and lectures", label: "Engaging in historical tours and lectures" }
        ]
    },
    { 
        id: 'communication_style', 
        question: "How do you prefer to communicate with your partner?", 
        type: 'select', 
        options: [
            { value: "A: Frequent and open communication, discussing everything", label: "Frequent and open communication, discussing everything" },
            { value: "B: Direct and to the point, addressing issues as they arise", label: "Direct and to the point, addressing issues as they arise" },
            { value: "C: Reserved, preferring to keep some things private", label: "Reserved, preferring to keep some things private" },
            { value: "D: Thoughtful and reflective, taking time to express feelings", label: "Thoughtful and reflective, taking time to express feelings" }
        ]
    },
    { 
        id: 'family_traditions', 
        question: "How important are family traditions in your life?", 
        type: 'select', 
        options: [
            { value: "A: Extremely important; I cherish and uphold them", label: "Extremely important; I cherish and uphold them" },
            { value: "B: Important, but I’m open to new traditions", label: "Important, but I’m open to new traditions" },
            { value: "C: Somewhat important, but flexible", label: "Somewhat important, but flexible" },
            { value: "D: Not very important; I prefer creating my own path", label: "Not very important; I prefer creating my own path" }
        ]
    },
    { 
        id: 'career_ambitions', 
        question: "How would you describe your career ambitions?", 
        type: 'select', 
        options: [
            { value: "A: Highly ambitious, constantly striving for growth", label: "Highly ambitious, constantly striving for growth" },
            { value: "B: Ambitious, but value work-life balance", label: "Ambitious, but value work-life balance" },
            { value: "C: Content with my current career path", label: "Content with my current career path" },
            { value: "D: Career is secondary to my personal life", label: "Career is secondary to my personal life" }
        ]
    },
    { 
        id: 'conflict_resolution', 
        question: "How do you handle disagreements in a relationship?", 
        type: 'select', 
        options: [
            { value: "A: Openly discuss and resolve them immediately", label: "Openly discuss and resolve them immediately" },
            { value: "B: Take time to cool off before addressing them", label: "Take time to cool off before addressing them" },
            { value: "C: Prefer to avoid conflict and let things settle naturally", label: "Prefer to avoid conflict and let things settle naturally" },
            { value: "D: Address only when absolutely necessary", label: "Address only when absolutely necessary" }
        ]
    },
    { 
        id: 'long_term_vision', 
        question: "Where do you see yourself in the next 10 years?", 
        type: 'select', 
        options: [
            { value: "A: Settled in a stable relationship with a family", label: "Settled in a stable relationship with a family" },
            { value: "B: Continuing to explore and achieve new personal and professional goals", label: "Continuing to explore and achieve new personal and professional goals" },
            { value: "C: Retired or semi-retired, enjoying the fruits of my labor", label: "Retired or semi-retired, enjoying the fruits of my labor" },
            { value: "D: Pursuing a passion or hobby full-time", label: "Pursuing a passion or hobby full-time" }
        ]
    },

     // Personal Information
     {
        id: 'personal_info',
        label: 'Personal Information',
        type: 'group',
        fields: [
            { id: 'full_name', label: 'Full Legal Name', type: 'text' },
            { id: 'preferred_name', label: 'Preferred Name', type: 'text' },
            { id: 'email', label: 'Email Address', type: 'email', required: true },
            { id: 'phone_number', label: 'Phone Number', type: 'tel', required: true },
            { id: 'dob', label: 'Date of Birth', type: 'date' },
            { id: 'gender', label: 'Gender Identity', type: 'select', options: ["Male", "Female", "Non_Binary", "Prefer not to say"] },
            { id: 'nationality', label: 'Nationality', type: 'text' },
            { id: 'hometown', label: 'Home Town', type: 'text' },
            { id: 'zip_code', label: 'Zip code', type: 'number' },
            { id: 'home_address', label: 'Home Address (Complete)', type: 'text' },
            { id: 'height', label: 'Height', type: 'number' },
            { id: 'weight', label: 'Weight', type: 'number' },
            { id: 'body_type', label: 'Body Type', type: 'select', options: ["Slim", "Athletic", "Average", "Curvy", "Prefer not to say"] },
            { id: 'eye_color', label: 'Eye Color', type: 'text' },
            { id: 'hair_color', label: 'Hair Color', type: 'text' },
            { id: 'fitness_level', label: 'Physical Fitness Level', type: 'select', options: ["Very Active", "Moderately Active", "Light Activity", "Prefer not to say"] },
            { id: 'marital_status', label: 'Marital Status', type: 'select', options: ["Single", "Divorced", "Widowed"] },
            { id: 'children', label: 'Do you have children?', type: 'select', options: ["Yes", "No", "Prefer not to say"] },
            { id: 'partner_children', label: 'Are you open to a partner with children?', type: 'select', options: ["Yes", "No", "Maybe"] },
            { id: 'family_relationship', label: 'Describe your relationship with your family', type: 'select', options: ["Close_knit", "Independent", "Estranged", "Prefer not to say"] },
            { id: 'education_level', label: 'Highest Level of Education', type: 'select', options: ["High School", "Bachelor’s Degree", "Master’s Degree", "Doctorate", "Other"] },
            { id: 'occupation', label: 'Current Occupation', type: 'text' },
            { id: 'industry', label: 'Industry', type: 'text' },
            { id: 'income_bracket', label: 'Income Bracket', type: 'select', options: ["Under $50k", "$50k_$100k", "$100k_$150k", "Over $150k"] },
            { id: 'career_ambitions', label: 'Career Ambitions', type: 'select', options: ["Ambitious", "Content with current position", "Flexible", "Prefer not to say"] },
            { id: 'smoke', label: 'Do you smoke?', type: 'select', options: ["Yes", "No", "Occasionally", "Prefer not to say"] },
            { id: 'drink', label: 'Do you drink alcohol?', type: 'select', options: ["Yes", "No", "Occasionally", "Prefer not to say"] },
            { id: 'dietary_restrictions', label: 'Dietary Restrictions or Preferences', type: 'text' },
            { id: 'hobbies', label: 'Hobbies and Interests', type: 'text' },
            { id: 'languages', label: 'Languages Spoken', type: 'text' },
            { id: 'pets', label: 'Do you have pets?', type: 'select', options: ["Yes", "No", "Prefer not to say"] },
            { id: 'pet_types', label: 'Types of Pets', type: 'text' },
            { id: 'interests', label: 'Interests and Activities', type: 'text' },
            { id: 'volunteering', label: 'Do you engage in any volunteering activities?', type: 'select', options: ["Yes", "No", "Occasionally"] },
            { id: 'volunteering_details', label: 'Details of Volunteering Activities', type: 'text' },
            { id: 'social_media', label: 'Social Media Profiles', type: 'text' },
            { id: 'online_dating', label: 'Have you tried online dating before?', type: 'select', options: ["Yes", "No"] },
            { id: 'dating_experience', label: 'Your Experience with Online Dating', type: 'text' }
        ]
    }
];

const validateField = (fieldId, value, fieldType) => {
    let error = '';

    if (!value || value.trim() === '') {
        return 'This field is required';
    }

    switch (fieldType) {
        case 'email':
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                error = 'Please enter a valid email address';
            }
            break;
        case 'tel':
            const phonePattern = /^[0-9]{10,15}$/;
            if (!phonePattern.test(value)) {
                error = 'Please enter a valid phone number';
            }
            break;
        case 'date':
            const datePattern = /^\d{4}-\d{2}-\d{2}$/;
            if (!datePattern.test(value)) {
                error = 'Please enter a valid date in YYYY-MM-DD format';
            }
            break;
        case 'number':
            if (isNaN(value) || value <= 0) {
                error = 'Please enter a valid number';
            }
            break;
        case 'select':
            if (value === '') {
                error = 'Please select an option';
            }
            break;
        default:
            break;
    }

    return error;
};

const PersonalityAssessmentForm = () => {
    const [state, dispatch] = useReducer(formReducer, initialState);
    const currentStepData = formSteps[state.currentStep];
    const isLastStep = state.currentStep === formSteps.length - 1;

    const handleNext = () => {
        let isValid = true;
    
        if (currentStepData.type === 'group') {
            currentStepData.fields.forEach((field) => {
                const error = validateField(field.id, state.formData[field.id], field.type);
                if (error) {
                    dispatch({ type: 'SET_ERROR', payload: { field: field.id, error } });
                    isValid = false;
                }
            });
        } else {
            const error = validateField(currentStepData.id, state.formData[currentStepData.id], currentStepData.type);
            if (error) {
                dispatch({ type: 'SET_ERROR', payload: { field: currentStepData.id, error } });
                isValid = false;
            }
        }
    
        if (isValid) {
            if (isLastStep) {
                handleSubmit();
            } else {
                dispatch({ type: 'NEXT_STEP' });
            }
        }
    };

    const handlePrevious = () => {
        dispatch({ type: 'PREVIOUS_STEP' });
    };

    const handleInputChange = (event, fieldId, fieldType) => {
        dispatch({
            type: 'SET_FORM_DATA',
            payload: { field: fieldId, value: event.target.value, label: event.target.label  },
        });

        const error = validateField(fieldId, event.target.value, event.target.label , fieldType);
        dispatch({ type: 'SET_ERROR', payload: { field: fieldId, error } });
    };



    const handleSubmit = async () => {
        dispatch({ type: 'SET_SUBMITTING', payload: true });
    
        try {
            const response = await axios.post('http://localhost:8001/submit-form', state.formData);
            alert(response.data.message); // Show success message
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form');
        } finally {
            dispatch({ type: 'SET_SUBMITTING', payload: false });
        }
    };



    return (
        <div className="container-resform-ford">
            <div className="title-resform-ford">
                <h2>Personality Assessment</h2>
            </div>
            <div className="progress-bar-resform-ford">
                <div className="progress-resform-ford" style={{ width: `${state.progress}%` }}>
                    {Math.round(state.progress)}%
                </div>
            </div>
            {currentStepData.type === 'group' ? (
                <div className="form-step-resform-ford active">
                    {currentStepData.fields.map((field) => (
                        <div key={field.id} className="form-field-resform-ford">
                            <label className="field-label-resform-ford">{field.label}</label>
                            {field.type === 'select' ? (
                                <select
                                    className="select-resform-ford"
                                    value={state.formData[field.id] || ''}
                                    onChange={(e) => handleInputChange(e, field.id, field.type)}
                                >
                                    <option value="">Select an option</option>
                                    {field.options.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    className="input-resform-ford"
                                    type={field.type}
                                    value={state.formData[field.id] || ''}
                                    onChange={(e) => handleInputChange(e, field.id, field.type)}
                                />
                            )}
                            {state.errors[field.id] && (
                                <div className="error-resform-ford">{state.errors[field.id]}</div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="form-step-resform-ford active">
                    <label className="field-label-resform-ford">{currentStepData.question}</label>
                    <select
                        className="select-resform-ford"
                        value={state.formData[currentStepData.id] || ''}
                        onChange={(e) => handleInputChange(e, currentStepData.id, currentStepData.type)}
                    >
                        <option value="">Select an option</option>
                        {currentStepData.options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {state.errors[currentStepData.id] && (
                        <div className="error-resform-ford">{state.errors[currentStepData.id]}</div>
                    )}
                </div>
            )}
            <div className="btn-container-resform-ford">
                <button
                    className="btn-resform-ford"
                    onClick={handlePrevious}
                    disabled={state.currentStep === 0}
                >
                    Previous
                </button>
                <button
                    className="btn-resform-ford"
                    onClick={isLastStep ? handleSubmit : handleNext}
                    disabled={state.isSubmitting}
                >
                    {isLastStep ? 'Submit' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default PersonalityAssessmentForm;

