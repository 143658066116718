import React from 'react';
import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import Events from '../component/section/events'; // Keeping the imported component name
import Work from '../component/section/work';
import FooterSection from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';

// Renaming the local Events component to EventPage
const EventPage = () => {
  return (
    <div>
      <Header2 />
      <Header />
      <PageHeader title={'Events'} />

      <div style={styles.container}>

{/* Upcoming Events */}
<section style={styles.section}>
  <h2 style={styles.sectionTitle}>Upcoming Events</h2>
  <div style={styles.eventsContainer}>
    {/* Dubai Extravaganza */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Dubai Extravaganza</h3>
      <p style={styles.eventDate}>Date: December 2024</p>
      <p style={styles.eventLocation}>Location: Burj Al Arab, Dubai, UAE</p>
      <ul style={styles.eventHighlights}>
        <li>Exclusive Masked Gala</li>
        <li>Luxury Networking</li>
        <li>Keynote Sessions</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Reserve Your Spot for Dubai</button>
      </div>
    </div>

    {/* New York Winter Wonderland */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>New York Winter Wonderland</h3>
      <p style={styles.eventDate}>Date: February 2025</p>
      <p style={styles.eventLocation}>Location: The Plaza Hotel, New York, USA</p>
      <ul style={styles.eventHighlights}>
        <li>Winter Ball</li>
        <li>Interactive Workshops</li>
        <li>Private Art Viewing</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Join Us in New York</button>
      </div>
    </div>

    {/* Maldives Island Retreat */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Maldives Island Retreat</h3>
      <p style={styles.eventDate}>Date: March 2025</p>
      <p style={styles.eventLocation}>Location: Velaa Private Island, Maldives</p>
      <ul style={styles.eventHighlights}>
        <li>Seaside Soirée</li>
        <li>Luxury Wellness Retreat</li>
        <li>Culinary Experience</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Escape to the Maldives</button>
      </div>
    </div>
  </div>
</section>

{/* Past Events */}
<section style={styles.section}>
  <h2 style={styles.sectionTitle}>Past Events</h2>
  <div style={styles.eventsContainer}>
    {/* Paris Elegance Summit */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Paris Elegance Summit</h3>
      <p style={styles.eventDate}>Date: September 2024</p>
      <p style={styles.eventLocation}>Location: Château de Chantilly, Paris, France</p>
      <p style={styles.eventRecap}>
        Romantic Evening and Cultural Immersion with a private tour of Château de Chantilly and a masked ball under the stars.
      </p>
    </div>

    {/* Tokyo Enchantment Gala */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Tokyo Enchantment Gala</h3>
      <p style={styles.eventDate}>Date: July 2024</p>
      <p style={styles.eventLocation}>Location: The Ritz-Carlton, Tokyo, Japan</p>
      <p style={styles.eventRecap}>
        An enchanting evening blending traditional Japanese performances with modern luxury and international networking.
      </p>
    </div>

    {/* London Royal Affair */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>London Royal Affair</h3>
      <p style={styles.eventDate}>Date: May 2024</p>
      <p style={styles.eventLocation}>Location: The Savoy Hotel, London, UK</p>
      <p style={styles.eventRecap}>
        A royal-themed evening with engaging panel discussions on finance, art, and luxury living, all in the grandeur of The Savoy.
      </p>
    </div>
  </div>
</section>

{/* Why Attend Section */}
<section style={styles.section}>
  <h2 style={styles.sectionTitle}>Why Attend Our Events?</h2>
  <div style={styles.whyAttendContainer}>
    <div style={styles.whyAttendItem}>
      <h4>Exclusive Access</h4>
      <p>Join our members in the world’s most luxurious locations, connecting with like-minded individuals.</p>
    </div>
    <div style={styles.whyAttendItem}>
      <h4>Unparalleled Privacy</h4>
      <p>Our events are private, invitation-only gatherings where your identity remains protected.</p>
    </div>
    <div style={styles.whyAttendItem}>
      <h4>Tailored Experiences</h4>
      <p>From wellness retreats to gourmet dining, every event is carefully curated to provide a premium experience.</p>
    </div>
    <div style={styles.whyAttendItem}>
      <h4>Professional Growth</h4>
      <p>Participate in workshops and discussions that offer insights into personal growth, networking, and luxury living.</p>
    </div>
  </div>
</section>

{/* Newsletter Section */}
<section style={styles.section}>
  <h2 style={styles.sectionTitle}>Stay Informed</h2>
  <p style={styles.text}>Sign up for our newsletter to receive the latest updates on upcoming events, special offers, and exclusive content.</p>
  <button style={styles.ctaButton} className='lab-btn'>Sign Up for Event Alerts</button>
</section>
</div>

      <FooterSection />
    </div>
  );
};



const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      color: '#ffffff',
      padding: '20px',
    },
    hero: {
      textAlign: 'center',
      padding: '50px 0',
      backgroundColor: '#1e1e1e',
    },
    heroTitle: {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
    heroText: {
      fontSize: '1.2rem',
      marginBottom: '20px',
    },
    section: {
      padding: '40px 0',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sectionTitle: {
      color: '#f5db83',
      fontSize: '2rem',
      marginBottom: '30px',
      textAlign: 'center',
    },
    eventsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    eventCard: {

      padding: '20px',
      borderRadius: '10px',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', 



      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
      backdropFilter: 'blur(10px)',
      webkitBackdropFilter: 'blur(10px)',
      border: '2px solid rgba(255, 255, 255, 0.18)',
      boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


    },
    eventTitle: {
      fontSize: '1.5rem',
      marginBottom: '10px',
      color: '#f5db83',
      height: '60px', // Fix height to make sure all headers are the same
      display: 'flex',
      alignItems: 'center', // Center text vertically
    },
    eventDate: {
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    eventLocation: {
      marginBottom: '10px',
      fontStyle: 'italic',
    },
    eventHighlights: {
      listStyleType: 'none',
      paddingLeft: '0',
      textAlign: 'left',
      marginBottom: '20px',
      flex: '1', // Ensures highlights take available space
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    ctaButton: {
      // backgroundColor: '#FF5733',
      // color: '#fff',
      // padding: '10px 20px',
      // border: 'none',
      borderRadius: '5px',
      // cursor: 'pointer',
      // transition: 'background-color 0.3s ease',
  
  
  
    },
    ctaButtonHover: {
      backgroundColor: '#C70039',
    },
    whyAttendContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    whyAttendItem: {
    //   backgroundColor: '#1e1e1e',
      padding: '20px',
      borderRadius: '10px',
      width: '250px',
      textAlign: 'center',


      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
      backdropFilter: 'blur(10px)',
      webkitBackdropFilter: 'blur(10px)',
      border: '2px solid rgba(255, 255, 255, 0.18)',
      boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


    },
    text: {
      textAlign: 'center',
      marginBottom: '20px',
    },
  };

export default EventPage; 