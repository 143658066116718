//sec\src\components\LineChart.jsx
import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import axios from 'axios';
import { tokens } from "../theme";

const LineChart = ({
  isCustomLineColors = false,
  isDashboard = false,
  showGrid = true,
  curveType = "catmullRom",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {
    primary: { 500: "#61cdbb" },
    grey: { 300: "#dddddd", 200: "#bbbbbb", 100: "#ffffff", 800: "#333333" },
  };

  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get('http://localhost:8001/api/monthlyTransactions');

        // Format data for Nivo line chart
        const formattedData = [
          {
            id: "Monthly Transactions",
            color: colors.primary[500],
            data: response.data
              .filter(monthData => monthData.total_amount !== null && monthData.month)
              .map(monthData => {
                const date = new Date(`${monthData.month}-01`);
                const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
                return { x: monthYear, y: monthData.total_amount };
              }),
          },
        ];

        if (formattedData[0].data.length === 0) {
          formattedData[0].data.push({ x: "No Data", y: 0 });
        }

        setTransactionData(formattedData);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
        setTransactionData([
          {
            id: "Monthly Transactions",
            color: colors.primary[500],
            data: [{ x: "No Data", y: 0 }],
          },
        ]);
      }
    };

    fetchTransactionData();
  }, []);

  if (transactionData.length === 0) {
    return <div>Loading chart...</div>;
  }

  const validCurveType = ["linear", "monotoneX", "catmullRom", "step"].includes(curveType)
    ? curveType
    : "linear";

  const lineColors = isCustomLineColors
    ? ["#F47560", "#61CDBB", "#E8C1A0", "#F1E15B"]
    : { scheme: "nivo" };

  const margins = isDashboard
    ? { top: 30, right: 50, bottom: 60, left: 50 }
    : { top: 50, right: 110, bottom: 70, left: 60 };

  return (
    <ResponsiveLine
      data={transactionData}
      theme={{
        axis: {
          domain: { line: { stroke: colors.grey[300] } },
          legend: { text: { fill: colors.grey[200], fontSize: 14, fontWeight: "bold" } },
          ticks: {
            line: { stroke: colors.grey[300], strokeWidth: 1 },
            text: { fill: colors.grey[200], fontSize: 14 },
          },
        },
        grid: {
          line: { stroke: colors.grey[800], strokeDasharray: "4 4" }
        },
        legends: { text: { fill: colors.grey[200] } },
        tooltip: {
          container: {
            background: colors.primary[700],
            color: colors.grey[100],
            fontSize: "12px",
            borderRadius: "4px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          },
        },
      }}
      colors={lineColors}
      margin={margins}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve={validCurveType}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Month & Year",
        legendOffset: 50,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5,
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Total Amount",
        legendOffset: -50,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={showGrid}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        isDashboard
          ? []
          : [
              {
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -40,
                itemsSpacing: 5,
                itemDirection: "left-to-right",
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  );
};

export default LineChart;
