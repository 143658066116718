//sec\src\scenes\API\api.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8001",
});


/*sabih changes*/
// Automatically attach JWT token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');  // Assuming JWT is stored in localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
/*sabih changes*/



export default api;





