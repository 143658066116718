// src/components/EventList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEvents, buyEventPass } from './eventapi/api';
import EventCard from './EventCard';
import Topbar from "./global/Topbar";
import Sidebar from "./global/Sidebar";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const [isSidebar, setIsSidebar] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await getEvents();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleBuyPass = async (eventId) => {
    try {
      await buyEventPass(eventId);
      alert('Pass purchased successfully!');
      navigate('/event-passes');
    } catch (error) {
      console.error('Error buying pass:', error);
      alert('You have already purchased this item, so you cannot purchase it again.');
    }
  };

  // CSS-in-JS styles
  const containerStyle = {
    padding: '20px',
    color: '#fff',
    backgroundColor: '#141b2d',
    minHeight: '100vh',
  };

  const headingStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#fff',
    fontSize: '2rem',
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // Set exactly 4 columns
    gap: '20px',
    padding: '10px',
  };

  const cardStyle = {
    backgroundColor: '#1F2A40',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    color: '#E0E0E0',
    textAlign: 'center',
  };

  const noEventsStyle = {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#666',
  };

  return (

    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

    <div style={containerStyle}>
      <h2 style={headingStyle}>Event List</h2>
      
      <div style={{
          ...gridStyle,
          // Responsive grid layout for different screen sizes
          '@media (max-width: 1200px)': {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
          '@media (max-width: 900px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          '@media (max-width: 600px)': {
            gridTemplateColumns: '1fr',
          },
        }}
      >
        {events.length > 0 ? (
          events.map((event) =>
            event ? (
              <div 
                key={event.event_id || event.id} 
                style={cardStyle}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                }}
              >
                <EventCard
                  event={event}
                  onBuyPass={handleBuyPass}
                />
              </div>
            ) : null
          )
        ) : (
          <p style={noEventsStyle}>No events found.</p>
        )}
      </div>
    </div>

    
    </main>
    </div>
  );
};

export default EventList;
