//sec\src\pages\memberform.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const formSteps = [
  // Personal Information
  {
    id: 'personal_information',
    label: 'Personal Information',
    fields: [
      { id: 'full_name', label: 'Full Legal Name', type: 'text', required: true },
      { id: 'preferred_name', label: 'Preferred Name', type: 'text', required: true },
      { id: 'email', label: 'Email Address', type: 'email', required: true },
      { id: 'phone_number', label: 'Phone Number', type: 'tel', required: true },
      { id: 'dob', label: 'Date of Birth', type: 'date', required: true },
      {
        id: 'gender_identity', // Changed from 'gender' to 'gender_identity'
        label: 'Gender Identity',
        type: 'radio',
        required: true,
        options: [
          'Male',
          'Female',
          'Non-binary',
          'Prefer not to say',
          { value: 'Other', hasTextInput: true },
        
        ],
      },
      {
        id: 'gender_identity_other', // Changed from 'gender' to 'gender_identity'
        label: 'Gender Identity Other',
        type: 'radio',
        options: [
          'Male',
          'Female',
          'Non-binary',
          'Prefer not to say',
          { value: 'Other', hasTextInput: true },
        ],
      },
      { id: 'nationality', label: 'Nationality', type: 'text' },
      { id: 'home_town', label: 'Home Town', type: 'text' },
      { id: 'zip_code', label: 'Zip Code', type: 'text' },
      { id: 'document_upload', label: 'Upload Document', type: 'file' },
    ],
  },
  // Home Address
  {
    id: 'home_address',
    label: 'Home Address',
    fields: [{ id: 'home_address', label: 'Home Address', type: 'text' }],
  },
  // Physical Details
  {
    id: 'physical_details',
    label: 'Physical Details',
    fields: [
      {
        id: 'height',
        label: 'Height',
        type: 'radio',
        options: ['Below 5\'0"', '5\'0" - 5\'5"', '5\'6" - 6\'0"', 'Above 6\'0"'],
      },
      { id: 'weight', label: 'Weight', type: 'text' },
      {
        id: 'body_type',
        label: 'Body Type',
        type: 'radio',
        options: ['Slim', 'Athletic', 'Average', 'Curvy', 'Plus size'],
      },
      {
        id: 'eye_color',
        label: 'Eye Color',
        type: 'radio',
        options: ['Brown', 'Blue', 'Green', 'Hazel', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'eye_color_other',
        label: 'Eye Color other',
        type: 'radio',
        options: ['Brown', 'Blue', 'Green', 'Hazel', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'hair_color',
        label: 'Hair Color',
        type: 'radio',
        options: ['Black', 'Brown', 'Blonde', 'Red', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'hair_color_other',
        label: 'Hair Color other',
        type: 'radio',
        options: ['Black', 'Brown', 'Blonde', 'Red', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'physical_fitness_level',
        label: 'Physical Fitness Level',
        type: 'radio',
        options: ['Not active', 'Moderately active', 'Highly active', 'Very fit'],
      },
    ],
  },
  // Family and Relationship Preferences
  {
    id: 'family_relationship',
    label: 'Family and Relationship Preferences',
    fields: [
      {
        id: 'marital_status',
        label: 'Marital Status',
        type: 'radio',
        options: ['Single', 'Divorced', 'Widowed', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'marital_status_other',
        label: 'Marital Status other',
        type: 'radio',
        options: ['Single', 'Divorced', 'Widowed', { value: 'Other', hasTextInput: true }],
      },
      {
        id: 'have_children',
        label: 'Do you have children?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      {
        id: 'partner_children_preference',
        label: 'Are you open to a partner with children?',
        type: 'radio',
        options: ['Yes', 'No', 'Maybe'],
      },
      {
        id: 'family_relationship_description',
        label: 'Family Relationship Description',
        type: 'text',
      },
    ],
  },
  // Education & Career
  {
    id: 'education_career',
    label: 'Education & Career',
    fields: [
      {
        id: 'education_level',
        label: 'Education Level',
        type: 'radio',
        options: [
          'High school',
          'Associate degree',
          'Bachelor’s degree',
          'Master’s degree',
          'Doctorate',
          { value: 'Other', hasTextInput: true },
        ],
      },
      {
        id: 'education_level_other',
        label: 'Education Level other',
        type: 'radio',
        options: [
          'High school',
          'Associate degree',
          'Bachelor’s degree',
          'Master’s degree',
          'Doctorate',
          { value: 'Other', hasTextInput: true },
        ],
      },
      { id: 'occupation', label: 'Occupation', type: 'text' },
      {
        id: 'industry',
        label: 'Industry',
        type: 'select',
        options: [
          'Technology',
          'Healthcare',
          'Finance',
          'Education',
          'Arts',
          'Other',
        ],
      },
      {
        id: 'income_bracket',
        label: 'Income Bracket',
        type: 'radio',
        options: [
          'Below $30,000',
          '$30,000 - $60,000',
          '$60,000 - $100,000',
          '$100,000 - $150,000',
          'Above $150,000',
        ],
      },
      { id: 'career_ambitions', label: 'Career Ambitions', type: 'text' },
    ],
  },
  // Lifestyle & Preferences
  {
    id: 'lifestyle_preferences',
    label: 'Lifestyle & Preferences',
    fields: [
      {
        id: 'do_you_smoke',
        label: 'Do you smoke?',
        type: 'radio',
        options: ['Yes', 'No', 'Occasionally'],
      },
      {
        id: 'do_you_drink_alcohol',
        label: 'Do you drink alcohol?',
        type: 'radio',
        options: ['Yes', 'No', 'Socially'],
      },
      {
        id: 'dietary_restrictions',
        label: 'Dietary Restrictions or Preferences',
        type: 'text',
      },
      { id: 'hobbies_interests', label: 'Hobbies and Interests', type: 'text' },
      { id: 'languages_spoken', label: 'Languages Spoken', type: 'text' },
      {
        id: 'pets',
        label: 'Pets',
        type: 'radio',
        options: [
          'No pets',
          { value: 'Yes', hasTextInput: true, textLabel: 'Type and number' },
        ],
      },
    ],
  },
  // Social & Online Presence
  {
    id: 'social_online_presence',
    label: 'Social & Online Presence',
    fields: [
      {
        id: 'volunteering',
        label: 'Do you engage in volunteering?',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      { id: 'social_media_profiles', label: 'Social Media Profiles', type: 'text' },
    ],
  },
  // Dating & Relationships
  {
    id: 'dating_relationships',
    label: 'Dating & Relationships',
    fields: [
      {
        id: 'online_dating_experience',
        label: 'Online Dating Experience',
        type: 'radio',
        options: ['First time', 'Some experience', 'Very experienced'],
      },
      {
        id: 'online_dating_reflection',
        label: 'What has been your reflection on online dating so far?',
        type: 'text',
      },
    ],
  },
  // Compatibility Questionnaire
  {
    id: 'compatibility_questionnaire',
    label: 'Compatibility Questionnaire',
    categories: [
      // 1. Personal Preferences & Qualities
      {
        id: 'personal_preferences',
        label: 'Personal Preferences & Qualities',
        fields: [
          {
            id: 'least_attractive_qualities',
            label: 'Least attractive qualities in a partner:',
            type: 'radio',
            options: [
              'Someone who lacks motivation or ambition.',
              'Someone who is disconnected from their emotions or other people’s emotions.',
              'Someone who sends mixed signals and is not dependable.',
              'Someone who takes life too seriously and doesn’t know how to relax.',
            ],
          },
        ],
      },
      // 2. Ideal Activities
      {
        id: 'ideal_activities',
        label: 'Ideal Activities',
        fields: [
          {
            id: 'weekend_activity_preference',
            label: 'Weekend activity preference:',
            type: 'radio',
            options: [
              'Getting together with family and/or friends for a night of laughter and fun.',
              'Date night with my partner cuddled up watching a movie.',
              'Doing something creative or working on a project around the house.',
              'Traveling or exploring.',
            ],
          },
        ],
      },
      // 3. Important Relationship Values
      {
        id: 'relationship_values',
        label: 'Important Relationship Values',
        fields: [
          {
            id: 'most_important_in_relationships',
            label: 'Most important in relationships:',
            type: 'radio',
            options: [
              'A sense of security and an intimate connection.',
              'Someone who will be by your side no matter what, that you can always depend on.',
              'Trust, stability, and consistency.',
              'Being able to let loose and always have fun together.',
            ],
          },
        ],
      },
      // 4. Self-description & Desires in a Partner
      {
        id: 'self_description',
        label: 'Self-description & Desires in a Partner',
        fields: [
          {
            id: 'self_description',
            label: 'Self-description:',
            type: 'radio',
            options: [
              'A hopeless romantic.',
              'Compassionate and empathetic.',
              'Successful and confident.',
              'Innovative and curious.',
            ],
          },
          {
            id: 'desired_qualities_partner',
            label: 'Desired qualities in a partner:',
            type: 'radio',
            options: [
              'Unconditional love.',
              'Someone with more of a sensitive side.',
              'Honesty and trust.',
              'Adventurous.',
            ],
          },
        ],
      },
      // 5. Relationship Challenges & Expectations
      {
        id: 'relationship_challenges',
        label: 'Relationship Challenges & Expectations',
        fields: [
          {
            id: 'most_frustrating_in_relationship',
            label: 'Most frustrating in a relationship:',
            type: 'radio',
            options: [
              'Sending mixed messages/words not matching actions.',
              'Someone who can’t connect on an emotional level.',
              'Someone who doesn’t like to take risks or have a sense of adventure.',
              'Someone who lacks motivation to accomplish their goals.',
            ],
          },
          {
            id: 'issues_in_past_relationships',
            label: 'Issues in past relationships:',
            type: 'radio',
            options: [
              'Intimacy.',
              'Lack of dependability and feeling supported.',
              'Trust and stability issues.',
              'Not being interested in the same things.',
            ],
          },
          {
            id: 'future_relationship_vision',
            label: 'Future relationship vision:',
            type: 'radio',
            options: [
              'Settling down with my soulmate as soon as possible.',
              'Taking things slow and waiting until I’m sure I’ve met the right one.',
              'In no rush to settle down, but I do picture myself getting married someday.',
              'Unsure if I will ever settle down.',
            ],
          },
          {
            id: 'would_like_children',
            label: 'Would you like to have children?',
            type: 'radio',
            options: [
              'Definitely, or already have children and want more.',
              'At some point, but not ready just yet.',
              'I am not sure yet.',
              'I don’t think I want children, or already have enough.',
            ],
          },
        ],
      },
      // 6. Physical Attraction & Love Language
      {
        id: 'physical_attraction',
        label: 'Physical Attraction & Love Language',
        fields: [
          {
            id: 'importance_physical_attraction',
            label: 'Importance of physical attraction:',
            type: 'radio',
            options: [
              'Very important.',
              'Somewhat important.',
              'Not very important.',
              'Not important at all.',
            ],
          },
          {
            id: 'love_language',
            label: 'Love language:',
            type: 'radio',
            options: [
              'Words of affirmation.',
              'Quality time/Acts of service.',
              'Physical touch.',
              'Receiving gifts.',
            ],
          },
        ],
      },
      // 7. Conflict Resolution & Relationship Dynamics
      {
        id: 'conflict_resolution',
        label: 'Conflict Resolution & Relationship Dynamics',
        fields: [
          {
            id: 'conflict_resolution_approach',
            label: 'Conflict resolution approach:',
            type: 'radio',
            options: [
              'Talk things out calmly and find a solution.',
              'Avoid conflicts and hope they go away.',
              'Get angry and confrontational.',
              'Shut down and withdraw.',
            ],
          },
          {
            id: 'perfect_date',
            label: 'Perfect date:',
            type: 'radio',
            options: [
              'Fancy dinner at a nice restaurant.',
              'A picnic in the park.',
              'A concert or live performance.',
              'A day spent exploring a new city or town.',
            ],
          },
          {
            id: 'religion_importance',
            label: 'Religion or spirituality in a relationship:',
            type: 'radio',
            options: [
              'Very important.',
              'Somewhat important.',
              'Not very important.',
              'Not important at all.',
            ],
          },
        ],
      },
      // 8. Dominance vs. Submission Test
      {
        id: 'dominance_submission',
        label: 'Dominance vs. Submission Test',
        fields: [
          {
            id: 'reaction_challenges_authority',
            label: 'Reaction to challenges to authority:',
            type: 'radio',
            options: [
              'Assert control firmly.',
              'Engage in discussion to understand both sides.',
              'Back down to avoid conflict.',
            ],
          },
          {
            id: 'learning_new_tasks',
            label: 'Learning new tasks:',
            type: 'radio',
            options: [
              'Take initiative and teach myself.',
              'Learn collaboratively by asking questions.',
              'Prefer someone to guide me through the process.',
            ],
          },
          {
            id: 'handling_pressure',
            label: 'Handling pressure:',
            type: 'radio',
            options: [
              'Take control and lead.',
              'Work collaboratively.',
              'Prefer others to lead.',
            ],
          },
          {
            id: 'deciding_activities_with_friends',
            label: 'Deciding activities with friends:',
            type: 'radio',
            options: [
              'Usually decide and get the group to follow.',
              'Suggest ideas but listen to others’ preferences.',
              'Wait for others to make suggestions and follow along.',
            ],
          },
          {
            id: 'handling_authority_figures',
            label: 'Handling authority figures:',
            type: 'radio',
            options: [
              'Challenge authority when necessary.',
              'Respect authority but discuss issues.',
              'Follow authority without questioning much.',
            ],
          },
          {
            id: 'expressing_desires_in_relationships',
            label: 'Expressing desires in relationships:',
            type: 'radio',
            options: [
              'Direct and assertive.',
              'Communicate openly, balancing both needs.',
              'Passive, letting my partner lead.',
            ],
          },
          {
            id: 'feeling_when_others_in_charge',
            label: 'Feeling in situations where others are in charge:',
            type: 'radio',
            options: [
              'Frustrated, prefer control.',
              'Comfortable as long as it’s fair.',
              'Relieved and prefer others to decide.',
            ],
          },
          {
            id: 'delegating_tasks',
            label: 'Delegating tasks:',
            type: 'radio',
            options: [
              'Prefer to handle tasks myself.',
              'Delegate when necessary, but stay involved.',
              'Prefer others to lead and follow instructions.',
            ],
          },
        ],
      },
      // 9. Advanced Relationship & Intimacy Questions
      {
        id: 'advanced_intimacy',
        label: 'Advanced Relationship & Intimacy Questions',
        fields: [
          {
            id: 'foreplay_preferences',
            label: 'Foreplay and sensual activities:',
            type: 'radio',
            options: [
              'Longer teasing/foreplay sessions.',
              'Sensual massages.',
              'Watching each other masturbate.',
              'Using mirrors while having sex.',
            ],
          },
          {
            id: 'exploring_fantasies',
            label: 'Exploring fantasies and rough play:',
            type: 'radio',
            options: [
              'Roleplaying, costumes.',
              'More vocal/dirty talk during sex.',
              'Being rougher or using restraints.',
              'Using toys like dildos, vibrators, and nipple clamps.',
            ],
          },
          {
            id: 'preferred_locations_for_sex',
            label: 'Preferred locations for sex:',
            type: 'radio',
            options: [
              'Car, park, or public spaces.',
              'Workplace or near outward-facing windows.',
              'Places where there is a risk of being caught.',
            ],
          },
          {
            id: 'involving_others_in_sex',
            label: 'Involving others in sex:',
            type: 'radio',
            options: [
              'Watching other couples.',
              'Threesomes, orgies.',
              'Watching your partner with someone else, or vice versa.',
            ],
          },
        ],
      },
      // 10. Couples Compatibility Test
      {
        id: 'couples_compatibility',
        label: 'Couples Compatibility Test',
        fields: [
          {
            id: 'shared_activities',
            label: 'Shared activities:',
            type: 'radio',
            options: [
              'Volunteering.',
              'Relaxing on the beach or at home.',
              'Reading a book together.',
            ],
          },
          {
            id: 'preferred_movie_genres',
            label: 'Preferred movie genres:',
            type: 'radio',
            options: ['Thriller', 'Action', 'Horror', 'Drama'],
          },
          {
            id: 'romantic_gestures',
            label: 'Romantic gestures:',
            type: 'radio',
            options: ['Buying gifts.', 'Emotional support.', 'Crafting handmade gifts.'],
          },
          {
            id: 'decision_making_for_dinner',
            label: 'Decision-making for dinner:',
            type: 'radio',
            options: ['Partner always decides.', 'Alternating or choosing at random.'],
          },
          {
            id: 'importance_of_religion',
            label: 'Importance of religion in a relationship:',
            type: 'radio',
            options: ['Very important.', 'Somewhat important.', 'Not very important.', 'Not important at all.'],
          },
          {
            id: 'most_important_aspect',
            label: 'Most important aspect of a successful relationship:',
            type: 'radio',
            options: ['Compromise.', 'Communication.', 'Trust.', 'Just being together.'],
          },
        ],
      },
      // Continue adding other categories as per your questionnaire...
      
    ],
  },
  {
    id: 'communication_emotional_intelligence',
    label: 'Communication and Emotional Intelligence',
    categories: [
      {
        id: 'communication_style',
        label: 'Communication Style',
        fields: [
          {
            id: 'preferred_communication_style',
            label: 'Preferred communication style in a relationship:',
            type: 'radio',
            options: [
              'Face-to-face, open discussions.',
              'Via text or phone calls.',
              'Through actions and non-verbal cues.',
              'I prefer to avoid talking about feelings and problems.',
            ],
          },
          {
            id: 'handling_misunderstandings',
            label: 'Handling misunderstandings with your partner:',
            type: 'radio',
            options: [
              'I immediately address and resolve them.',
              'I wait until both of us have calmed down before talking.',
              'I tend to bottle up my feelings and avoid conflict.',
              'I use humor or distraction to deflect the issue.',
            ],
          },
          {
            id: 'expressing_love_affection',
            label: 'Expressing love and affection:',
            type: 'radio',
            options: [
              'Through physical touch and intimacy.',
              'By doing small, thoughtful things for my partner.',
              'By spending quality time together.',
              'Through verbal affirmations.',
            ],
          },
          {
            id: 'preferred_support_when_upset',
            label: 'Preferred support when upset:',
            type: 'radio',
            options: [
              'I want them to give me space until I’m ready to talk.',
              'I prefer immediate comfort and reassurance.',
              'I like practical solutions and advice.',
              'I prefer emotional empathy, not solutions.',
            ],
          },
        ],
      },
      {
        id: 'emotional_intelligence_coping',
        label: 'Emotional Intelligence & Coping',
        fields: [
          {
            id: 'handling_stress_personal_life',
            label: 'Handling stress in personal life:',
            type: 'radio',
            options: [
              'I talk through it with my partner or a close friend.',
              'I need alone time to process my feelings.',
              'I engage in hobbies or activities to distract myself.',
              'I tend to shut down or withdraw until the stress passes.',
            ],
          },
          {
            id: 'comforting_someone_sad',
            label: 'Comforting someone who is sad or anxious:',
            type: 'radio',
            options: [
              'I listen and provide emotional support.',
              'I try to offer practical solutions.',
              'I give them space but let them know I\'m there for them.',
              'I struggle to comfort others; I find it difficult.',
            ],
          },
          {
            id: 'comfort_level_discussing_emotions',
            label: 'Comfort level discussing emotions:',
            type: 'radio',
            options: [
              'Very comfortable, I share openly.',
              'Somewhat comfortable, but I hold back sometimes.',
              'I’m not very comfortable; I struggle with vulnerability.',
              'I avoid discussing emotions.',
            ],
          },
          {
            id: 'dealing_difficult_situations',
            label: 'Dealing with difficult situations in a relationship:',
            type: 'radio',
            options: [
              'Address them head-on, no matter how uncomfortable.',
              'Avoid them and hope they resolve themselves.',
              'Compromise and work together to find a solution.',
              'I usually take a backseat and let my partner handle it.',
            ],
          },
        ],
      },
    ],
  },
  // 8. Future Goals and Long-term Compatibility
  {
    id: 'future_goals_compatibility',
    label: 'Future Goals and Long-term Compatibility',
    categories: [
      {
        id: 'life_goals_career',
        label: 'Life Goals & Career',
        fields: [
          {
            id: 'five_years_career',
            label: 'Where do you see yourself in the next 5 years, career-wise?',
            type: 'radio',
            options: [
              'In a stable, long-term position.',
              'Pursuing new opportunities and challenges.',
              'Still figuring out what I want to do.',
              'I’m more focused on personal growth than career.',
            ],
          },
          {
            id: 'attitude_work_life_balance',
            label: 'Attitude towards work-life balance:',
            type: 'radio',
            options: [
              'I believe in maintaining a strict balance between work and personal life.',
              'I’m career-focused, and work often takes priority.',
              'I prioritize my personal life over work.',
              'I adjust based on what’s needed at the time.',
            ],
          },
          {
            id: 'financial_stability_importance',
            label: 'Importance of financial stability in a relationship:',
            type: 'radio',
            options: [
              'Very important, it’s one of the foundations of a successful relationship.',
              'Somewhat important, as long as we’re both responsible.',
              'Not very important, I focus more on emotional connection.',
              'Not important at all, money doesn’t play a role in my relationships.',
            ],
          },
          {
            id: 'long_term_relationship_goals',
            label: 'Long-term relationship goals:',
            type: 'radio',
            options: [
              'Marriage and family.',
              'A committed relationship without necessarily getting married.',
              'Exploring things and seeing where they go.',
              'I’m not sure about long-term goals right now.',
            ],
          },
        ],
      },
      {
        id: 'lifestyle_family',
        label: 'Lifestyle and Family',
        fields: [
          {
            id: 'preferred_living_environment',
            label: 'Preferred future living environment:',
            type: 'radio',
            options: [
              'In a big city with lots of opportunities.',
              'In a quiet suburb, close to family.',
              'In a rural or countryside area.',
              'I’m flexible and could live anywhere.',
            ],
          },
          {
            id: 'preferred_family_structure',
            label: 'Preferred family structure:',
            type: 'radio',
            options: [
              'Traditional family with children.',
              'A partnership, without necessarily having children.',
              'I’m open to various structures, as long as we’re happy.',
              'I prefer not to have a family of my own.',
            ],
          },
          {
            id: 'sharing_responsibilities',
            label: 'Sharing responsibilities (household, finances, etc.):',
            type: 'radio',
            options: [
              'I believe everything should be shared equally.',
              'I prefer to take on certain roles while my partner takes on others.',
              'I think it depends on each person’s strengths and availability.',
              'I’m not too concerned about sharing responsibilities.',
            ],
          },
        ],
      },
    ],
  },
  // 9. Values and Beliefs
  {
    id: 'values_beliefs',
    label: 'Values and Beliefs',
    categories: [
      {
        id: 'personal_values',
        label: 'Personal Values',
        fields: [
          {
            id: 'most_important_values',
            label: 'Most important values in life:',
            type: 'radio',
            options: [
              'Family, love, and loyalty.',
              'Career, success, and independence.',
              'Creativity, self-expression, and freedom.',
              'Compassion, empathy, and kindness.',
            ],
          },
          {
            id: 'defining_success',
            label: 'Defining success in life:',
            type: 'radio',
            options: [
              'By achieving career goals and financial stability.',
              'By maintaining meaningful relationships.',
              'By personal happiness and fulfillment.',
              'By living authentically and in line with my values.',
            ],
          },
          {
            id: 'importance_personal_growth',
            label: 'Importance of personal growth and self-improvement:',
            type: 'radio',
            options: [
              'Very important, I’m always looking to grow.',
              'Somewhat important, I work on it when I have time.',
              'Not very important, I focus on living in the moment.',
              'I don’t prioritize self-improvement.',
            ],
          },
        ],
      },
      {
        id: 'social_political_views',
        label: 'Social and Political Views',
        fields: [
          {
            id: 'shared_political_views_importance',
            label: 'Importance of shared political views with a partner:',
            type: 'radio',
            options: [
              'Very important, it’s a key aspect of compatibility.',
              'Somewhat important, but I’m open to differences.',
              'Not very important, as long as we respect each other’s views.',
              'Not important at all, politics don’t affect my relationships.',
            ],
          },
          {
            id: 'comfort_pda',
            label: 'Comfort level with public displays of affection (PDA):',
            type: 'radio',
            options: [
              'I’m very comfortable with it, and I enjoy it.',
              'I like small gestures, but nothing too showy.',
              'I’m not a fan of PDA.',
              'It depends on the situation.',
            ],
          },
          {
            id: 'stance_on_gender_roles',
            label: 'Stance on gender roles in a relationship:',
            type: 'radio',
            options: [
              'I believe in traditional gender roles.',
              'I prefer an equal partnership where roles are fluid.',
              'I’m open to exploring different dynamics.',
              'Gender roles don’t matter to me.',
            ],
          },
          {
            id: 'views_on_social_activism',
            label: 'Views on social activism and community involvement:',
            type: 'radio',
            options: [
              'I’m passionate about it and actively participate.',
              'I support causes but don’t actively participate.',
              'I believe in focusing on personal responsibilities more than community involvement.',
              'I’m not interested in activism or community work.',
            ],
          },
        ],
      },
    ],
  },
  // 10. Intimacy and Sexual Compatibility
  {
    id: 'intimacy_sexual_compatibility',
    label: 'Intimacy and Sexual Compatibility',
    categories: [
      {
        id: 'sexual_preferences',
        label: 'Sexual Preferences',
        fields: [
          {
            id: 'importance_sexual_chemistry',
            label: 'Importance of sexual chemistry in a relationship:',
            type: 'radio',
            options: [
              'It’s one of the most important aspects.',
              'It’s important, but not the most essential part.',
              'It’s somewhat important, but emotional connection matters more.',
              'It’s not very important to me.',
            ],
          },
          {
            id: 'comfort_discussing_sexual_desires',
            label: 'Comfort level discussing sexual desires with a partner:',
            type: 'radio',
            options: [
              'Very comfortable, I openly discuss everything.',
              'Somewhat comfortable, but I hold back at times.',
              'I find it difficult to talk about sexual topics.',
              'I prefer not to discuss sexual matters directly.',
            ],
          },
          {
            id: 'preferred_frequency_of_sex',
            label: 'Preferred frequency of sex in a relationship:',
            type: 'radio',
            options: [
              'Daily or almost daily.',
              'A few times a week.',
              'Once a week or less.',
              'Frequency isn’t important, as long as the connection is there.',
            ],
          },
          {
            id: 'importance_variety_sex_life',
            label: 'Importance of variety in sex life:',
            type: 'radio',
            options: [
              'Very important, I enjoy exploring new things.',
              'Somewhat important, but I’m happy with a routine.',
              'Not very important, I prefer familiarity.',
              'I’m not interested in experimenting much.',
            ],
          },
        ],
      },
      {
        id: 'intimacy_outside_sex',
        label: 'Intimacy Outside of Sex',
        fields: [
          {
            id: 'importance_non_sexual_intimacy',
            label: 'Importance of non-sexual forms of intimacy (cuddling, holding hands, etc.):',
            type: 'radio',
            options: [
              'Very important, I need physical closeness.',
              'Somewhat important, but not a dealbreaker.',
              'Not very important, I’m more focused on emotional connection.',
              'I don’t prioritize physical intimacy outside of sex.',
            ],
          },
          {
            id: 'expression_of_affection',
            label: 'Expression of affection in public and private:',
            type: 'radio',
            options: [
              'I’m very affectionate in both public and private.',
              'I’m affectionate in private but more reserved in public.',
              'I’m more reserved in general, whether in public or private.',
              'I don’t show much physical affection.',
            ],
          },
        ],
      },
    ],
  },
  // 11. Personality & Emotional Compatibility
  {
    id: 'personality_emotional_compatibility',
    label: 'Personality & Emotional Compatibility',
    categories: [
      {
        id: 'emotional_responses',
        label: 'Emotional Responses',
        fields: [
          {
            id: 'reaction_to_criticism',
            label: 'Reaction to criticism:',
            type: 'radio',
            options: [
              'I become defensive and try to justify myself.',
              'I take the criticism into account and try to improve.',
              'I feel hurt and withdraw emotionally.',
              'I dismiss the criticism if I think it’s unfair.',
            ],
          },
          {
            id: 'preferred_coping_style',
            label: 'Preferred coping style for stress or feeling overwhelmed:',
            type: 'radio',
            options: [
              'I talk to my partner or someone close to me.',
              'I process emotions alone.',
              'I distract myself with hobbies or work until I feel better.',
              'I bottle things up until they pass.',
            ],
          },
          {
            id: 'handling_emotional_vulnerability',
            label: 'Handling emotional vulnerability in a relationship:',
            type: 'radio',
            options: [
              'I feel comfortable being vulnerable and open.',
              'I’m selective about what I share.',
              'I find it difficult to be emotionally vulnerable.',
              'I avoid vulnerability as it feels like weakness.',
            ],
          },
          {
            id: 'root_cause_conflicts',
            label: 'Belief on root cause of most conflicts in relationships:',
            type: 'radio',
            options: [
              'Miscommunication and misunderstanding.',
              'Lack of trust or respect.',
              'Differing goals or expectations.',
              'Emotional baggage from past experiences.',
            ],
          },
          {
            id: 'view_on_compromise',
            label: 'View on the role of compromise in a relationship:',
            type: 'radio',
            options: [
              'Essential, both partners should compromise equally.',
              'Some things require compromise; others should not.',
              'I’m usually willing to compromise more than my partner.',
              'I find it hard to compromise and prefer things my way.',
            ],
          },
          {
            id: 'emotional_vs_physical',
            label: 'More important in a relationship: emotional connection or physical intimacy?',
            type: 'radio',
            options: [
              'Emotional connection is the foundation.',
              'Both are equally important.',
              'Physical intimacy strengthens emotional connection.',
              'Physical intimacy is important, but emotional connection is more lasting.',
            ],
          },
        ],
      },
    ],
  },
  // 12. Conflict Resolution & Communication Style
  {
    id: 'conflict_resolution_communication',
    label: 'Conflict Resolution & Communication Style',
    categories: [
      {
        id: 'conflict_resolution_preferences',
        label: 'Conflict Resolution Preferences',
        fields: [
          {
            id: 'preferred_disagreement_resolution',
            label: 'Preferred approach for resolving disagreements:',
            type: 'radio',
            options: [
              'Address immediately and openly.',
              'Wait for both to calm down before discussing.',
              'Avoid conflict and hope it resolves on its own.',
              'Compromise quickly to avoid further conflict.',
            ],
          },
          {
            id: 'response_when_upset',
            label: 'Response when a partner upsets you:',
            type: 'radio',
            options: [
              'Calmly express feelings and explain why.',
              'Take time to cool down before addressing it.',
              'Hold onto frustration for a while before talking.',
              'Keep it to myself and try to let it go.',
            ],
          },
          {
            id: 'comfort_with_opposite_sex_friendships',
            label: 'Comfort level with a partner having close friendships with people of the opposite sex:',
            type: 'radio',
            options: [
              'Completely fine, as long as there’s trust.',
              'A bit uncomfortable but can handle it with clear boundaries.',
              'Jealous and feel it can cause issues.',
              'Not okay with it; prefer minimal opposite-sex friendships.',
            ],
          },
          {
            id: 'preferred_partner_response_when_upset',
            label: 'Preferred partner response when angry or upset:',
            type: 'radio',
            options: [
              'Listen and acknowledge my feelings.',
              'Give me space and time before talking.',
              'Give advice or help solve the problem.',
              'Offer comfort and reassurance.',
            ],
          },
        ],
      },
    ],
  },
    // 13. Values & Life Philosophy
    {
      id: 'values_life_philosophy',
      label: 'Values & Life Philosophy',
      categories: [
        {
          id: 'beliefs_and_happiness',
          label: 'Beliefs and Happiness',
          fields: [
            {
              id: 'belief_on_personality_change',
              label: 'Belief on fundamental personality change over time:',
              type: 'radio',
              options: [
                'Yes, with effort and self-awareness.',
                'Possible, but most people remain largely the same.',
                'Small habits can change, but core personality stays.',
                'Don’t believe people can change fundamentally.',
              ],
            },
            {
              id: 'definition_of_happiness',
              label: 'Definition of happiness:',
              type: 'radio',
              options: [
                'Achieving inner peace and contentment, regardless of circumstances.',
                'Building meaningful relationships with loved ones.',
                'Pursuing and accomplishing personal and career goals.',
                'Experiencing new adventures and living in the moment.',
              ],
            },
            {
              id: 'importance_of_partner_emotional_wellbeing',
              label: 'Importance of partner’s emotional well-being:',
              type: 'radio',
              options: [
                'A top priority; their happiness affects mine.',
                'Important, but I focus on my own emotional well-being too.',
                'I care, but expect them to handle their own emotions.',
                'Each person should be responsible for their emotional state.',
              ],
            },
            {
              id: 'belief_key_to_long_term_happiness',
              label: 'Belief in the key to long-term happiness in a relationship:',
              type: 'radio',
              options: [
                'Mutual respect and understanding.',
                'Emotional and physical intimacy.',
                'Trust and effective communication.',
                'Shared goals and a sense of partnership.',
              ],
            },
            {
              id: 'comfort_with_different_beliefs',
              label: 'Comfort level with partner having different religious or spiritual beliefs:',
              type: 'radio',
              options: [
                'Open and respectful as long as we understand each other.',
                'Might cause tension but could manage.',
                'Prefer a partner with similar beliefs.',
                'Would struggle in a relationship with significantly different beliefs.',
              ],
            },
          ],
        },
      ],
    },
    // 14. Emotional Needs & Attachment Style
    {
      id: 'emotional_needs_attachment',
      label: 'Emotional Needs & Attachment Style',
      categories: [
        {
          id: 'attachment_style',
          label: 'Attachment Style',
          fields: [
            {
              id: 'attachment_style_in_relationships',
              label: 'Attachment style in relationships:',
              type: 'radio',
              options: [
                'Secure and comfortable depending on each other.',
                'Crave closeness but sometimes feel insecure.',
                'Prefer emotional distance and independence.',
                'Anxious about abandonment; need reassurance.',
              ],
            },
            {
              id: 'feeling_most_appreciated',
              label: 'Feeling most appreciated in a relationship:',
              type: 'radio',
              options: [
                'Receiving words of affirmation and appreciation.',
                'Partner makes time and prioritizes the relationship.',
                'Acts of service like helping with tasks.',
                'Physical touch and closeness like hugs and kisses.',
              ],
            },
            {
              id: 'reaction_if_partner_needs_space',
              label: 'Reaction if partner needs more space than you:',
              type: 'radio',
              options: [
                'Respect their need for space.',
                'Feel anxious and worry about the relationship.',
                'Prefer to talk and understand their need.',
                'Feel rejected and emotionally distant.',
              ],
            },
          ],
        },
      ],
    },
    // 15. Decision-Making & Problem-Solving
    {
      id: 'decision_making_problem_solving',
      label: 'Decision-Making & Problem-Solving',
      categories: [
        {
          id: 'decision_making',
          label: 'Decision Making',
          fields: [
            {
              id: 'preferred_decision_making_style',
              label: 'Preferred decision-making style with partner:',
              type: 'radio',
              options: [
                'Discuss everything and reach mutual agreement.',
                'One usually takes the lead, depending on the decision.',
                'Avoid making big decisions until necessary.',
                'Separate decision-making for individual matters; joint decisions for shared matters.',
              ],
            },
            {
              id: 'handling_long_term_uncertainty',
              label: 'Handling long-term uncertainty (e.g., financial instability, job changes):',
              type: 'radio',
              options: [
                'Remain flexible and adjust as needed.',
                'Need a solid plan to feel secure.',
                'Struggle with uncertainty; prefer not to think about it.',
                'Rely on partner for reassurance when things feel unstable.',
              ],
            },
            {
              id: 'partner_perception_of_conflict',
              label: 'Partner’s perception of conflict in relationships:',
              type: 'radio',
              options: [
                'Necessary part of growth; helps us understand each other.',
                'Something to avoid as much as possible.',
                'Sign that something may be fundamentally wrong.',
                'Challenge we can overcome together with communication.',
              ],
            },
            {
              id: 'approach_to_major_life_decisions',
              label: 'Approach to major life decisions (moving, changing careers, etc.):',
              type: 'radio',
              options: [
                'Analyze pros and cons before deciding.',
                'Trust intuition and make decisions based on feelings.',
                'Rely heavily on advice from family, friends, or partner.',
                'Take risks and make quick decisions without much deliberation.',
              ],
            },
          ],
        },
      ],
    },
    // 16. Cognitive and Behavioral Patterns
    {
      id: 'cognitive_behavioral_patterns',
      label: 'Cognitive and Behavioral Patterns',
      categories: [
        {
          id: 'behavioral_patterns',
          label: 'Behavioral Patterns',
          fields: [
            {
              id: 'forgiveness_after_conflict',
              label: 'Forgiveness after a fight or disagreement:',
              type: 'radio',
              options: [
                'Forgive easily; don’t hold grudges.',
                'Need time to process before forgiving.',
                'Find it difficult to forgive; may hold onto resentment.',
                'Forgive but remember the issue long-term.',
              ],
            },
            {
              id: 'role_of_past_relationships',
              label: 'Role of past relationships in shaping current behavior:',
              type: 'radio',
              options: [
                'Past relationships heavily influence current behavior and dynamics.',
                'May have some influence, but people can grow and change.',
                'Past is irrelevant; present behavior matters most.',
                'Past relationships determine future relationship success or failure.',
              ],
            },
          ],
        },
      ],
    },
    // Exploring Sexual Orientation and Preferences
    {
      id: 'sexual_orientation_preferences',
      label: 'Exploring Sexual Orientation and Preferences',
      categories: [
        {
          id: 'sexual_orientation',
          label: 'Sexual Orientation',
          fields: [
            {
              id: 'sexual_orientation',
              label: 'Sexual orientation:',
              type: 'radio',
              options: [
                'Gay.',
                'Lesbian.',
                'Bisexual.',
                'Straight.',
                'Pansexual.',
                'Asexual.',
                'Still exploring or unsure.',
              ],
            },
            {
              id: 'experience_same_opposite_sex_relationships',
              label: 'Experience in same-sex or opposite-sex relationships:',
              type: 'radio',
              options: [
                'Yes, same-sex relationship.',
                'Yes, opposite-sex relationship.',
                'Yes, both same-sex and opposite-sex relationships.',
                'No, only opposite-sex relationships.',
                'No, open to both same-sex and opposite-sex relationships.',
              ],
            },
            {
              id: 'comfort_expressing_orientation_publicly',
              label: 'Comfort level expressing sexual orientation publicly (e.g., PDA):',
              type: 'radio',
              options: [
                'Very comfortable, regardless of partner’s gender.',
                'Somewhat comfortable, depending on situation and partner.',
                'Avoid PDA, regardless of orientation.',
                'Uncomfortable; usually avoid PDA.',
              ],
            },
            {
              id: 'support_from_family_friends',
              label: 'Support for sexual orientation from family and friends:',
              type: 'radio',
              options: [
                'Fully supported by family and friends.',
                'Some family/friends are supportive, but not all.',
                'Had to hide orientation from certain people.',
                'Faced challenges or rejection because of orientation.',
                'Family/friends don’t mind as long as in a straight relationship.',
              ],
            },
            {
              id: 'realization_of_attraction',
              label: 'Realization of attraction to same or opposite sex:',
              type: 'radio',
              options: [
                'Always known.',
                'Discovered in adolescence.',
                'Realized as an adult.',
                'Still exploring feelings and attractions.',
                'Only attracted to the opposite sex.',
              ],
            },
            {
              id: 'importance_partner_understanding_orientation',
              label: 'Importance of partner understanding and supporting your orientation:',
              type: 'radio',
              options: [
                'Crucial for them to fully understand and support it.',
                'Important, but not a dealbreaker if they don’t fully understand.',
                'Focused on mutual respect more than understanding.',
                'Don’t need deep understanding as long as we connect.',
                'Prefer a partner of the opposite sex who supports orientation.',
              ],
            },
            {
              id: 'challenges_same_opposite_sex_relationships',
              label: 'Challenges of being in same-sex vs. opposite-sex relationships:',
              type: 'radio',
              options: [
                'Yes, same-sex relationships come with social pressures.',
                'Yes, opposite-sex relationships come with traditional role expectations.',
                'Depends on environment and people around us.',
                'Believe all relationships face similar challenges.',
              ],
            },
            {
              id: 'experience_discrimination_orientation',
              label: 'Experience with discrimination or bias because of orientation:',
              type: 'radio',
              options: [
                'Yes, frequently.',
                'Yes, but only on rare occasions.',
                'No, mostly been accepted.',
                'Haven’t faced bias but avoid situations where it might happen.',
              ],
            },
          ],
        },
        {
          id: 'compatibility_relationship_dynamics',
          label: 'Compatibility & Relationship Dynamics',
          fields: [
            {
              id: 'preferred_roles_in_relationship',
              label: 'Preferred roles in ideal relationship: traditional gender roles vs. fluid roles:',
              type: 'radio',
              options: [
                'Prefer traditional gender roles.',
                'Roles are fluid; don’t believe in traditional expectations.',
                'Take on roles based on individual preferences.',
                'Open to exploring different dynamics.',
              ],
            },
            {
              id: 'importance_partner_being_open',
              label: 'Importance of partner being open about their orientation:',
              type: 'radio',
              options: [
                'Very important, need a partner open with identity.',
                'Somewhat important, but okay with privacy in certain situations.',
                'Respect choice to be out or not, as long as honest with each other.',
                'Not important, comfortable with partner keeping it private.',
                'Fine with partner as long as they’re straight and supportive.',
              ],
            },
            {
              id: 'approach_to_orientation_conversations',
              label: 'Approach to conversations about sexual orientation when meeting new people:',
              type: 'radio',
              options: [
                'Open and upfront about it.',
                'Wait until comfortable or trust is established.',
                'Cautious unless necessary.',
                'Prefer not to discuss unless asked directly.',
                'Open about opposite-sex attraction but don’t bring it up often.',
              ],
            },
            {
              id: 'anticipated_challenges_relationships',
              label: 'Anticipated challenges in same-sex or opposite-sex relationships:',
              type: 'radio',
              options: [
                'Social or family judgment about same-sex relationships.',
                'Navigating traditional gender roles in opposite-sex relationships.',
                'Family pressures or expectations for marriage/children.',
                'No unique challenges anticipated as long as there’s respect.',
              ],
            },
            {
              id: 'feelings_on_raising_children',
              label: 'Feelings on raising children in same-sex or opposite-sex relationships:',
              type: 'radio',
              options: [
                'Would love to raise children with a same-sex partner.',
                'Would love to raise children with an opposite-sex partner.',
                'Open to the idea, but not a priority.',
                'Unsure about raising children, regardless of relationship type.',
                'Don’t want children, but open to discussion with partner.',
              ],
            },
            {
              id: 'importance_supportive_community',
              label: 'Importance of building a supportive community in same-sex or opposite-sex relationships:',
              type: 'radio',
              options: [
                'Important to have a supportive LGBTQ+ or general community.',
                'Important but fine without it as long as partner is supportive.',
                'Don’t feel need for a specific community.',
                'Open to it but haven’t thought about it much.',
                'Supportive community is important, regardless of orientation.',
              ],
            },
            {
              id: 'impact_orientation_on_goals',
              label: 'Impact of sexual orientation on long-term relationship goals (marriage, children):',
              type: 'radio',
              options: [
                'Significant impact on goals.',
                'Some impact, but willing to work through challenges.',
                'Goals are the same regardless of orientation.',
                'Haven’t thought much about how orientation impacts goals.',
                'Prefer traditional relationship goals with an opposite-sex partner.',
              ],
            },
          ],
        },
        {
          id: 'social_emotional_aspects',
          label: 'Social and Emotional Aspects',
          fields: [
            {
              id: 'feelings_on_visibility',
              label: 'Feelings on LGBTQ+ visibility vs. opposite-sex visibility in society:',
              type: 'radio',
              options: [
                'Happy with progress, but believe more is needed for LGBTQ+ visibility.',
                'Feel both LGBTQ+ and straight relationships should be equally visible.',
                'Society is becoming more accepting of all relationships.',
                'Still feel there’s much work needed for acceptance.',
              ],
            },
            {
              id: 'comfort_dating_within_community',
              label: 'Comfort dating within orientation community (LGBTQ+ or straight) or open to outside community:',
              type: 'radio',
              options: [
                'Prefer dating within LGBTQ+ for shared understanding.',
                'Prefer dating within straight community for common interests.',
                'Open to relationships both within and outside community.',
                'Mostly dated within community but open to new experiences.',
                'No preference as long as there’s respect.',
              ],
            },
            {
              id: 'handling_incorrect_orientation_assumptions',
              label: 'Handling situations where others assume orientation incorrectly (same-sex or straight):',
              type: 'radio',
              options: [
                'Correct immediately and explain orientation.',
                'Correct if necessary, but don’t feel obligated.',
                'Let it slide unless relevant to the conversation.',
                'Feel uncomfortable but don’t usually address it.',
              ],
            },
            {
              id: 'thoughts_on_coming_out_professional',
              label: 'Thoughts on coming out in professional settings:',
              type: 'radio',
              options: [
                'Open but only if it’s a safe environment.',
                'Already out or open at work and comfortable with it.',
                'Keep professional and personal life separate.',
                'Avoid coming out unless necessary for well-being.',
              ],
            },
            {
              id: 'importance_of_attending_events',
              label: 'Importance of attending LGBTQ+ events or traditional relationship events with partner:',
              type: 'radio',
              options: [
                'Very important, see it as part of relationship and identity.',
                'Somewhat important, okay if partner isn’t interested.',
                'Enjoy attending, but don’t mind going solo or with friends.',
                'Don’t feel need to attend LGBTQ+ or traditional events regularly.',
                'Prefer traditional events like weddings or family gatherings.',
              ],
            },
          ],
        },
      ],
    },
  // Additional Closed Questions
  {
    id: 'additional_closed_questions',
    label: 'Additional Closed Questions',
    fields: [
      {
        id: 'actively_search_partner',
        label: 'How often do you actively search for a partner?',
        type: 'radio',
        options: ['Daily.', 'Weekly.', 'Occasionally.', 'Rarely.'],
      },
      {
        id: 'preferred_platform',
        label: 'Preferred platform for finding a partner:',
        type: 'radio',
        options: ['Online dating apps.', 'Matchmaking services.', 'Social media.', 'In-person events.'],
      },
      {
        id: 'satisfaction_current_method',
        label: 'Satisfaction with current method of partner search:',
        type: 'radio',
        options: ['Yes.', 'No.'],
      },
      {
        id: 'preference_private_setting',
        label: 'Preference for meeting potential partners in a private, curated setting:',
        type: 'radio',
        options: ['Yes.', 'No.'],
      },
      {
        id: 'experience_matchmaking',
        label: 'Experience with professional matchmaking services:',
        type: 'radio',
        options: ['Yes.', 'No.'],
      },
      {
        id: 'open_to_relocate',
        label: 'Open to relocating for the right partner:',
        type: 'radio',
        options: ['Yes.', 'No.'],
      },
    ],
  },
  // Additional Open Questions
  {
    id: 'additional_open_questions',
    label: 'Additional Open Questions',
    fields: [
      {
        id: 'ideal_matchmaking_service',
        label: 'Ideal matchmaking service:',
        type: 'text',
      },
      {
        id: 'experience_previous_dating_methods',
        label: 'Experience with previous dating methods:',
        type: 'text',
      },
      {
        id: 'expectations_successful_partnership',
        label: 'Expectations for a successful partnership:',
        type: 'text',
      },
      {
        id: 'definition_of_compatibility',
        label: 'Definition of compatibility in a partner:',
        type: 'text',
      },
      {
        id: 'most_important_values_long_term',
        label: 'Most important values long term in a partner:',
        type: 'text',
      },
      {
        id: 'most_important_values',
        label: 'Most important personal values in a long-term relationship:',
        type: 'text',
      },
    ],
  },
  // Additional Leading Questions
  {
    id: 'additional_leading_questions',
    label: 'Additional Leading Questions',
    fields: [
      {
        id: 'supportive_network_importance',
        label:
          'Do you believe a supportive network and exclusive events could improve chances of finding the right partner? Why?',
        type: 'text',
      },
      {
        id: 'diverse_backgrounds_impact',
        label:
          'How would meeting partners from diverse backgrounds in premium settings impact your search?',
        type: 'text',
      },
      {
        id: 'pre_vetted_partner_relief',
        label:
          'Wouldn’t it be a relief to know your partner has been pre-vetted for compatibility before meeting?',
        type: 'text',
      },
      {
        id: 'structured_meeting_help',
        label:
          'Would structured, reliable ways of meeting new people help reduce dating challenges?',
        type: 'text',
      },
      {
        id: 'importance_dedicated_matchmaking',
        label:
          'Importance of dedicated matchmaking service focused on serious partners meeting your criteria:',
        type: 'text',
      },
    ],
  },
];


const MemberForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const totalSteps = formSteps.length;

  const handleInputChange = (event, field) => {
    const { id, type } = field;
    const value = type === 'file' ? event.target.files[0] : event.target.value;

    setFormData({ ...formData, [id]: value });

    // Clear validation error on input change
    if (errors[id]) {
      setErrors({ ...errors, [id]: null });
    }
  };

  const handleTextInputChange = (event, fieldId) => {
    const value = event.target.value;

    setFormData({
      ...formData,
      [fieldId]: {
        ...formData[fieldId],
        text: value,
      },
    });

    // Clear validation error on input change
    if (errors[fieldId]) {
      setErrors({ ...errors, [fieldId]: null });
    }
  };

  const handleNext = () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      if (currentStep < totalSteps - 1) {
        setCurrentStep(currentStep + 1);
        window.scrollTo(0, 0);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);

      const dataToSubmit = new FormData();
      const userId = localStorage.getItem('user_id');

      if (!userId) {
        alert('User ID is missing! Please log in.');
        setIsSubmitting(false);
        return;
      }

      dataToSubmit.append('user_id', userId);

      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (value instanceof File) {
          dataToSubmit.append(key, value);
        } else if (typeof value === 'object' && value !== null) {
          dataToSubmit.append(key, value.value);
          if (value.text) {
            dataToSubmit.append(`${key}_other`, value.text);
          }
        } else {
          dataToSubmit.append(key, value);
        }
      });

      try {
        const response = await axios.post('http://localhost:8001/member-form', dataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        alert(response.data.message);

              // Update is_form_completed in local storage
      localStorage.setItem('is_form_completed', 'true');

      // Redirect to dashboard
      navigate('/dashboard');

      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateStep = () => {
    const stepErrors = {};
    const currentFormStep = formSteps[currentStep];
  
    const fieldsToValidate = currentFormStep.fields || [];
    if (currentFormStep.categories) {
      currentFormStep.categories.forEach((category) => {
        fieldsToValidate.push(...category.fields);
      });
    }
  
    fieldsToValidate.forEach((field) => {
      const value = formData[field.id];
      if (field.required && (!value || value === '')) {
        stepErrors[field.id] = 'This field is required';
      } else if (field.type === 'email' && value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          stepErrors[field.id] = 'Please enter a valid email address';
        }
      }
      // ...other validations...
    });
  
    return stepErrors;
  };

  const currentFormStep = formSteps[currentStep];
  const progressPercentage = ((currentStep + 1) / totalSteps) * 100;

  // Dark Theme CSS using const variables
  const containerStyle = {
    width: '100%',
    margin: '0 auto',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: '#1F2A40',
    color: '#e0e0e0',
    minHeight: '100vh',
  };

  const titleStyle = {
    padding: '30px 20px 10px',
    textAlign: 'center',
    backgroundColor: '#141b2d',
    borderBottom: '1px solid rgb(12 17 37)',
  };

  const formContainerStyle = {
    width: '90%',
    maxWidth: '800px',
    margin: '20px auto',
    backgroundColor: '#141b2d',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.5s ease-in-out',
  };

  const progressBarContainerStyle = {
    marginBottom: '30px',
  };

  const progressBarBackgroundStyle = {
    height: '8px',
    backgroundColor: '#0b1521',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const progressBarStyle = {
    width: `${progressPercentage}%`,
    height: '100%',
    backgroundColor: '#2a3a59',
    transition: 'width 0.3s',
  };

  const progressTextStyle = {
    textAlign: 'right',
    marginTop: '10px',
    fontSize: '14px',
    color: '#a1a1a1',
  };

  const formFieldStyle = {
    marginBottom: '20px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#e0e0e0',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #273249',
    backgroundColor: '#273249',
    fontSize: '16px',
    color: '#e0e0e0',
    outline: 'none',
    boxSizing: 'border-box',
  };

  const inputErrorStyle = {
    borderColor: '#cf6679',
  };

  const errorTextStyle = {
    color: '#cf6679',
    fontSize: '14px',
    marginTop: '5px',
  };

  const inputFocusStyle = {
    borderColor: '#c1c1c1',
  };

  const radioOptionStyle = {
    marginBottom: '10px',
  };

  const navigationButtonsContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  };

  const buttonStyle = {
    padding: '12px 24px',
    backgroundColor: 'rgb(39, 50, 73)',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: 'rgb(39, 50, 73)',
    fontSize: '16px',
    fontWeight: '600',
    outline: 'none',
  };

  const previousButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#273249',
    color: '#e0e0e0',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#ffffff',
  };

  const subHeadingStyle = {
    fontSize: '20px',
    marginBottom: '15px',
    color: '#e0e0e0',
  };

  const radioLabelStyle = {
    fontSize: '16px',
    color: '#e0e0e0',
  };

  const checkboxStyle = {
    marginRight: '8px',
  };

  const textAreaStyle = {
    ...inputStyle,
    height: '100px',
    resize: 'vertical',
  };

  const buttonHoverStyle = {
    backgroundColor: 'rgb(39, 50, 73)',
  };

  // Animation styles
  const [animationClass, setAnimationClass] = useState('fade-in');

  const animateStepChange = (direction) => {
    setAnimationClass(direction === 'next' ? 'slide-in-right' : 'slide-in-left');
    setTimeout(() => {
      setAnimationClass('');
    }, 500);
  };

  const handleAnimatedNext = () => {
    const validationErrors = validateStep();
    if (Object.keys(validationErrors).length === 0) {
      animateStepChange('next');
      handleNext();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleAnimatedPrevious = () => {
    animateStepChange('previous');
    handlePrevious();
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        <h2 style={{ margin: '0', fontSize: '28px', color: '#ffffff' }}>Member Assessment</h2>
      </div>
      <div style={formContainerStyle} className={animationClass} role="form" aria-labelledby="form-title">
        <div style={progressBarContainerStyle}>
          <div style={progressBarBackgroundStyle}>
            <div style={progressBarStyle}></div>
          </div>
          <p style={progressTextStyle}>
            Step {currentStep + 1} of {totalSteps}
          </p>
        </div>
        <div>
          <h3 style={headingStyle} id="form-title">
            {currentFormStep.label}
          </h3>
          {/* For compatibility questionnaire with categories */}
          {currentFormStep.categories ? (
            currentFormStep.categories.map((category) => (
              <div key={category.id} style={{ marginBottom: '30px' }}>
                <h4 style={subHeadingStyle}>{category.label}</h4>
                {category.fields.map((field) => (
                  <div key={field.id} style={formFieldStyle}>
                    <label style={labelStyle} htmlFor={field.id}>
                      {field.label}
                    </label>
                    {field.type === 'text' ? (
                      <>
                        <textarea
                          id={field.id}
                          value={formData[field.id] || ''}
                          onChange={(e) => handleInputChange(e, field)}
                          style={{
                            ...textAreaStyle,
                            ...(errors[field.id] ? inputErrorStyle : {}),
                          }}
                          aria-invalid={errors[field.id] ? 'true' : 'false'}
                        />
                        {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                      </>
                    ) : field.type === 'radio' ? (
                      field.options.map((option) => (
                        <div key={option.value || option} style={radioOptionStyle}>
                          <label style={radioLabelStyle}>
                            <input
                              type="radio"
                              name={field.id}
                              value={option.value || option}
                              checked={
                                formData[field.id] === (option.value || option) ||
                                (typeof formData[field.id] === 'object' &&
                                  formData[field.id].value === option.value)
                              }
                              onChange={(e) =>
                                handleInputChange(e, {
                                  ...field,
                                  hasTextInput: option.hasTextInput,
                                })
                              }
                              style={checkboxStyle}
                              aria-invalid={errors[field.id] ? 'true' : 'false'}
                            />
                            {option.value || option}
                          </label>
                          {option.hasTextInput &&
                            formData[field.id]?.value === option.value && (
                              <input
                                type="text"
                                placeholder={option.textLabel || 'Please specify'}
                                value={formData[field.id]?.text || ''}
                                onChange={(e) => handleTextInputChange(e, field.id)}
                                style={{
                                  ...inputStyle,
                                  marginTop: '10px',
                                  ...(errors[field.id] ? inputErrorStyle : {}),
                                }}
                                aria-invalid={errors[field.id] ? 'true' : 'false'}
                              />
                            )}
                        </div>
                      ))
                    ) : null}
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </div>
                ))}
              </div>
            ))
          ) : (
            currentFormStep.fields.map((field) => (
              <div key={field.id} style={formFieldStyle}>
                <label style={labelStyle} htmlFor={field.id}>
                  {field.label}
                  {field.required && ' *'}
                </label>
                {field.type === 'text' ? (
                  <>
                    <input
                      type="text"
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#2c2c2c')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'email' || field.type === 'tel' ? (
                  <>
                    <input
                      type={field.type}
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#2c2c2c')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'date' ? (
                  <>
                    <input
                      type="date"
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#2c2c2c')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'number' ? (
                  <>
                    <input
                      type="number"
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      onFocus={(e) => (e.target.style.borderColor = inputFocusStyle.borderColor)}
                      onBlur={(e) => (e.target.style.borderColor = '#2c2c2c')}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'file' ? (
                  <>
                    <input
                      type="file"
                      id={field.id}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        padding: '6px',
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    />
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'select' ? (
                  <>
                    <select
                      id={field.id}
                      value={formData[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field)}
                      required={field.required}
                      style={{
                        ...inputStyle,
                        ...(errors[field.id] ? inputErrorStyle : {}),
                      }}
                      aria-invalid={errors[field.id] ? 'true' : 'false'}
                    >
                      <option value="">Select an option</option>
                      {field.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
                  </>
                ) : field.type === 'radio' ? (
                  field.options.map((option) => (
                    <div key={option.value || option} style={radioOptionStyle}>
                      <label style={radioLabelStyle}>
                        <input
                          type="radio"
                          name={field.id}
                          value={option.value || option}
                          checked={
                            formData[field.id] === (option.value || option) ||
                            (typeof formData[field.id] === 'object' &&
                              formData[field.id].value === option.value)
                          }
                          onChange={(e) =>
                            handleInputChange(e, { ...field, hasTextInput: option.hasTextInput })
                          }
                          required={field.required}
                          style={checkboxStyle}
                          aria-invalid={errors[field.id] ? 'true' : 'false'}
                        />
                        {option.value || option}
                      </label>
                      {option.hasTextInput &&
                        formData[field.id]?.value === option.value && (
                          <input
                            type="text"
                            placeholder={option.textLabel || 'Please specify'}
                            value={formData[field.id]?.text || ''}
                            onChange={(e) => handleTextInputChange(e, field.id)}
                            style={{
                              ...inputStyle,
                              marginTop: '10px',
                              ...(errors[field.id] ? inputErrorStyle : {}),
                            }}
                            aria-invalid={errors[field.id] ? 'true' : 'false'}
                          />
                        )}
                    </div>
                  ))
                ) : null}
                {errors[field.id] && <p style={errorTextStyle}>{errors[field.id]}</p>}
              </div>
            ))
          )}
        </div>
        <div style={navigationButtonsContainerStyle}>
          {currentStep > 0 && (
            <button
              onClick={handleAnimatedPrevious}
              style={previousButtonStyle}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#5a5a5a')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#3e3e3e')}
            >
              Previous
            </button>
          )}
          {currentStep < totalSteps - 1 && (
            <button
              onClick={handleAnimatedNext}
              style={buttonStyle}
              onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgb(59 76 113)')}
            >
              Next
            </button>
          )}
          {currentStep === totalSteps - 1 && (
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{
                ...buttonStyle,
                ...(isSubmitting ? { opacity: 0.6, cursor: 'not-allowed' } : {}),
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'rgb(59 76 113)')}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberForm;