import React, { Component } from 'react';
import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';
import GoogleMap from '../component/section/googlemap';

// Import icons from '@mui/icons-material'
import { LocationOn, Phone, Email, Language, Send } from '@mui/icons-material';

const titel = "Don't Be A Stranger Just Say Hello.";
const desc = "We do fast phone repair. In most cases, we repair your device in just minutes.";
const btnText = "Send Our Message";
const infotitle = "Get Information";
const infodesc = "Our contact information details and follow us on social media";

let ContactInfoList = [
    {
        icon: <LocationOn />,
        infoTitle: 'Office Address',
        infoDesc: '1201 Park Street, Fifth Avenue',
    },
    {
        icon: <Phone />,
        infoTitle: 'Phone Number',
        infoDesc: '+22698 745 632, 02 982 745',
    },
    {
        icon: <Email />,
        infoTitle: 'Send Mail',
        infoDesc: 'info@yourmail.com',
    },
    {
        icon: <Language />,
        infoTitle: 'Our Website',
        infoDesc: 'www.yoursitename.com',
    },
];

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactNumber: '',
            contactMessage: '',
        };
    }

    addinpdata = async (e) => {
        e.preventDefault();
        const { contactName, contactEmail, contactSubject, contactNumber, contactMessage } = this.state;

        // Validation
        if (!contactName || !contactEmail || !contactSubject || !contactNumber || !contactMessage) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const response = await fetch('http://localhost:8001/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    contactName, contactEmail, contactSubject, contactNumber, contactMessage
                })
            });

            const data = await response.json();

            if (response.ok) {
                alert("Data added successfully");
                this.setState({
                    contactName: '',
                    contactEmail: '',
                    contactSubject: '',
                    contactNumber: '',
                    contactMessage: '',
                });
            } else {
                alert("Error: " + (data.error || "An unknown error occurred"));
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred. Please try again.");
        }
    };

    render() {
        return (
            <div>
                <Header2 />
                <Header />
                <PageHeader title={'Contact Us'} />
                <div className="contact-section">
                    <div className="contact-top padding-tb aside-bg padding-b">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form">
                                            <h4>{titel}</h4>
                                            <p className="mb-5">{desc}</p>
                                            <form onSubmit={this.addinpdata} id="commentform" className="comment-form">
                                                <input
                                                    type="text"
                                                    name="contactName"
                                                    id="item01"
                                                    value={this.state.contactName}
                                                    onChange={(e) => this.setState({ contactName: e.target.value })}
                                                    placeholder="Your Name *"
                                                />
                                                <input
                                                    type="email"
                                                    name="contactEmail"
                                                    id="item02"
                                                    value={this.state.contactEmail}
                                                    onChange={(e) => this.setState({ contactEmail: e.target.value })}
                                                    placeholder="Your Email *"
                                                />
                                                <input
                                                    type="text"
                                                    name="contactSubject"
                                                    id="item03"
                                                    value={this.state.contactSubject}
                                                    onChange={(e) => this.setState({ contactSubject: e.target.value })}
                                                    placeholder="Your Subject *"
                                                />
                                                <input
                                                    type="text"
                                                    name="contactNumber"
                                                    id="item04"
                                                    value={this.state.contactNumber}
                                                    onChange={(e) => this.setState({ contactNumber: e.target.value })}
                                                    placeholder="Mobile Number *"
                                                />
                                                <textarea
                                                    rows="8"
                                                    id="item05"
                                                    name="contactMessage"
                                                    value={this.state.contactMessage}
                                                    onChange={(e) => this.setState({ contactMessage: e.target.value })}
                                                    placeholder="Your Message *"
                                                ></textarea>
                                                <button type="submit" className="lab-btn">
                                                    <span>{btnText}</span>
                                                    <Send style={{ marginLeft: '8px' }} />
                                                </button>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-info-wrapper">
                                        <div className="contact-info-title">
                                            <h5>{infotitle}</h5>
                                            <p>{infodesc}</p>
                                        </div>
                                        <div className="contact-info-content">
                                            {ContactInfoList.map((val, i) => (
                                                <div className="contact-info-item" key={i}>
                                                    <div className="contact-info-inner" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="contact-info-thumb" style={{ fontSize: '36px', color: '#ffffff', marginRight: '15px' }}>
                                                            {val.icon}
                                                        </div>
                                                        <div className="contact-info-details">
                                                            <span className="fw-bold">{val.infoTitle}</span>
                                                            <p>{val.infoDesc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GoogleMap />
                </div>
                <FooterSection />
            </div>
        );
    }
}

export default ContactPage;
