import React, { useState, useContext, useCallback, useEffect } from "react";
import { Box, IconButton, Menu, MenuItem, Typography, Tooltip, Badge, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { formatNumber } from '../../utils/formatNumber';  // utility to format large numbers

const Topbar = ({ toggleSidebar, isSidebarVisible }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuType, setMenuType] = useState("");
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const [coins, setCoins] = useState(0);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const getAuthToken = () => localStorage.getItem('token');

  const handleAuthError = useCallback(
    (error) => {
      if (error.response && error.response.status === 401) {
        alert('Session expired. Please log in again.');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setError(error.response ? error.response.data.message : 'An error occurred. Please try again later.');
      }
    },
    [navigate]
  );

  const fetchWallet = useCallback(async () => {
    try {
      const token = getAuthToken();
      const userId = localStorage.getItem('user_id');
      if (!token || !userId) throw new Error('No token or userId found. Please log in.');

      const response = await axios.get(`http://localhost:8001/api/wallet/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCoins(response.data.coins);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      setError(error.message || 'An error occurred while fetching wallet balance.');
      handleAuthError(error);
    }
  }, [handleAuthError]);

  useEffect(() => {
    fetchWallet();
  }, [fetchWallet]);

  const handleLogout = async () => {
    try {
      await axios.post('http://localhost:8001/logout', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('userRole');

      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error.message);
      alert('Logout failed, please try again.');
    }
  };

  const handleMenuClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuType("");
    handleNestedMenuClose();
  };

  const handleNestedMenuClick = (event) => {
    setNestedAnchorEl((prevEl) => (prevEl ? null : event.currentTarget));
  };

  const handleNestedMenuClose = () => {
    setNestedAnchorEl(null);
  };

  const renderMenu = () => {
    const commonMenuProps = {
      anchorEl,
      open: Boolean(anchorEl),
      onClose: handleMenuClose,
      PaperProps: {
        style: {
          backgroundColor: colors.primary[400],
          color: colors.grey[100],
          minWidth: "230px",
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };

    const nestedMenuProps = {
      anchorEl: nestedAnchorEl,
      open: Boolean(nestedAnchorEl),
      onClose: handleNestedMenuClose,
      PaperProps: {
        style: {
          backgroundColor: colors.primary[400],
          color: colors.grey[100],
          minWidth: "180px",
        },
      },
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };

    switch (menuType) {
      case "notifications":
        return (
          <Menu {...commonMenuProps}>
            <MenuItem>Notification 1</MenuItem>
            <MenuItem>Notification 2</MenuItem>
            <MenuItem>Notification 3</MenuItem>
          </Menu>
        );
      case "settings":
        return (
          <>
            <Menu {...commonMenuProps}>
              <MenuItem onClick={handleNestedMenuClick}>
                System Settings
              </MenuItem>
            </Menu>

            <Menu {...nestedMenuProps}>
              <Box>
                <Typography variant="subtitle2" sx={{ ml: 2, mt: 1 }}>
                </Typography>
                <MenuItem component={Link} to="/platformconfiguration">Platform configuration</MenuItem>
                <Typography variant="subtitle2" sx={{ ml: 2, mt: 1 }}>
                </Typography>
                <MenuItem component={Link} to="/Backup_and_Recovery">Backup and recovery</MenuItem>
              </Box>
            </Menu>
          </>
        );
      case "profile":
        return (
          <Menu {...commonMenuProps}>
            <MenuItem component={Link} to="/profile-page">Profile</MenuItem>
            <MenuItem onClick={handleLogout} component={Link} to="/login">Logout</MenuItem>
          </Menu>
        );
      default:
        return null;
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2} position="relative">
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        width="50%"
        ml={isSidebarVisible ? "75px" : "20px"}
        transition="margin-left 0.3s ease"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex" alignItems="center">
        {/* Wallet Section */}
        <Tooltip title="Wallet Balance">
          <Box
            display="flex"
            alignItems="center"
            mr={2}
            p={1}
            borderRadius="8px"
            bgcolor={colors.primary[400]}
            boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
            sx={{
              '&:hover': {
                backgroundColor: colors.primary[500],
              },
              transition: 'background-color 0.3s ease',
            }}
          >
            <Badge
              badgeContent={null}  // you can add a condition-based badge here
              color="secondary"
              variant="dot"
              sx={{ mr: 1 }}
            >
              <AccountBalanceWalletOutlinedIcon />
            </Badge>
            <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold', color: colors.grey[100] }}>
              {formatNumber(coins)} 
            </Typography>
          </Box>
        </Tooltip>

        <IconButton onClick={(event) => handleMenuClick(event, "notifications")}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={(event) => handleMenuClick(event, "settings")}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={(event) => handleMenuClick(event, "profile")}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>

      {renderMenu()}
    </Box>
  );
};

export default Topbar;
