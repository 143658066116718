import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function FooterSection() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Adjust styles based on window width
  const responsiveStyles = {
    footerSection: {
      ...styles.footerSection,
      flexDirection: 'column',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
      padding: windowWidth <= 480 ? '30px 20px' : '50px 0',
    },
    footerCta: {
      ...styles.footerCta,
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
      alignItems: 'center',
    },
    footerWidget: {
      ...styles.footerWidget,
      width: windowWidth <= 768 ? '100%' : '30%',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
      marginBottom: windowWidth <= 768 ? '30px' : '50px',
    },
    footerMenu: {
      ...styles.footerMenu,
      flexDirection: windowWidth <= 480 ? 'column' : 'row',
      justifyContent: 'center',
    },
    footerMenuItem: {
      ...styles.footerMenuItem,
      marginLeft: windowWidth <= 480 ? '0' : '20px',
      marginBottom: windowWidth <= 480 ? '10px' : '0',
    },
    socialIcons: {
      ...styles.socialIcons,
      justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
    },
    footerLinksContainer: {
      ...styles.footerLinksContainer,
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
    },
    footerLinks: {
      ...styles.footerLinks,
      margin: windowWidth <= 768 ? '0 auto' : '0',
      alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
    },
  };

  return (
    <footer style={responsiveStyles.footerSection}>
      {/* Contact Info Section */}
      <div style={responsiveStyles.footerCta}>
        <div style={styles.singleCta}>
          <i className="fas fa-map-marker-alt" style={styles.ctaIcon}></i>
          <div style={styles.ctaText}>
            <h4 style={styles.ctaHeading}>Find us</h4>
            <span style={styles.ctaSpan}>5022 Baker Street, London 10013</span>
          </div>
        </div>
        <div style={styles.singleCta}>
          <i className="fas fa-phone" style={styles.ctaIcon}></i>
          <div style={styles.ctaText}>
            <h4 style={styles.ctaHeading}>Call us</h4>
            <li style={styles.ctaSpan}>
              <a style={styles.ctaLink} href="tel:+16238274857">
                +1 623-872-4857
              </a>
            </li>
          </div>
        </div>
        <div style={styles.singleCta}>
          <i className="far fa-envelope-open" style={styles.ctaIcon}></i>
          <div style={styles.ctaText}>
            <h4 style={styles.ctaHeading}>Mail us</h4>
            <li style={styles.ctaSpan}>
              <a style={styles.ctaLink} href="mailto:register@secretproposal.com">
                register@secretproposal.com
              </a>
            </li>
          </div>
        </div>
      </div>

      {/* Main Footer Content */}
      <div style={styles.footerContent}>
        {/* Logo and Description */}
        <div style={responsiveStyles.footerWidget}>
          <div style={styles.footerLogo}>
            <a href="/" style={styles.logoLink}>
              <img className='footerimage' src="" alt="logo" style={styles.logoImage} />
            </a>
          </div>
          <div style={styles.footerText}>
            <p>
              Welcome to SPCC, where exclusivity meets discretion. Our mission is to connect high-net-worth individuals with their perfect match through personalized and confidential matchmaking services.
            </p>
          </div>
          <div style={responsiveStyles.socialIcons}>
            <SocialIcon href="#" Icon={FacebookOutlinedIcon} />
            <SocialIcon href="#" Icon={InstagramIcon} />
            <SocialIcon href="#" Icon={LinkedInIcon} />
          </div>
        </div>

        {/* Useful Links */}
        <div style={responsiveStyles.footerWidget}>
          <div style={styles.footerWidgetHeading}>
            <h3 style={styles.widgetTitle}>Useful Links</h3>
          </div>
          <div style={responsiveStyles.footerLinksContainer}>
            <ul style={responsiveStyles.footerLinks}>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/">Home</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/aboutus">About</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/event">Events</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/services">Services</FooterLink>
              </li>
            </ul>
            <ul style={responsiveStyles.footerLinks}>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/pricing">Pricing Plans</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/">FAQs</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/contact">Contacts</FooterLink>
              </li>
              <li style={styles.footerLinkItem}>
                <FooterLink to="/">Privacy</FooterLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Subscribe Form */}
        <div style={responsiveStyles.footerWidget}>
          <div style={styles.footerWidgetHeading}>
            <h3 style={styles.widgetTitle}>Subscribe</h3>
          </div>
          <p style={styles.subscribeText}>
            Don’t miss to subscribe to our new feeds, kindly fill the form below.
          </p>
          <div style={styles.subscribeForm}>
            <form action="#">
              <input
                type="email"
                placeholder="Email Address"
                style={styles.subscribeInput}
              />
              <button style={styles.subscribeButton}>
                <i className="fas fa-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div style={styles.footerBottom}>
        <p style={styles.copyright}>
          &copy; {new Date().getFullYear()} All Rights Reserved | Designed by{' '}
          <a href="#" style={styles.designerLink}>
            Solutions Gram
          </a>
        </p>
        <ul style={responsiveStyles.footerMenu}>
          <li style={responsiveStyles.footerMenuItem}>
            <FooterLink to="/">Home</FooterLink>
          </li>
          <li style={responsiveStyles.footerMenuItem}>
            <FooterLink to="/">Terms</FooterLink>
          </li>
          <li style={responsiveStyles.footerMenuItem}>
            <FooterLink to="/">Privacy</FooterLink>
          </li>
          <li style={responsiveStyles.footerMenuItem}>
            <FooterLink to="/">Policy</FooterLink>
          </li>
          <li style={responsiveStyles.footerMenuItem}>
            <FooterLink to="/">Contact</FooterLink>
          </li>
        </ul>
      </div>
    </footer>
  );
}

// Custom component for footer links to handle hover effect
function FooterLink({ to, children }) {
  const [isHovered, setIsHovered] = useState(false);

  const linkStyle = {
    ...styles.footerLink,
    color: isHovered ? '#f5db83' : '#878787',
  };

  return (
    <NavLink
      to={to}
      style={linkStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </NavLink>
  );
}

// Custom component for social icons to handle hover effect
function SocialIcon({ href, Icon }) {
  const [isHovered, setIsHovered] = useState(false);

  const iconStyle = {
    ...styles.socialIcon,
    color: isHovered ? '#f5db83' : '#ffffff',
  };

  return (
    <a
      href={href}
      style={iconStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon style={{ fontSize: '24px' }} />
    </a>
  );
}

const styles = {
  footerSection: {
    backgroundColor: '#141b2d',
    padding: '50px 0',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerCta: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px 0',
    borderBottom: '2px solid rgb(36, 58, 85)',
    marginBottom: '30px',
    width: '100%',
  },
  singleCta: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    marginBottom: '20px',
    maxWidth: '300px',
  },
  ctaIcon: {
    color: '#f5db83',
    fontSize: '30px',
    marginRight: '15px',
  },
  ctaText: {
    color: '#ffffff',
  },
  ctaHeading: {
    margin: '0',
    fontSize: '1.2rem',
  },
  ctaSpan: {
    color: '#757575',
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  ctaLink: {
    color: '#757575',
    textDecoration: 'none',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px',
    padding: '0 20px',
  },
  footerWidget: {
    marginBottom: '50px',
  },
  footerWidgetHeading: {
    marginBottom: '20px',
  },
  widgetTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#f5db83',
    margin: '0',
  },
  footerLinksContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  footerLinks: {
    listStyleType: 'none',
    padding: '0',
    margin: '0 20px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  footerLinkItem: {
    marginBottom: '12px',
  },
  footerLink: {
    color: '#878787',
    textDecoration: 'none',
    transition: 'color 0.3s',
    fontSize: '16px',
  },
  footerLogo: {
    marginBottom: '20px',
  },
  logoLink: {
    display: 'inline-block',
  },
  logoImage: {
    width: '150px',
    height: 'auto',
  },
  footerText: {
    marginBottom: '20px',
    color: '#878787',
    fontSize: '16px',
    lineHeight: '1.6',
  },
  socialIcons: {
    display: 'flex',
    marginTop: '10px',
  },
  socialIcon: {
    color: '#ffffff',
    fontSize: '20px',
    marginRight: '15px',
    transition: 'color 0.3s',
    textDecoration: 'none',
  },
  subscribeText: {
    color: '#878787',
    fontSize: '16px',
    lineHeight: '1.6',
  },
  subscribeForm: {
    position: 'relative',
    marginTop: '20px',
  },
  subscribeInput: {
    width: '100%',
    padding: '14px 28px',
    backgroundColor: '#243a55',
    border: 'none',
    color: '#ffffff',
    fontSize: '16px',
  },
  subscribeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    backgroundColor: '#f5db83',
    padding: '13px 20px',
    border: 'none',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '16px',
  },
  footerBottom: {
    backgroundColor: '#243a55',
    padding: '25px 0',
    color: '#878787',
    textAlign: 'center',
    width: '100%',
    marginTop: '20px',
  },
  designerLink: {
    color: '#f5db83',
    textDecoration: 'none',
  },
  copyright: {
    margin: '0',
  },
  footerMenu: {
    display: 'flex',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '0',
    marginTop: '10px',
    flexWrap: 'wrap',
  },
  footerMenuItem: {
    marginLeft: '20px',
    fontSize: '14px',
  },
};

export default FooterSection;
