import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Contacts = () => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Responsiveness
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Custom theme
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1F2A40',
        paper: '#1F2A40',
      },
      primary: {
        main: '#1F2A40',
      },
      text: {
        primary: '#ffffff',
        secondary: '#b0b0b0',
      },
    },
  });

  // Fetch contacts from the backend
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('http://localhost:8001/contacts');
        setRows(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };
    fetchContacts();
  }, []);

  // Open view modal
  const handleView = (row) => {
    setSelectedRow(row);
    setIsViewModalOpen(true);
  };

  // Open edit modal
  const handleEdit = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  };

  // Save changes made to the contact
  const handleSave = async () => {
    try {
      await axios.put(
        `http://localhost:8001/contacts/${selectedRow.user_id}`,
        selectedRow
      );
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.user_id === selectedRow.user_id ? selectedRow : row
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  // Handle input changes
  const handleChange = (field, value) => {
    setSelectedRow((prev) => ({ ...prev, [field]: value }));
  };

  // Define the columns for the DataGrid
  const columns = useMemo(() => {
    // Base columns
    const baseColumns = [
      {
        field: 'user_id',
        headerName: 'ID',
        flex: 0.5,
        minWidth: 50,
      },
      {
        field: 'username',
        headerName: 'Username',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'role',
        headerName: 'Role',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'phone_number',
        headerName: 'Phone Number',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'zip_code',
        headerName: 'Zip Code',
        flex: 0.7,
        minWidth: 100,
      },
      {
        field: 'view',
        headerName: 'View',
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation(); // Prevents triggering the onCellClick
              handleView(params.row);
            }}
            size="small"
          >
            View
          </Button>
        ),
      },
      {
        field: 'edit',
        headerName: 'Edit',
        flex: 0.5,
        minWidth: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        ),
      },
    ];

    // Adjust columns for small screens
    if (isSmallScreen) {
      // Hide less important columns on small screens
      return baseColumns.map((column) => {
        if (
          ['email', 'phone_number', 'zip_code'].includes(column.field)
        ) {
          return { ...column, hide: true };
        }
        return column;
      });
    }

    return baseColumns;
  }, [isSmallScreen]);

  return (
    <ThemeProvider theme={theme}>
      <Box m={isSmallScreen ? '10px' : '20px'}>
        <Typography variant="h4" gutterBottom>
          Contacts
        </Typography>
        <Box
          height="70vh"
          sx={{
            overflowX: 'auto', // Enable horizontal scrolling
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.user_id}
            components={{ Toolbar: GridToolbar }}
            autoHeight={isSmallScreen}
            sx={{
              backgroundColor: '#1F2A40',
              color: '#fff',
              '& .MuiDataGrid-cell': {
                color: '#fff',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#333',
                color: '#fff',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#333',
                color: '#fff',
              },
            }}
          />
        </Box>

        {/* View Modal */}
        <Dialog
          open={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { backgroundColor: '#1F2A40', color: '#fff' },
          }}
        >
          <DialogTitle>Contact Details</DialogTitle>
          <DialogContent dividers>
            {selectedRow && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  {selectedRow.username}
                </Typography>
                <Box>
                  <Typography>
                    <strong>ID:</strong> {selectedRow.user_id}
                  </Typography>
                  <Typography>
                    <strong>Role:</strong> {selectedRow.role}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {selectedRow.email}
                  </Typography>
                  <Typography>
                    <strong>Phone Number:</strong> {selectedRow.phone_number}
                  </Typography>
                  <Typography>
                    <strong>Zip Code:</strong> {selectedRow.zip_code}
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsViewModalOpen(false)}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Modal */}
        <Dialog
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { backgroundColor: '#1F2A40', color: '#fff' },
          }}
        >
          <DialogTitle>Edit Contact</DialogTitle>
          <DialogContent dividers>
            {selectedRow && (
              <Box component="form">
                {/* Username */}
                <TextField
                  margin="dense"
                  label="Username"
                  fullWidth
                  value={selectedRow.username || ''}
                  onChange={(e) => handleChange('username', e.target.value)}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                {/* Email */}
                <TextField
                  margin="dense"
                  label="Email"
                  fullWidth
                  value={selectedRow.email || ''}
                  onChange={(e) => handleChange('email', e.target.value)}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                {/* Phone Number */}
                <TextField
                  margin="dense"
                  label="Phone Number"
                  fullWidth
                  value={selectedRow.phone_number || ''}
                  onChange={(e) => handleChange('phone_number', e.target.value)}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                {/* Zip Code */}
                <TextField
                  margin="dense"
                  label="Zip Code"
                  fullWidth
                  value={selectedRow.zip_code || ''}
                  onChange={(e) => handleChange('zip_code', e.target.value)}
                  InputLabelProps={{ style: { color: '#fff' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsEditModalOpen(false)}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Contacts;
