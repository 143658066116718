import { Box, useTheme } from "@mui/material";
// import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Header from '../../component/layout/header';
import Header2 from '../../component/layout/header2';
import PageHeader from '../../component/layout/pageheader';
import FooterSection from '../../component/layout/footer';

import { tokens } from "../../theme";


import { useState } from "react";



const FAQ = () => {


  const theme = useTheme();
  return (

    <div>
               <Header2/>
                <Header />
                <PageHeader title={'FAQS'} />
<div style={styles.main}>

       

    <Box  m="20px">
      {/* <Header title="FAQ" />  */}
      <h3 style={styles.faq}>FAQS</h3>
      <Typography style={styles.sub}>Frequently Asked Questions Page</Typography>
      

      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What is the Secret Proposal Capital Club?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The Secret Proposal Capital Club is an exclusive 
matchmaking service designed for high-net-worth individuals and professionals. Our club combines the 
allure of mystery with the precision of AI profiling to create meaningful connections in a secure and 
discreet environment.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How does the membership process work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          To become a member, simply sign up through our website. 
After completing a brief questionnaire, you'll receive information about our membership options. Once 
you select a membership package, we will begin building your profile and matching you with potential 
partners.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What are the benefits of joining the Secret Proposal Capital Club?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Members enjoy access to 
luxurious events held in exclusive locations worldwide, personalized matchmaking using advanced AI 
technology, and a guarantee of complete privacy and confidentiality. Members also receive benefits such 
as guest passes, discounts, and the opportunity to participate in referral programs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How are matches made?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Our advanced AI technology profiles each member based on their interests, 
values, and preferences. Matches are made based on compatibility, ensuring that you are connected 
with individuals who share your lifestyle and relationship goals.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What is the significance of the masks at events?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Masks are a key element of our events, adding an 
element of mystery and allowing members to express themselves freely. They also help ensure that 
initial connections are based on personality rather than appearance. At the end of the event, members 
can choose to reveal their identity.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          Where are the events held?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Our events take place in some of the world's most luxurious locations, 
including Dubai, New York, the Maldives, and more. Each event is meticulously planned to provide an 
unforgettable experience in a beautiful setting.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How is my privacy protected?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Your privacy is our top priority. We guarantee that your identity will 
remain confidential until you choose to reveal it. All personal information is securely stored and never 
shared with third parties.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What are the different membership options?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We offer three levels of membership: <br />
• Presidential Membership: 18 months, full access to events, and exclusive benefits. <br />
• Platinum Membership: 12 months, access to events with additional perks. <br />
• Discovery Membership: 6 months, access to selected events. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          Can I bring a guest to the events?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes, members are allowed to bring guests to our events. Each 
membership package includes a certain number of guest passes, and additional passes can be purchased 
if needed.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How do I contact Secret Proposal Capital Club for more information?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can contact us directly 
through our website using the "Contact Us" form, or reach out via email or phone. Our team is available 
to answer any questions and assist you with your membership.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What is the process for verifying members?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          All members undergo a thorough verification process, 
including identity checks, to ensure that they meet our standards. This process is designed to maintain 
the integrity and exclusivity of our club.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How do I sign up for events?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Once you become a member, you will receive notifications about 
          upcoming events. You can sign up for events directly through our member portal
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          What is the refund policy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Due to the personalized nature of our services, SPCC does not offer refunds once membership has been 
confirmed and services have commenced. We are committed to providing the best possible service and 
support throughout your membership
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          Can I pause my membership if I need to take a break?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes, SPCC allows members to pause their membership for a limited period. Please contact our support 
team to discuss your situation, and we will work with you to accommodate your needs.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={styles.box} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={styles.color} variant="h5">
          How do I cancel my membership?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          If you wish to cancel your membership, please contact SPCC support. We will guide you through the 
          cancellation process and discuss any remaining obligations or considerations.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>


  
    </div>

    <FooterSection />
    </div>
  );
};

const styles = {


  main:{
    
    paddingBottom:'40px',

  },


  color: {

    color:'smoke',
    fontWeight:'bold',
  },

  faq: {

    color:'#f5db83',
    display:'flex',
    justifyContent: 'center',
  },

  sub: {
    
    fontSize:'18px',
    marginTop:'-10px',
marginBottom:'20px',
display:'flex',
justifyContent: 'center',

  },


  box: {
    
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    webkitBackdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',

  },

}

export default FAQ;
