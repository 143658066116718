// src/pages/login.jsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Header2 from '../component/layout/header2';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';

const LoginPage = () => {
  const [userName, setUserName] = useState('');
  const [userPass, setUserPass] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Clear any user data in localStorage for a fresh login
  const clearUserData = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('userRole');
    localStorage.removeItem('is_form_completed');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Send login request
      const response = await axios.post(
        'http://localhost:8001/login',
        { email: userName, password: userPass.trim() },
        { headers: { 'Content-Type': 'application/json' } }
      );
  
      const { user, token } = response.data;
      console.log('Login response user:', user); // Debugging line
  
      if (user && token) {
        // Store user info and token in localStorage
        localStorage.setItem('user_id', user.id);
        localStorage.setItem('userRole', user.role);
        localStorage.setItem('token', token);
        localStorage.setItem('is_form_completed', user.is_form_completed);
  
        // Display welcome message
        alert(`Login successful! Welcome, ${user.username}`);
  
        // Redirect based on role and form completion
        if (user.role === 'member' || user.role === 'partner') {
          if (user.is_form_completed === true || user.is_form_completed === '1') {
            navigate('/dashboard');
          } else {
            if (user.role === 'member') {
              navigate('/member-form');
            } else if (user.role === 'partner') {
              navigate('/partner-form');
            }
          }
        } else {
          // For other roles, redirect to dashboard
          navigate('/dashboard');
        }
      } else {
        throw new Error('Invalid login response format');
      }
    } catch (error) {
      console.error('Login error:', error);
  
      // Clear any partial user data in case of error
      clearUserData();
  
      // Handle specific error codes for user feedback
      if (error.response && error.response.status === 401) {
        alert('Invalid email or password. Please try again.');
      } else {
        alert('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <Header2 />
      <PageHeader title={'Log In'} />
      <div className="login-section padding-tb">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">Login</h3>
            <form className="account-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="item01"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="User Name *"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  id="item02"
                  value={userPass}
                  onChange={(e) => setUserPass(e.target.value)}
                  placeholder="Password *"
                />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <a href="#">Forget Password?</a>
                </div>
              </div>
              <div className="form-group">
                <button className="d-block lab-btn" type="submit">
                  <span>{loading ? 'Logging in...' : 'Submit Now'}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/signup">Sign Up</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default LoginPage;
