import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import PurchaseCoins from '../PurchaseCoins';
import TransactionHistory from '../TransactionHistory';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../utils/formatNumber';

const WalletDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [coins, setCoins] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getAuthToken = () => localStorage.getItem('token');

    const handleAuthError = useCallback(
        (error) => {
            if (error.response && error.response.status === 401) {
                alert('Session expired. Please log in again.');
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                setError(error.response ? error.response.data.message : 'An error occurred. Please try again later.');
            }
        },
        [navigate]
    );

    const fetchWallet = useCallback(async () => {
        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('user_id');
            if (!token || !userId) throw new Error('No token or userId found. Please log in.');

            const response = await axios.get(`http://localhost:8001/api/wallet/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCoins(response.data.coins);
        } catch (error) {
            console.error('Error fetching wallet balance:', error);
            setError(error.message || 'An error occurred while fetching wallet balance.');
        }
    }, []);

    const fetchTransactions = useCallback(async () => {
        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('user_id');
            if (!token || !userId) throw new Error('No token or userId found. Please log in.');
    
            const response = await axios.get(`http://localhost:8001/api/transactions/${userId}/transactions`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTransactions(response.data.transactions);
        } catch (error) {
            handleAuthError(error);
            console.error('Error fetching transactions:', error);
            setError(error.response?.data?.message || 'Failed to fetch transactions.');
        } finally {
            setLoading(false);
        }
    }, [handleAuthError]);

    useEffect(() => {
        fetchWallet();
        fetchTransactions();
    }, [fetchWallet, fetchTransactions]);

    const dashboardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '2rem',
        fontFamily: 'Arial, sans-serif',
    };

    const headerStyle = {
        fontSize: '2rem',
        color: '#e0e0e0',
        marginBottom: '0.5rem',
    };

    const balanceStyle = {
        fontSize: '1.5rem',
        color: '#ccc',
        marginBottom: '2rem',
    };

    const sectionHeaderStyle = {
        fontSize: '1.8rem',
        color: '#e0e0e0',
        margin: '2rem 0 1rem',
    };

    const errorContainerStyle = {
        textAlign: 'center',
        color: '#f44336',
        backgroundColor: '#2a2a2a',
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        maxWidth: '600px',
        margin: '2rem auto',
    };

    const errorTitleStyle = {
        fontSize: '1.5rem',
        marginBottom: '0.5rem',
    };

    const buttonStyle = {
        padding: '0.8rem 1.2rem',
        fontSize: '1rem',
        color: '#fff',
        backgroundColor: '#007bff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    if (loading) return <Loader />;

    if (error) {
        return (
            <div style={errorContainerStyle}>
                <h2 style={errorTitleStyle}>Error</h2>
                <p>{error}</p>
                <button style={buttonStyle} onClick={() => window.location.reload()}>Try Again</button>
            </div>
        );
    }

    return (
        <div style={dashboardContainerStyle}>
            <h1 style={headerStyle}>Your Wallet</h1>
            <p style={balanceStyle}>Current Balance: {formatNumber(coins)} Coins</p>
            
            <h1 style={headerStyle}>Buy Coins</h1>
            <PurchaseCoins onCoinPurchase={setCoins} />

            <h2 style={sectionHeaderStyle}>Transaction History</h2>
            <TransactionHistory transactions={transactions} />
        </div>
    );
};

export default WalletDashboard;
