// import React, { Component } from "react";
// import { NavLink, Link } from "react-router-dom";

// const contactnumber = "+800-123-4567 6587";
// const location = "Beverley, New York 224 USA";

// class Header extends Component {
//   menuTrigger() {
//     document.querySelector(".menu").classList.toggle("active");
//     document.querySelector(".header-bar").classList.toggle("active");
//   }
//   menuTriggerTwo() {
//     document.querySelector(".header-top").classList.toggle("open");
//     // document.querySelector('.header-bar').classList.toggle('active')
//   }
//   // menuTriggerThree() {
//   //     document.querySelector('.menu-item-has-children').classList.toggle('open')
//   //     document.querySelector('.header-bar').classList.remove('active')
//   // }

//   render() {
//     window.addEventListener("scroll", function () {
//       var value = window.scrollY;
//       if (value > 200) {
//         document
//           .querySelector(".header-section")
//           .classList.add(["header-fixed"], ["fadeInUp"]);
//       } else {
//         document
//           .querySelector(".header-section")
//           .classList.remove(["header-fixed"], ["fadeInUp"]);
//       }
//     });

 

//     return (



//       <div className="headerr">
  

//       <ul className="menuu">
//         <li>
//           <NavLink to="/"><div className="icon1 inn"></div></NavLink>
//         </li>
//         <li>
//           <NavLink to="/aboutus"><div className="icon5 inn"></div></NavLink>
//         </li>
//         <li>
//           <NavLink to="/event"><div className="icon2 inn"></div></NavLink>
//         </li>
//         <li>
//           <NavLink to="/pricing"><div className="icon3 inn"></div></NavLink>
//         </li>
//         <li>
//           <NavLink to="/contact"><div className="icon4 inn"></div></NavLink>
//         </li>
//         <li>
//           <NavLink to="/dashboard"><div className="icon4 inn"></div></NavLink>
//         </li>
        
//       </ul>

//     </div>
    


//     );
//   }
// }

// export default Header;



import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";


function header() {
  return (
    <div className="headerr">
  

    <ul className="menuu">
      <li>
        <NavLink to="/"><div className="icon1 inn"></div></NavLink>
      </li>
      <li>
        <NavLink to="/aboutus"><div className="icon5 inn"></div></NavLink>
      </li>
      <li>
        <NavLink to="/event"><div className="icon2 inn"></div></NavLink>
      </li>
      <li>
        <NavLink to="/pricing"><div className="icon3 inn"></div></NavLink>
      </li>
      <li>
        <NavLink to="/contact"><div className="icon4 inn"></div></NavLink>
      </li>
      
    </ul>

  </div>
  )
}

export default header;
