import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MemberFormManagement from "../crude/Component/MemberFormManagement";
import PartnerFormManagement from "../crude/Component/PartnerFormManagement";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";

const UserInformation = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
const [isSidebar, setIsSidebar] = useState(true);
  // Custom theme
  const theme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#1F2A40",
        paper: "#1F2A40",
      },
      primary: {
        main: "#1F2A40",
      },
      text: {
        primary: "#ffffff",
        secondary: "#b0b0b0",
      },
    },
  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (

    <div className="app">
    <Sidebar isSidebar={isSidebar} />
    <main className="content">
      <Topbar setIsSidebar={setIsSidebar} />


    <ThemeProvider theme={theme}>
      <Box m={isSmallScreen ? "10px" : "20px"}>
        <Typography variant="h4" gutterBottom>
          User Information
        </Typography>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab label="Member Forms" />
          <Tab label="Partner Forms" />
        </Tabs>
        {tabIndex === 0 && <MemberFormManagement />}
        {tabIndex === 1 && <PartnerFormManagement />}
      </Box>
    </ThemeProvider>

    </main>
    </div>
  );
};

export default UserInformation;
