import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Slider() {
  const scrollerRef = useRef(null);

  useEffect(() => {
    const scroller = scrollerRef.current;
    const scrollerInner = scroller.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);

    // Duplicate content once for seamless scroll
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem);
    });
  }, []);

  const handleScroll = (direction) => {
    const scroller = scrollerRef.current;
    if (direction === "left") {
      scroller.scrollLeft -= 200; // Scroll left by 200px
    } else {
      scroller.scrollLeft += 200; // Scroll right by 200px
    }
  };

  return (
    <div className="slider-container">
      <div className="slider-header">
        <h1 className="slider-title">Our Affiliated Partners</h1>
        <p className="slider-description">
          Discover the prestigious brands that partner with us to elevate your experience.
        </p>
      </div>

      <div className="slider">
        <button
          className="scroll-btn scroll-btn-left"
          onClick={() => handleScroll("left")}
          aria-label="Scroll Left"
        >
          &#8249;
        </button>

        <div className="scroller" ref={scrollerRef}>
          <div className="scroller__inner">
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=1" alt="Partner 1" />
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=2" alt="Partner 2" />
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=3" alt="Partner 3" />
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=4" alt="Partner 4" />
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=5" alt="Partner 5" />
            <img className="partner-logo" src="https://i.pravatar.cc/150?img=6" alt="Partner 6" />
          </div>
        </div>

        <button
          className="scroll-btn scroll-btn-right"
          onClick={() => handleScroll("right")}
          aria-label="Scroll Right"
        >
          &#8250;
        </button>
      </div>

      <Link to="/" className="explore-btn">
        <span>Explore Our Partners</span>
      </Link>
    </div>
  );
}

export default Slider;
