import React from 'react';



// Renaming the local Events component to EventPage
const EventPage = () => {
  return (
    <div>


      <div style={styles.container}>

{/* Upcoming Events */}
<section style={styles.section}>
  <h2 style={styles.sectionTitle}>Upcoming Events</h2>
  <div style={styles.eventsContainer}>
    {/* Dubai Extravaganza */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Dubai Extravaganza</h3>
      <p style={styles.eventDate}>Date: December 2024</p>
      <p style={styles.eventLocation}>Location: Burj Al Arab, Dubai, UAE</p>
      <ul style={styles.eventHighlights}>
        <li>Exclusive Masked Gala</li>
        <li>Luxury Networking</li>
        <li>Keynote Sessions</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Reserve Your Spot for Dubai</button>
      </div>
    </div>

    {/* New York Winter Wonderland */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>New York Winter Wonderland</h3>
      <p style={styles.eventDate}>Date: February 2025</p>
      <p style={styles.eventLocation}>Location: The Plaza Hotel, New York, USA</p>
      <ul style={styles.eventHighlights}>
        <li>Winter Ball</li>
        <li>Interactive Workshops</li>
        <li>Private Art Viewing</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Join Us in New York</button>
      </div>
    </div>

    {/* Maldives Island Retreat */}
    <div style={styles.eventCard}>
      <h3 style={styles.eventTitle}>Maldives Island Retreat</h3>
      <p style={styles.eventDate}>Date: March 2025</p>
      <p style={styles.eventLocation}>Location: Velaa Private Island, Maldives</p>
      <ul style={styles.eventHighlights}>
        <li>Seaside Soirée</li>
        <li>Luxury Wellness Retreat</li>
        <li>Culinary Experience</li>
      </ul>
      <div style={styles.buttonContainer}>
        <button style={styles.ctaButton} className='lab-btn'>Escape to the Maldives</button>
      </div>
    </div>
  </div>
</section>

</div>

<div style={styles.bt}>
     <button style={styles.all} className='lab-btn'>View All Services</button>
     </div>

    </div>
  );
};



const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      color: '#ffffff',
      padding: '20px',
    },
    hero: {
      textAlign: 'center',
      padding: '50px 0',
      backgroundColor: '#1e1e1e',
    },
    heroTitle: {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
    heroText: {
      fontSize: '1.2rem',
      marginBottom: '20px',
    },
    section: {
      padding: '40px 0',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sectionTitle: {
      color: '#f5db83',
      fontSize: '2rem',
      marginBottom: '30px',
      textAlign: 'center',
    },
    eventsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    eventCard: {

      padding: '20px',
      borderRadius: '10px',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', 



      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
      backdropFilter: 'blur(10px)',
      webkitBackdropFilter: 'blur(10px)',
      border: '2px solid rgba(255, 255, 255, 0.18)',
      boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


    },
    eventTitle: {
      fontSize: '1.5rem',
      marginBottom: '10px',
      color: '#f5db83',
      height: '60px', // Fix height to make sure all headers are the same
      display: 'flex',
      alignItems: 'center', // Center text vertically
    },
    eventDate: {
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    eventLocation: {
      marginBottom: '10px',
      fontStyle: 'italic',
    },
    eventHighlights: {
      listStyleType: 'none',
      paddingLeft: '0',
      textAlign: 'left',
      marginBottom: '20px',
      flex: '1', // Ensures highlights take available space
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    ctaButton: {
      // backgroundColor: '#FF5733',
      // color: '#fff',
      // padding: '10px 20px',
      // border: 'none',
      borderRadius: '5px',
      // cursor: 'pointer',
      // transition: 'background-color 0.3s ease',
  
  
  
    },
    ctaButtonHover: {
      backgroundColor: '#C70039',
    },
    whyAttendContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    whyAttendItem: {
    //   backgroundColor: '#1e1e1e',
      padding: '20px',
      borderRadius: '10px',
      width: '250px',
      textAlign: 'center',


      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
      backdropFilter: 'blur(10px)',
      webkitBackdropFilter: 'blur(10px)',
      border: '2px solid rgba(255, 255, 255, 0.18)',
      boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


    },
    text: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    all: {

      width: '200px', 
      display: 'flex',
      justifyContent: 'center',
      borderRadius:'10px',
    
    },
    bt: {
  
      
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '40px',
      paddingBottom:'40px',
  
    
    },   
  };

export default EventPage; 