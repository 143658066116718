import React, { Component } from 'react';



const Service = () => {
  return (

    <div>

<div style={styles.main}>
  <h2 style={styles.ww}>Services</h2>
  <p style={styles.ww}>At Secret Proposal Capital Club, we believe in the power of genuine connections.<br /> Our mission is to 
     offer a secure, discreet, and highly personalized matchmaking experience for high-net-worth individuals.</p>
</div>

    <div style={styles.container}>
      {/* Service Box 1 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Personalized Matchmaking</h3>
        <div style={styles.content}>
          <p style={styles.text}>
          At Secret Proposal Capital Club, we offer customized matchmaking services for high-net-worth 
          individuals. Our AI technology carefully matches you with people who share your values, interests, and life goals.
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Start Your Matchmaking Journey</button>
        </div>
      </div>

      {/* Service Box 2 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Exclusive Events</h3>
        <div style={styles.content}>
          <p style={styles.text}>
            Our events are the cornerstone of the Secret Proposal Capital Club experience. Held in luxurious and exotic 
            locations worldwide, these events create the perfect setting for romance to blossom.
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Explore Upcoming Events</button>
        </div>
      </div>

      {/* Service Box 3 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Privacy and Confidentiality</h3>
        <div style={styles.content}>
          <p style={styles.text}>
            Your privacy is our utmost priority. We guarantee that your personal information is securely stored and 
            never shared without your consent.
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Discover Our Privacy Policy</button>
        </div>
      </div>

      {/* Service Box 4 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Membership Packages</h3>
        <div style={styles.content}>
          <p style={styles.text}>
            We offer three levels of membership to cater to your specific needs: Presidential, Platinum, and Discovery 
            Memberships, each with unique benefits and perks.
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Choose Your Membership</button>
        </div>
      </div>


    </div>

    <div style={styles.bt}>
     <button style={styles.all} className='lab-btn'>View All Services</button>
     </div>

    </div>
  );
};

const styles = {

  main:{
   paddingTop:'30px',
  },

  ww:{
   display:'flex',
   justifyContent:'center',
   textAlign: 'center',
  },

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '20px',
    padding: '20px',
    color: '#fff',
  },
  serviceBox: {
    padding: '20px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    webkitBackdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


  },
  title: {
    fontSize: '1.5rem',
    color: '#f5db83',
    marginBottom: '10px',
    height: '60px', // Ensures consistent height for all headers
    display: 'flex',
    alignItems: 'center', // Vertically centers the text within the header
  },
  content: {
    flex: '1',
    display: 'flex',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.6',
    color: '#b3b3b3',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {

    width: '100%', 
  
  },
  all: {

    width: '200px', 
    display: 'flex',
    justifyContent: 'center',
    borderRadius:'10px',
  
  },
  bt: {

    
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '40px',

  
  },
};

export default Service;

