// src/scenes/matchmakingApiCall/index.jsx
import api from "../API/api";

// Fetch matches for the user
export const getMatches = async () => {
  const userId = localStorage.getItem('user_id'); // Retrieve user_id from localStorage
  try {
    const response = await api.get(`/api/matchmaking/matches/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching matches:", error);
    return [];
  }
};

// src/scenes/matchmakingApiCall/index.jsx
export const startMatchmaking = async () => {
  const userId = localStorage.getItem('user_id'); // Retrieve user_id from localStorage
  const token = localStorage.getItem('token'); // Retrieve JWT token from localStorage

  if (!token || !userId) {
      console.error("Missing token or user_id in localStorage");
      return { success: false, message: "Missing token or user_id" };
  }

  try {
      const response = await api.post(
          `/api/matchmaking/start-matchmaking/${userId}`, // Correct URL
          {}, // No body required
          {
              headers: {
                  Authorization: `Bearer ${token}`, // Attach JWT token
              },
          }
      );

      // Check if the response is successful
      if (response.data && response.data.success) {
          return { success: true, matches: response.data.matches };
      } else {
          return { success: false, message: response.data.message || "Failed to start matchmaking" };
      }
  } catch (error) {
      console.error("Error starting matchmaking:", error);
      return { success: false, message: "Error starting matchmaking" };
  }
};