import React from "react";

function Kala() {
  return (


<div className="kala">
    <div className="kala1">
        <div className="kala2">
            <img className="kalaimg" alt="Matchmaking Image"/>
        </div>
        <div className="kala2 kala3">
            <h5>Personalized Matchmaking</h5>
            <h2>Matchmaking</h2>
            <p>
                We pride ourselves on offering bespoke matchmaking services that are
                meticulously customized to align with your unique preferences and
                needs. Our dedicated team works tirelessly to ensure a personalized
                and extraordinary experience, guiding you toward discovering your
                ideal partner. Whether you seek a soulmate or a companion for life’s
                adventures, our tailored approach promises to deliver meaningful and
                lasting connections.
            </p>
            <a href="#" className="read-more">Read More</a>
        </div>
        <div className="kala2 kala4">
            <ol>
                <div className="ka">
                <div className="ka1"> <li >01.</li>  </div>  <div> <li>Personalized Matchmaking</li> </div>
                </div>
                <div className="ka">
                <div className="ka1"> <li>02.</li> </div>  <div> <li>Exclusive Luxurious Matchmaking Events</li> </div>
                </div>
                <div className="ka">
                <div className="ka1"> <li>03.</li>  </div>  <div> <li>Confidentiality and Discretion</li> </div>
                </div>
              
            </ol>
        </div>
    </div>
</div>

  
  );
}

export default Kala;
