import React from 'react'
// import { Link } from 'react-router-dom';





const Service = () => {
  return (

    <div style={styles.upmain}>

<div style={styles.main}>
  <h2 style={styles.ww}>Introduction</h2>
  <p style={styles.ww}>"Discover the Secret Proposal Club, an exclusive matchmaking experience for high-caliber individuals <br /> 
seeking meaningful connections. Our unique blend of mystery and speed dating creates the perfect 
environment for your personality to shine."</p>
</div>

    <div style={styles.container}>
      {/* Service Box 1 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Crackling First Encounters</h3>
        <div style={styles.content}>
          <p style={styles.text}>
          "Say goodbye to awkward first dates. At Secret Proposal Club, we create vibrant environments where 
               first encounters are filled with energy and anticipation. Our AI-vetted matches and customized masks 
               ensure every interaction is impactful."
               <br />
               Benefit: "Experience a dating environment designed for success."

          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Get Started Today</button>
        </div>
      </div>

      {/* Service Box 2 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}>Absolute Discretion:</h3>
        <div style={styles.content}>
          <p style={styles.text}>
          "Your privacy is paramount. At Secret Proposal Club, your identity remains confidential until you choose 
          to reveal it, allowing you to engage with confidence."
          <br />
          Benefit: "Enjoy complete privacy and discretion."
          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Privacy Commitment</button>
        </div>
      </div>

      {/* Service Box 3 */}
      <div style={styles.serviceBox}>
        <h3 style={styles.title}> The Mystery of the Mask</h3>
        <div style={styles.content}>
          <p style={styles.text}>
          "Masks are more than just a fashion statement—they’re a key to unlocking your true personality. At our 
          events, masks foster anonymity, allowing you to express yourself freely and make genuine connections."
          <br />
          Visuals:"Rotating images and short success stories of recently matched couples."

          </p>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} className='lab-btn'>Sign up</button>
        </div>
      </div>

      {/* Service Box 4 */}
      


    </div>

    </div>
  );
};

const styles = {

  upmain:{
    paddingRight:'70px',
    paddingLeft:'70px',
   },



  main:{
   paddingTop:'30px',
  },

  ww:{
   display:'flex',
   justifyContent:'center',
   textAlign: 'center',
  },

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '20px',
    padding: '20px',
    color: '#fff',
  },
  serviceBox: {
    padding: '20px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    webkitBackdropFilter: 'blur(10px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',


  },
  title: {
    fontSize: '1.5rem',
    color: '#f5db83',
    marginBottom: '10px',
    height: '60px', // Ensures consistent height for all headers
    display: 'flex',
    alignItems: 'center', // Vertically centers the text within the header
  },
  content: {
    flex: '1',
    display: 'flex',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.6',
    color: '#b3b3b3',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {

    width: '100%', 
  
  },

};

export default Service;

