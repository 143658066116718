import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Avatar, IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  HomeOutlined, PeopleOutlined, ContactsOutlined, ReceiptOutlined,
  BarChartOutlined, PieChartOutlined, TimelineOutlined, QuestionAnswerOutlined,
  EventAvailableOutlined, MapOutlined, PersonOutlineOutlined, CampaignOutlined,
  FeedbackOutlined, ChatOutlined, AccountBalanceOutlined, VerifiedUserOutlined,
  AssignmentOutlined, LocalOfferOutlined, HelpOutlineOutlined, 
  IntegrationInstructionsOutlined, ConfirmationNumberOutlined, SupervisorAccountOutlined
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
// Full adminRoutes array with all roles
const adminRoutes = [
  { path: '/dashboard', label: 'Dashboard', icon: <HomeOutlined />, roles: ['admin', 'member', 'agent', 'support', 'partner'] },
];

const data = [
  { path: '/user-management', label: 'User Management', icon: <PeopleOutlined />, roles: ['admin', 'support','member'] },
  { path: '/user-information', label: 'User Information', icon: <PeopleOutlined />, roles: ['admin', 'support','member'] },
  { path: '/contacts', label: 'Contacts Information', icon: <ContactsOutlined />, roles: ['admin', 'member', 'agent', 'partner', 'support'] },
  { path: '/invoices', label: 'Invoices Balances', icon: <ReceiptOutlined />, roles: ['admin', 'member', 'agent', 'partner', 'support'] },
  { path: '/userscreation', label: 'Role Management', icon: <ReceiptOutlined />, roles: ['admin','member'] },
];

const pages = [
  // { path: '/form', label: 'Forms', icon: <AssignmentOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },
  { path: '/calendar', label: 'Create Event', icon: <EventAvailableOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },

];

const newp = [
  { path: '/matches', label: 'Ai-MatchMaking', icon: <PersonOutlineOutlined />, roles: [ 'member', 'partner'] },
  { path: '/EventList', label: 'Buy Event', icon: <PersonOutlineOutlined />, roles: [ 'member', 'partner'] },
  { path: '/event-passes', label: 'Event Passes', icon: <PersonOutlineOutlined />, roles: [ 'member', 'partner'] },
];

const charts = [
  // { path: '/bar', label: 'Bar Chart', icon: <BarChartOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },
  // { path: '/pie', label: 'Pie Chart', icon: <PieChartOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },
  // { path: '/line', label: 'Line Chart', icon: <TimelineOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },
  // { path: '/geography', label: 'Geography', icon: <MapOutlined />, roles: ['admin', 'member', 'agent', 'partner'] },
];






// Utility to get user role from localStorage (or any other source)
const getUserRole = () => {
  return localStorage.getItem('userRole'); // e.g., 'admin', 'member', etc.
};

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initially closed
  const [userRole, setUserRole] = useState('');
  const [username, setUsername] = useState(''); // State for username
  const [role, setRole] = useState(''); // State for role
  const sidebarRef = useRef(null);

  // Fetch user role on component mount
  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
  }, []);


    // Fetch user info on component mount
    useEffect(() => {
      const fetchUserInfo = async () => {
        try {
          const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
          if (token) {
            const response = await axios.get('http://localhost:8001/api/user-info', {
              headers: {
                Authorization: `Bearer ${token}`, // Include token in the header
              },
            });
            setUsername(response.data.username); // Set username from API response
            setRole(response.data.role); // Set role from API response
            setUserRole(response.data.role); // Set user role for filtering routes
          } else {
            console.error('No token found, please log in');
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };
  
      fetchUserInfo();
    }, []);
  

  // Close sidebar when clicking outside (but exclude link clicks inside the sidebar)
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarOpen(false);  // Close sidebar when clicking outside
    }
  };

  // Add event listener to detect clicks outside the sidebar
  useEffect(() => {
    if (sidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      {/* Toggle Button */}
      <IconButton
        onClick={toggleSidebar}
        style={{
          position: 'fixed',
          top: 20,
          left: sidebarOpen ? 250 : 20,
          zIndex: 1000,
          backgroundColor: '#1a2238',
          color: '#fff',
          padding: '10px',
        }}
      >
        {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {/* Sidebar */}
      <Drawer
        ref={sidebarRef}
        variant="persistent"
        open={sidebarOpen}
        anchor="left"
        style={{
          width: sidebarOpen ? 240 : 0,
          transition: 'width 0.02s ease',
          opacity: sidebarOpen ? 1 : 0,
          overflowX: 'hidden',
          backgroundColor: '#202c44',
        }}
        PaperProps={{
          style: {
            width: sidebarOpen ? 240 : 0,
            transition: 'width 0.02s ease',
            opacity: sidebarOpen ? 1 : 0,
            backgroundColor: '#1F2942',
            color: '#fff',
          },
        }}
      >
        {/* User Info */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',  // Center horizontally
            justifyContent: 'center',  // Center vertically
            padding: '20px',
            borderBottom: '1px solid #303c54',
            paddingTop: '30px',
            opacity: sidebarOpen ? 1 : 0,
            transition: 'opacity 0.3s ease',
            textAlign: 'center',  // Ensures text is centered
          }}
        >
          <Avatar
            src="https://randomuser.me/api/portraits/men/45.jpg"
            alt="User Avatar"
            style={{ marginBottom: '10px', width: 80, height: 80 }}
          />
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{username || 'Loading...'}</div>
            <div style={{ fontSize: '14px', color: '#ccc' }}>{role || 'Loading...'}</div>
        </div>

        {/* Navigation Links */}
        <List>
          {adminRoutes
            .filter((route) => route.roles.includes(userRole))
            .map((route, index) => (
              <ListItem button key={index} component={Link} to={route.path}>
                <ListItemIcon style={{ color: '#fff' }}>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ))}
        </List>


        <List>
          <span style={{ marginLeft:'15px', color:'#9c9a9a' }}>data</span>
          {data
            .filter((route) => route.roles.includes(userRole))
            .map((route, index) => (
              <ListItem button key={index} component={Link} to={route.path}>
                <ListItemIcon style={{ color: '#fff' }}>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ))}
        </List>


        <List>
          <span style={{ marginLeft:'15px', color:'#9c9a9a' }}>pages</span>
          {pages
            .filter((route) => route.roles.includes(userRole))
            .map((route, index) => (
              <ListItem button key={index} component={Link} to={route.path}>
                <ListItemIcon style={{ color: '#fff' }}>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ))}
        </List>


        <List>
          <span style={{ marginLeft:'15px', color:'#9c9a9a' }}>newp</span>
          {newp
            .filter((route) => route.roles.includes(userRole))
            .map((route, index) => (
              <ListItem button key={index} component={Link} to={route.path}>
                <ListItemIcon style={{ color: '#fff' }}>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ))}
        </List>


        
        {/* <List>
          <span style={{ marginLeft:'15px', color:'#9c9a9a' }}>charts</span>
          {charts
            .filter((route) => route.roles.includes(userRole))
            .map((route, index) => (
              <ListItem button key={index} component={Link} to={route.path}>
                <ListItemIcon style={{ color: '#fff' }}>{route.icon}</ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            ))}
        </List> */}

     

      </Drawer>
    </div>
  );
};

export default Sidebar;