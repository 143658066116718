import React, { Component } from "react";

const title = "You’re Just 3 Steps Away From A Great Date";
const subtitle = "How Does It Work?";

let WorkListContent = [
  {
    imgClass: "ufffimg1", // Using class for background images
    step: "step",
    stepCount: "01",
    title: "Register Your Interest",
    desc: "Complete our simple registration form to express your interest.",
  },
  {
    imgClass: "ufffimg2",
    step: "step",
    stepCount: "02",
    title: "Attend Exclusive Events",
    desc: "Join us at our bespoke events in luxurious locations around the world.",
  },
  {
    imgClass: "ufffimg3",
    step: "step",
    stepCount: "03",
    title: "Meet Your Match",
    desc: "Engage with verified members and find your perfect match.",
  },
];

class WorkSection extends Component {
  render() {
    return (
      <section className="work-section padding-tb">
        <div className="container">
          <div className="section-header">
            <h4 className="theme-color">{subtitle}</h4>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row justify-content-center g-5">
              {/* Iterating over WorkListContent to generate each step */}
              {WorkListContent.map((item, index) => (
                <div className="col-lg-4 col-sm-6 col-12 px-4" key={index}>
                  <div className="lab-item work-item">
                    <div className="lab-inner text-center">
                      <div className="lab-thumb">
                        <div className="thumb-inner">
                          {/* Adding background image class */}
                          <div className={item.imgClass}></div>
                          <div className="step">
                            <span>{item.step}</span>
                            <p className="stepp"> {item.stepCount}</p>
                          </div>
                        </div>
                      </div>
                      <div className="lab-content">
                        <h4>{item.title}</h4>
                        <p >{item.desc} </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}




export default WorkSection;
