
//sec\src\pages\signin.jsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Added Link import
import axios from 'axios';
import Header2 from '../component/layout/header2';
import PageHeader from '../component/layout/pageheader';
import FooterSection from '../component/layout/footer';

const SignInPage = () => {
    const [regName, setRegName] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regConPassword, setRegConPassword] = useState('');
    const navigate = useNavigate(); // useNavigate hook

    const handleSubmit = (e) => {
        e.preventDefault();

        // Password match validation
        if (regPassword !== regConPassword) {
            alert("Passwords do not match!");
            return;
        }

        // Registration request
        axios.post('http://localhost:8001/register', {
            username: regName,
            email: regEmail,
            password: regPassword
        })
        .then(response => {
            // Success: Navigate to login page
            alert('Registration successful!');
            navigate('/login');
        })
        .catch(error => {
            // Handle known errors (e.g., email already registered)
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message);  // e.g., "This email is already registered."
            } else {
                // Handle unexpected errors
                alert('There was an error registering. Please try again later.');
                console.error('Registration error:', error);
            }
        });
    };

    return (
        <div>
            <Header2 />
            <PageHeader title={'Sign Up'} />
            <div className="login-section padding-tb">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Register Now</h3>
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    id="item01"
                                    value={regName}
                                    onChange={(e) => setRegName(e.target.value)}
                                    placeholder="Your Name *"
                                    required // Add required attribute for client-side validation
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"  // Changed to email for better validation
                                    name="email"
                                    id="item02"
                                    value={regEmail}
                                    onChange={(e) => setRegEmail(e.target.value)}
                                    placeholder="Your Email *"
                                    required // Add required attribute
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    id="item03"
                                    value={regPassword}
                                    onChange={(e) => setRegPassword(e.target.value)}
                                    placeholder="Password *"
                                    required // Add required attribute
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="conpassword"
                                    id="item04"
                                    value={regConPassword}
                                    onChange={(e) => setRegConPassword(e.target.value)}
                                    placeholder="Confirm Password *"
                                    required // Add required attribute
                                />
                            </div>
                            <div className="form-group">
                                <button className="d-block lab-btn" type="submit"><span>Get Started Now</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection />
        </div>
    );
};

export default SignInPage;
