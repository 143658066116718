//sec/src/scenes/calendar/calendar.jsx
import React, { useState, useEffect } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { formatDate } from '@fullcalendar/core'; 
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Modal,
  TextField
} from "@mui/material";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import { getEvents, createEvent } from "../eventapi/api";  

// Define CSS constants for styling
const styles = {
  heading: {
    color: '#f1f1f1',
    fontWeight: '600',
  },
  sidebarBox: {
    flex: '1 1 20%',
    padding: '15px',
    // border: '1px solid #333',
    borderRadius: '8px',
    // backgroundColor: '#1e1e1e',
    marginBottom: { xs: '16px', md: '0' },
    maxHeight:'90vh',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',

  },
  sidebarHeading: {
    // color: '',
  },
  listItem: {
    // backgroundColor: 'blue',
    marginBottom: '10px',
    padding: '15px',
    borderRadius: '8px',

    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
  },
  calendarBox: {
    flex: '1 1 100%',
    marginLeft: { md: '15px' },
    border: '1px solid #333',
    borderRadius: '8px',
    overflow: 'hidden',
    // backgroundColor: '#1e1e1e',
padding:'20px',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '2px solid rgba(255, 255, 255, 0.18)',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',

    

  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '450px',
    backgroundColor: '#141b2d',
    color: '#e0e0e0',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 4px 12px',
    overflowY: 'auto',
    maxHeight: '90vh',

  },
  modalHeading: {
    color: '#81d4fa',
    fontWeight: '500',
  },
  textField: {
    margin: 'normal',
    InputLabelProps: { style: { color: '#b0bec5' } },
    InputProps: { style: { color: '#e0e0e0' } },
  },
  createButton: {
    marginTop: '20px',
    backgroundColor: '#29b6f6',
    color: '#121212',
    fontWeight: '600',
    fontSize: '1em',
    width: '100%',
  },
};

const Calendar = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    eventType: '',
    capacity: '',
    organizer: '',
    cost: '',
    venue_name: '',
    location: '',
    city: '',
    start_date: '',
    end_date: ''
  });
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        console.log('Fetching events for the calendar...');
        const response = await getEvents();
        const events = response.map(event => ({
          title: event.title,
          start: event.start_date,
          end: event.end_date,
          extendedProps: {
            description: event.description,
            cost: event.cost,
            venue_name: event.venue_name,
            location: event.location,
            city: event.city,
            organizer: event.organizer,
            event_type: event.event_type,
          }
        }));
        setCurrentEvents(events);
        console.log('Events fetched and set in calendar:', events);
      } catch (error) {
        console.error('Error fetching events for calendar:', error);
      }
    };

    fetchEvents();
  }, []); 

  const handleDateClick = (selected) => {
    console.log('Date selected for event creation:', selected.date);
    setSelectedDate(selected);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    console.log('Closing event creation modal');
    setOpenModal(false);
    setEventData({
      title: '',
      description: '',
      eventType: '',
      capacity: '',
      organizer: '',
      cost: '',
      venue_name: '',
      location: '',
      city: '',
      start_date: '',
      end_date: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Event input changed:', name, value);
    setEventData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const newEvent = {
        title: eventData.title,
        description: eventData.description,
        eventType: eventData.eventType || 'Default',
        capacity: eventData.capacity,
        organizer: eventData.organizer || 'Unknown Organizer',
        cost: eventData.cost,
        venue_name: eventData.venue_name,
        location: eventData.location,
        city: eventData.city,
        start_date: eventData.start_date,
        end_date: eventData.end_date,
      };

      console.log('Creating new event with the following data:', newEvent);
      const response = await createEvent(newEvent);
      console.log('Event created successfully, response:', response);

      const calendarApi = selectedDate.view.calendar;
      calendarApi.unselect();
      calendarApi.addEvent({
        ...newEvent,
        start: newEvent.start_date,
        end: newEvent.end_date,
        id: response.event_id,
      });
      
      setCurrentEvents((prevEvents) => [...prevEvents, newEvent]);
      console.log('Event successfully added to the calendar:', newEvent);
      handleCloseModal();
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Error creating event:", error.response.data);
      } else {
        console.error("Error creating event:", error.message);
      }
      console.log('Event data at the time of error:', eventData); 
    }
  };

  const handleEventClick = (selected) => {
    if (window.confirm(`Are you sure you want to delete the event '${selected.event.title}'`)) {
      console.log('Event deleted:', selected.event.title);
      selected.event.remove();
      setCurrentEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== selected.event.id)
      );
    }
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Typography variant="h3" style={styles.heading}>Calendar</Typography>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" mt={2}>
            <Box sx={styles.sidebarBox}>
              <Typography variant="h5" style={styles.sidebarHeading}>Events</Typography>
              <List>
                {currentEvents.map((event) => (
                  <ListItem key={event.id} sx={styles.listItem}>
                    <ListItemText
                      primary={<Typography variant="h6" style={{ color: '#fff' }}>{event.title}</Typography>}
                      secondary={
                        <>
                          <Typography style={{ fontSize: '0.9em', color: '#b0bec5' }}>{formatDate(event.start, { year: "numeric", month: "short", day: "numeric" })}</Typography>
                          {event.description && <Typography>Description: {event.description}</Typography>}
                          {event.eventType && <Typography>Type: {event.eventType}</Typography>}
                          {event.capacity && <Typography>Capacity: {event.capacity}</Typography>}
                          {event.organizer && <Typography>Organizer: {event.organizer}</Typography>}
                          {event.cost && <Typography>Cost: {event.cost}</Typography>}
                          {event.venue_name && <Typography>Venue: {event.venue_name}</Typography>}
                          {event.location && <Typography>Location: {event.location}</Typography>}
                          {event.city && <Typography>City: {event.city}</Typography>}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box sx={styles.calendarBox}>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                initialView="dayGridMonth"
                selectable={true}
                select={handleDateClick}
                events={currentEvents}
                eventClick={handleEventClick}
              />
            </Box>
          </Box>

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={styles.modalBox}>
              <Typography variant="h6" mb="10px" style={styles.modalHeading}>Create Event</Typography>
              {['title', 'description', 'eventType', 'capacity', 'organizer', 'cost', 'venue_name', 'location', 'city'].map((field, index) => (
                <TextField
                  key={index}
                  fullWidth
                  label={field.replace('_', ' ').toUpperCase()}
                  name={field}
                  value={eventData[field]}
                  onChange={handleChange}
                  margin="normal"
                  InputLabelProps={styles.textField.InputLabelProps}
                  InputProps={styles.textField.InputProps}
                />
              ))}
              <TextField
                fullWidth
                label="Start Date"
                name="start_date"
                type="datetime-local"
                value={eventData.start_date}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={styles.textField.InputLabelProps}
                InputProps={styles.textField.InputProps}
              />
              <TextField
                fullWidth
                label="End Date"
                name="end_date"
                type="datetime-local"
                value={eventData.end_date}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={styles.textField.InputLabelProps}
                InputProps={styles.textField.InputProps}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={styles.createButton}
              >
                Create Event
              </Button>
            </Box>
          </Modal>
        </Box>
      </main>
    </div>
  );
};

export default Calendar;
